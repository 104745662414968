import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setGetHistoryList } from '../layout/store/thunk/setGetHistoryList';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import WinTable from '../../../@casino/ui/WinTable';
import {columns, getRows, columnsMobile, getRowsMobile} from './helper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {translate} from '../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    desktop: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    }
}))

const History = () => {
    document.title = translate('title.history')
    const dispatch = useDispatch();
    const classes  = useStyles();

    React.useEffect(() => {
        dispatch(setGetHistoryList({
            page: 1,
            rowsPerPage: 10
        }));
    }, []);

    const history = useSelector(state => state.profile_page.history) ;
    
    const rows = React.useMemo(() => getRows(history.list), [history.list]);
    const rowsMobile = React.useMemo(() => getRowsMobile(history.list), [history.list]);

    return (
        <WinPageBlock
            title={translate('navigation.history')}
            description={translate('common.recentGames')}
        >   
            <div className={classes.desktop}>
                <WinTable 
                    columns={columns}
                    rows={rows}
                    pageCount={history.page}
                    changePage={(page) => dispatch(setGetHistoryList({
                        page,
                        rowsPerPage: 10
                    }))}
                    labelNotFound={translate('common.informationRecentGames')}
                />   
            </div>
            <div className={classes.mobile}>
                <WinTable 
                    columns={columnsMobile}
                    rows={rowsMobile}
                    pageCount={history.page}
                    changePage={(page) => dispatch(setGetHistoryList({
                        page,
                        rowsPerPage: 10
                    }))}
                    labelNotFound={translate('common.informationRecentGames')}
                />    
            </div>
           
        </WinPageBlock>
    )
}

export default History;