import React from 'react';
import Center from './img/center.png';
import Blade1 from './img/1.png';
import Blade2 from './img/2.png';
import Blade3 from './img/3.png';
import Blade4 from './img/4.png';
import './spiner.css';

const Loader = ({background}) => {
    return (
        <div style={{width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', background: background ? 'rgba(0,0,0,0.3)' : 'inherit'}}>
            <div style={{position: 'relative', width: 200, height: 200, display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 99999999999999999999999999999999}}>
                <img src={Center} alt="" />
                <img className="img1" src={Blade1} alt="" style={{position: 'absolute', top: 0, right: 0}}/>
                <img className="img2"  src={Blade2} alt="" style={{position: 'absolute', bottom: 0, right: 0}}/>
                <img className="img3"  src={Blade3} alt="" style={{position: 'absolute', bottom: 0, left: 0}}/>
                <img className="img4"  src={Blade4} alt="" style={{position: 'absolute', top: 0, left: 0}}/>
            </div>
        </div>
    )
}

export default Loader;