
import {getListProvider} from '../api/getListProvider';
import {setGetListProviderAction} from '../action';

export const setGetListProvider = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getListProvider(params);

            dispatch(setGetListProviderAction(data.providers));
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}