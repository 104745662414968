import React from 'react';
import { makeStyles } from '@material-ui/core';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    label: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 24,
        color: 'gold'
    },
    text: {
        color: 'rgba(255,255,255,0.8)',
        fontFamily: 'Montserrat, sans-serif'
    }
}))

const PoliticalCanselPaymentRU = () => {
    const classes = useStyles();

    return (
        <div class="page-content rules">
            <h2 className={classes.label}>{translate('common.135')}</h2>
            <p className={classes.text}>{translate('common.136')}</p>
            <p className={classes.text}>{translate('common.137')}</p>
            <p className={classes.text}>{translate('common.138')}</p>
            <p className={classes.text}>{translate('common.139')}</p>
            <p className={classes.text}>{translate('common.140')}</p>
            <p className={classes.text}>{translate('common.141')}</p>
        </div>
    )
}

export default PoliticalCanselPaymentRU;