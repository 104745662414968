import { postCreatePromocode } from '../api/postCreatePromocode';
import { setGetReferralsCheck } from './setGetReferralsCheck';

export const setPostCreatePromocode = (params) => {
    return async (dispatch, getState) => {
        try {
            await postCreatePromocode(params);
            dispatch(setGetReferralsCheck());
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}