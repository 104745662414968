import { postBonusActivate } from '../api/postBonusActivate';
import { setProfile } from '../../authorization/thunk/setProfile';
import { translate } from '../../../dictionaries';

export const setPostBonusActivate = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postBonusActivate(params);
            dispatch(setProfile())
        } catch(error) {
            alert(translate('common.error'))
            console.log(error);
        }
    }
}