import {types} from './types';

const initialState = {
    code_country: [],
    currency: [],
    banners: [],
    country: []
}

export function settingsReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_CODE_COUNTRY:
            return { ...state, code_country: action.payload };
        case types.SET_GET_CURRENCY:
            return { ...state, currency: action.payload };
        case types.SET_GET_BANNERS:
            return { ...state, banners: action.payload };
        case types.SET_GET_COUNTRY: 
            return { ...state, country: action.payload };
        default:
            return state
    }
}