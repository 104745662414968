import React from 'react';
import { ReactComponent as Krug } from './krug.svg';
import { ReactComponent as Present } from './present.svg';
import "./style.css"
import Btn from '../../../../../../../@casino/ui/btn';
import { translate } from '../../../../../../../dictionaries';

const BonusFD = () => {
    const [isActiveAnim, setActiveAnim] = React.useState(false);
    return (
        <div
            style={{ position: 'relative', cursor: 'pointer' }}
            onClick={() => setActiveAnim(!isActiveAnim)}
        >
            <div className="bonus-mobile" style={{
                transform: isActiveAnim ? 'rotateY(180deg)' : 'rotateY(0deg)',
                opacity: isActiveAnim ? 0 : 1,
                textAlign: 'center',
                transition: 'opacity 0.2s ease, transform 0.5s ease'
            }}>
                <div style={{}}>
                    <Present className="bonus__icon-mobile" />
                </div>
                
                <div  style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className='bonus__wrap-mobile'>
                        <div>
                            <h3 className='bonus__wrap__title-mobile'  dangerouslySetInnerHTML={{ __html: translate('common.387')}} />
                        </div>
                        <div>
                            <span className='bonus__wrap__text-mobile'>
                            {translate('common.388')} 
                            </span>
                        </div>
                        <div style={{marginTop: 17}}>
                            <Btn
                                title={translate('common.384')}
                                event={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        {/*  */}
                    </div>
                    <Krug className="bonus__circle__background-mobile" />
                </div>
            </div>
            <div className="bonus2-mobile" style={{
                transform: !isActiveAnim ? 'rotateY(180deg)' : 'rotateY(0deg)',
                opacity: !isActiveAnim ? 0 : 1,
                transition: 'opacity 0.2s ease, transform 0.5s ease'
            }}>
                <div  style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', textAlign: 'center'}}>
                    <div className='bonus__wrap-mobile'>
                        <div>
                            <h3 className='bonus__wrap__title-mobile'>{translate('common.385')}</h3>
                        </div>
                        <div>
                            <div style={{paddingBottom: 20}}>
                            <span className='bonus__wrap__text-mobile' >
                                {translate('common.389')}
                            </span>
                            </div>
                            <div>
                                <Btn
                                    title={translate('common.384')}
                                    event={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </div>
                            
                        </div>
                    </div>
                    <Krug className="bonus__circle__background-mobile" />
                </div>
            </div>
        </div>
    )
}

export default BonusFD;