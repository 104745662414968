import React from 'react';
import {ReactComponent as ArrowLeft} from '../../img/arrowLeft2.svg';
import {ReactComponent as ArrowRight} from '../../img/arrowRight2.svg';

const Pagination = ({all, nextPage, prevPage}) => {
    const [page, setPage] = React.useState(1);

    return (
        <div style={{marginTop: 40, display: 'flex', justifyContent: 'center'}}>
            <span style={{
                display: 'flex',
                alignItems: 'center',
                background: '#142A8F',
                borderRadius: 3,
                padding: '5px 10px',
                color: '#D2D8E3',
                fontSize: 16,
                fontFamily: 'Montserrat, sans-serif',
                cursor: 'pointer',
                marginRight: 5
            }}
                onClick={() => {
                    if (page - 1 > 0) {
                        prevPage(page - 1);
                        setPage(page - 1)
                    }
                }}
            >
                <ArrowLeft/>
            </span>
 
                {
                    (page - 2 < page && page - 2 > 0) &&
                    <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        background: '#142A8F',
                        borderRadius: 3,
                        padding: '5px 10px',
                        color: '#D2D8E3',
                        fontSize: 16,
                        fontFamily: 'Montserrat, sans-serif',
                        cursor: 'pointer',
                        marginRight: 5
                    }}
                    onClick={() => {
                        prevPage(page - 2);
                        setPage(page - 2)
                    }}
                    >
                        {page - 2}
                    </span>
    
                }
                {
                    (page - 1 < page && page - 1 > 0) &&
                    <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        background: '#142A8F',
                        borderRadius: 3,
                        padding: '5px 10px',
                        color: '#D2D8E3',
                        fontSize: 16,
                        fontFamily: 'Montserrat, sans-serif',
                        cursor: 'pointer',
                        marginRight: 5
                    }}
                        onClick={() => {
                            prevPage(page - 1);
                            setPage(page - 1)
                        }}>
                        {page - 1}
                    </span>
                }
                <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        background: '#FF005C',
                        borderRadius: 3,
                        padding: '5px 10px',
                        color: '#D2D8E3',
                        fontSize: 16,
                        fontFamily: 'Montserrat, sans-serif',
                        cursor: 'pointer',
                        marginRight: 5,
                    }}
                        className="pagination__link">
                        {page}
                </span>
                {
                    page + 1 < all &&
                    <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        background: '#142A8F',
                        borderRadius: 3,
                        padding: '5px 10px',
                        color: '#D2D8E3',
                        fontSize: 16,
                        fontFamily: 'Montserrat, sans-serif',
                        cursor: 'pointer',
                        marginRight: 5
                    }}
                    onClick={() => {
                        prevPage(page + 1);
                        setPage(page + 1);
                    }}
                    >
                        {page + 1}
                    </span>
                }
                {
                    page + 2 < all &&
                    <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        background: '#142A8F',
                        borderRadius: 3,
                        padding: '5px 10px',
                        color: '#D2D8E3',
                        fontSize: 16,
                        fontFamily: 'Montserrat, sans-serif',
                        cursor: 'pointer',
                        marginRight: 5
                    }}
                        className="pagination__link">
                        ...
                    </span>
                }
                {
                    page < all &&
                    <span style={{
                        display: 'flex',
                        alignItems: 'center',
                        background: '#142A8F',
                        borderRadius: 3,
                        padding: '5px 10px',
                        color: '#D2D8E3',
                        fontSize: 16,
                        fontFamily: 'Montserrat, sans-serif',
                        cursor: 'pointer',
                        marginRight: 5
                    }}
                    onClick={() => {
                        prevPage(all);
                        setPage(all);
                    }}>
                        {all}
                    </span>
                }
            <span style={{
                display: 'flex',
                alignItems: 'center',
                background: '#142A8F',
                borderRadius: 3,
                padding: '5px 10px',
                color: '#D2D8E3',
                fontSize: 16,
                fontFamily: 'Montserrat, sans-serif',
                cursor: 'pointer',
            }}
                onClick={() => {
                    if (page + 1 <= all) {
                        prevPage(page + 1);
                        setPage(page + 1)
                    }
                }}
            >
                <ArrowRight />
            </span>
        </div>
    )
}

export default Pagination;