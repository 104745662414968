import React from 'react';
import { useDispatch } from 'react-redux';
import { setGetListBonuses } from '../../store/thunk/setGetListBonuses';
import MainPageBlock from '../../../../../@casino/ui/mainPageBlock';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../dictionaries';
import BonusFD from './components/BonusFD';
import BonusFS from './components/BonusFS';
import BonusCashback from './components/BonusCashback';
import BonusFDMobile from './components/BonusFDMobile';
import BonusFSMobile from './components/BonusFSMobile';
import BonusCashbackMobile from './components/BonusCashbackMobile';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    }
}))

const BonusesList = ({ auth }) => {
    document.title = translate('title.bonuses')
    const dispatch = useDispatch();
    const classes = useStyles();

    React.useEffect(() => {
        dispatch(setGetListBonuses());
    }, [])

    const cals = window.screen.height - 780;

    return (
        <MainPageBlock
            title={translate('navigation.bonuses')}
        >

            <div style={{
                minHeight: cals,
                display: 'flex', justifyContent: 'space-between',
                width: '100%'
            }}
                className={classes.root}
            >
                {
                    !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && (
                        <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 20}}>
                            <BonusFD />
                            <BonusCashback />
                            <BonusFS />
                        </div>
                    )
                }

                {
                    (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && (
                        <>
                            <BonusFDMobile />
                            <BonusFSMobile />
                            <BonusCashbackMobile />
                        </>
                    )
                }


            </div>
        </MainPageBlock>
    )
}

export default BonusesList;