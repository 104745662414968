import React from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Button, makeStyles } from '@material-ui/core';
import { translate } from '../../../../../dictionaries';
import { useDispatch } from 'react-redux';
import { setPostPinsale } from '../../../../../store/bonus/thunk/setPostPinsale';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: 109,
    },
    wrapTitle: {
        marginBottom: 28
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '18px',
        lineHeight: '37px',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
    },
    bonus: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        background: '#001137',
        borderRadius: '3px',
        width: 300,
        height: 120,
        marginRight: 20
    },
    activeBonus: {
        border: '1px solid #FF005C',
        boxSizing: 'border-box',
        boxShadow: '0px 3px 20px #FF005C',
    },
    textBonus: {
        width: '51%',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '24px',
        lineHeight: '29px',
        textAlign: 'center',
        paddingRight: 20
    },
    colorTextBonus: {
        color: '#FFFFFF',
    },
    colorTextActiveBonus: {
        color: '#FF005C',
    },
    iconBonus: {
        width: 117,
    },
    wrapBonusDesktop: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 31,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: 20,
            alignItems: 'center',
            marginBottom: 40
        }
    },
    field2: {
        width: 300,
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: '3px',
        padding: '15px 19px',
        background: 'inherit',
        color: '#fff',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    labelHelper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        width: 171,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        }
    },
}))
const PinsaleField = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { register, handleSubmit, setValue, control, setError, formState: { errors } } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {

        },
    });

    const onSubmit = (store) => {
        dispatch(setPostPinsale(store))
    }

    return (

        <form onSubmit={handleSubmit(onSubmit)} className={classes.wrapBonusDesktop} style={{ display: 'flex', columnGap: 12, marginBottom: 50 }}>
            <label htmlFor="pinsale">
                <input
                    {...register('pinsale')}
                    type="text"
                    id="pinsale"
                    placeholder={translate('common.input_pinsale')}
                    className={classes.field2}
                    inputmode="decimal"
                />
            </label>
            <Button color="secondary" variant="contained" type="submit">{translate('common.activate')}</Button>
        </form>
    )
}

export default PinsaleField;