import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useFormik, FormikContext } from "formik";
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch } from 'react-redux';
import { translate } from '../../../../../../../../../dictionaries';
import { setPostConfirmationPhone } from '../../../../../../../../template/header/store/thunk/setPostConfirmationPhone';

const useStyles = makeStyles(() => ({
    field2: {
        width: 414,
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: '3px',
        padding: '15px 19px',
        background: 'inherit',
        color: '#fff',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
}));

const Confirmation = ({ showModalConfirm, setShowModalConfirm, initial }) => {
    const [error, setError] = React.useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();
    const initialValues = {
        code: ''
    }

    const onSubmit = (store) => {
        dispatch(setPostConfirmationPhone({
            ...initial,
            phone: initial.login,
            codeSms: store.code,
            messageUuid: showModalConfirm
        }, setShowModalConfirm, setError))
    }

    const enableReinitialize = true;

    const formik = useFormik({ onSubmit, initialValues, enableReinitialize });

    return (
        <Dialog
            open={showModalConfirm}
            maxWidth={1000}
        >
            <div style={{
                position: 'relative',
                zIndex: 9999999,
                width: 300,
                background: '#001E5B',
                padding: 20
            }}>
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                    <h3 style={{
                        margin: 0,
                        padding: 0,
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#FFFFFF',
                        textAlign: 'center',
                        marginBottom: 8
                    }}>{translate('common.successPhone')}</h3>
                    <span style={{
                        color: '#fff',
                        fontSize: 14,
                        fontFamily: 'Montserrat, sans-serif'
                    }}>
                        {translate('common.smsToSend')}
                    </span>
                </div>
                <div>
                    <FormikContext.Provider value={formik}>
                        <form onSubmit={formik.handleSubmit} >
                            <label htmlFor="code" style={{ display: 'flex', alignItems: 'center', marginTop: 12 }}>
                                <input
                                    type="text"
                                    name="code"
                                    id="code"
                                    placeholder={translate('common.codeSuccess')}
                                    value={formik.values.code}
                                    onChange={(v) => {
                                        if (!isNaN(+v.target.value)) {
                                            formik.setFieldValue('code', v.target.value);
                                            setError(false);
                                        }
                                    }}
                                    className={classes.field2}
                                />
                            </label>
                            {
                                error &&
                                <span style={{
                                    color: '#FF005C',
                                    fontSize: 12,
                                    fontFamily: 'Montserrat, sans-serif',
                                }} >{translate('common.error')}!</span>
                            }
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                                <span style={{
                                    background: '#FF005C',
                                    borderRadius: 50,
                                    padding: '10px 40px',
                                    fontFamily: 'Montserrat, sans-serif',
                                    color: '#ffffff',
                                    cursor: 'pointer'
                                }}
                                    onClick={() => formik.submitForm()}
                                >{translate('common.success')}</span>
                            </div>

                        </form>
                    </FormikContext.Provider>
                </div>
            </div>
        </Dialog>
    )
}

export default Confirmation;