import React from 'react';
import {useStyles} from './helper';
import {ReactComponent as ArrowBottom} from '../../../../../@casino/img/arrowBottom.svg';
import { useSelector } from 'react-redux';
import WithdrawalImage from '../../../../../@casino/img/withdrawal.png';
import {translate} from '../../../../../dictionaries';

const WithBlock = ({formik, balance: balance_type}) => {
    const classes = useStyles();
    const profile = useSelector(state => state.profile.view);
    

    const getValidation = (value) => {
        if (!!!value.card_number || !!!value.amount) {
            return false
        }
        return true
    }

    const getMethodOperation = (type_operation) => {
        switch (type_operation) {
            case 'visa-rus':  return 'VISA RUS';
            case 'mastercard_rus': return 'MASTERCARD RUS';
            case 'visa-internation': return `VISA ${translate('common.titleEuropeUpper')}`;
            case 'mastercard_internation': return `MASTERCARD ${translate('common.titleEuropeUpper')}`
            case 'usdt-trc': return `USDT TRC-20`;
            case 'ethereum': return 'ETHEREUM';
            case 'litecoin': return 'LITECOIN';
            case 'piastrix': return 'PIASTRIX';
        }
    }
  
    const getCurrency = (currency) => {
        switch (currency) {
            case 'RUB': return '₽';
            case 'USD': return '$';
            case 'EUR': return '€';
            default: return currency;
        }
    }

    const getCardNumberTitle = (type) => {
        switch(type) {
            case 'usdt-trc': return translate('common.number_wallet');
            case 'ethereum': return translate('common.number_wallet');
            case 'litecoin': return translate('common.number_wallet');
            default: return translate('common.card_number')
        }
    }

    const balance = balance_type === 'slot' ? profile?.balance : profile?.balance_sport;

    return (
        <div className={classes.mt41}>
            <label htmlFor="method" className={classes.wrapLabelMethod}>
                <span className={classes.helper}>{translate('common.methodWithdrawal')}</span>
                <div  className={classes.wrapLabel}>
                    <span className={classes.label}>{getMethodOperation(formik.values.type_operation)}</span>
                    <ArrowBottom className={classes.arrowbottom}/>
                </div>
            </label>
            <label htmlFor="" className={classes.wrapAmount}>
                <span className={classes.helper}>{getCardNumberTitle(formik.values.type_operation)}</span>
                <input type="text" 
                    className={classes.input2}
                    disabled={!!profile.card_number && formik.values.type_operation !== 'usdt-trc' && formik.values.type_operation !== 'ethereum' && formik.values.type_operation !== 'litecoin'  && formik.values.type_operation !== 'piastrix'}
                    onChange={(v) => {
                        if (!isNaN(+v.target.value) && formik.values.type_operation !== 'usdt-trc' && formik.values.type_operation !== 'ethereum' && formik.values.type_operation !== 'litecoin' && formik.values.type_operation !== 'piastrix') {
                            formik.setFieldValue('card_number', v.target.value)
                        } else if (formik.values.type_operation === 'usdt-trc' || formik.values.type_operation === 'ethereum' || formik.values.type_operation === 'litecoin' || formik.values.type_operation === 'piastrix') {
                            formik.setFieldValue('card_number', v.target.value)
                        }
                    }}
                    value={formik.values.card_number}
                />
            </label>
            {
                !profile.card_number && (formik.values.type_operation === 'visa-internation' || formik.values.type_operation === 'mastercard_internation') &&
                <>
                    <label htmlFor="" className={classes.wrapAmount}>
                        <span className={classes.helper}>{translate('common.nameDepositor')}</span>
                        <input type="text" 
                            className={classes.input2}
                            disabled={!!profile.card_number}
                            onChange={(v) => {
                                formik.setFieldValue('name', v.target.value)
                            }}
                            value={formik.values.name}
                        />
                    </label>
                    <label htmlFor="" className={classes.wrapAmount}>
                        <span className={classes.helper}>{translate('common.surnameDepositor')}</span>
                        <input type="text" 
                            className={classes.input2}
                            disabled={!!profile.card_number}
                            onChange={(v) => {
                                formik.setFieldValue('surname', v.target.value)
                            }}
                            value={formik.values.surname}
                        />
                    </label>
                </>
            }
            <label htmlFor="" className={classes.wrapAmount}>
                <span className={classes.helper}>{translate('common.amountWithdrawals')}</span>
                <div className={classes.wrapInputAmount}>
                    <input type="text" 
                        className={classes.input}
                        onChange={(v) => {
                            if (!isNaN(+v.target.value) && v.target.value >= 0 && v.target.value <= balance && profile.wager === 0 && +profile.count_deposit >= 1) {
                                formik.setFieldValue('amount', +v.target.value)
                            }
                        }}
                        value={formik.values.amount}
                    />
                    <div className={classes.wrapAccess}>
                        <span className={classes.access}>
                            {translate('common.available')}: 
                        </span>
                        <span className={classes.summ}>
                            {profile.wager > 0 || +profile.count_deposit === 0 ? 0 : new Intl.NumberFormat('ru-RU').format(balance)} {getCurrency(profile?.currency)}
                        </span>
                    </div>
                </div>
            </label>
            {
                !!profile.wager && 
                <div  style={{marginTop: 12}}>
                    <span className={classes.helper}><span className={classes.summ} style={{fontWeight: 700}}>{translate('common.withdrawalWager')}.</span> {translate('common.withdrawalWagerAfter')}: <span className={classes.summ}>{profile.wager} {getCurrency(profile?.currency)}</span></span>
                </div>
            }
            {
                +profile.count_deposit === 0 && 
                <div  style={{marginTop: 12}}>
                    <span className={classes.helper}><span className={classes.summ} style={{fontWeight: 700}}>{translate('common.withdrawalDeposit')}.</span> {translate('common.withdrawalDepositAfter')}</span>
                </div>
            }
            <span></span>
            <div className={classes.wrapBtn}>
                <span className={classes.btn} style={{
                    background: getValidation(formik.values) && '#7B61FF',
                    border: !getValidation(formik.values) && '1px solid #7B61FF',
                    cursor: getValidation(formik.values) ? 'pointer' : 'default',
                }}
                    onClick={() => {
                        if (getValidation(formik.values)) {
                            formik.submitForm()
                        }    
                    }}
                >
                    {translate('btn.withdrawal')}
                </span>
            </div>
            <div className={classes.wrapImage}>
                <img src={WithdrawalImage} alt="" style={{margin: 0, padding: 0, display: 'block'}}/>
            </div>
        </div>
    )
}

export default WithBlock;