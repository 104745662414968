import {types} from './types';

export const setGamesAction = (payload) => {
    return {
        type: types.SET_GAMES,
        payload,
      }
}

export const setGameIdAction = (payload) => {
  return {
      type: types.SET_GAME_ID,
      payload,
    }
}

export const setGetListLastWinAction = (payload) => {
  return {
      type: types.SET_LIST_LAST_WIN,
      payload,
    }
}

export const setGetLastTournamentAction = (payload) => {
  return {
    type: types.SET_LAST_TOURNAMENT,
    payload,
  }
}


export const setJackpotListAction = (payload) => {
  return {
      type: types.SET_JACKPOT,
      payload,
    }
}

export const setGetListProviderAction = (payload) => {
  return {
      type: types.SET_LIST_PROVIDER,
      payload,
    }
}

export const setGetBonusesNotificationAction = (payload) => {
  return {
      type: types.SET_NOTIFICATION,
      payload
  }
}

export const setGameAction = (payload) => {
  return {
    type: types.SET_GAME,
    payload
  }
}

export const setLastWithdrawalsAction = (payload) => {
  return {
    type: types.SET_LAST_WITHDRAWALS,
    payload
  }
}