
import { getGamesList } from '../api/getGamesList';
import { setGamesSlotAction } from '../action';

export const setGamesListSlot = (params) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await getGamesList(params);

            const prevData = getState().mobile_games.slot;

            dispatch(setGamesSlotAction({
                list: params.page === 1 ? data.data : [...prevData.list, ...data.data],
                page: Number(data.currentPage),
                totalSize: data.totalSize
            }));
        } catch (error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}