import React from 'react';
import classes from './style.module.css';
import { translate } from '../../../../../dictionaries';

const PrizeFund = ({
    fund
}) => {
    return (
        <div className={classes.root}>
            <h3 className={classes.title}>{translate('common.496')}</h3>
            <span className={classes.text}>
                {new Intl.NumberFormat('ru-RU').format(fund)} <span style={{ fontSize: 12 }}>₽</span>
            </span>
        </div>
    )
}

export default PrizeFund;