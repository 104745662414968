import { translate } from '../../../dictionaries';
import { postCancelTransaction } from '../api/postCancelTransaction';
import { setGetTransaction } from './setGetTransaction';

export const setPostCancelTransaction = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postCancelTransaction(params);  
            dispatch(setGetTransaction());  
        } catch(error) {
            alert(translate("common.368"))
        }
    }
}