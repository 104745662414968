import { postChangePassword } from '../api/changePassword';

export const setPostChangePassword = (params, setShowSuccess, setError) => {
    return async (dispatch, getState) => {
        try {
            await postChangePassword(params);
            if (setShowSuccess) {
                setShowSuccess(true);
            }
          
        } catch(error) {
            setError(error?.response?.data?.message);
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}