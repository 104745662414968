import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    btn: {
        padding: '10px 40px',
        background: '#FF005C',
        borderRadius: 50,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#fff',
        transition: 'background .2s ease',
        cursor: 'pointer',
        textAlign: 'center',
        position: 'relative',
        zIndex: 5,
        '&:hover': {
            background: "#ED074C",
            transition: 'background .2s ease',
        }
    },
    btn__disabled: {
        background: '#3747A6',
        '&:hover': {
            background: "#3747A6",
            transition: 'background .2s ease',
        }
    }
}))


const Btn = ({ title, event, isDisabled }) => {
    const classes = useStyles();

    return (
        <span className={clsx(classes.btn, isDisabled && classes.btn__disabled)} onClick={event}>{title}</span>
    )
}

export default Btn;