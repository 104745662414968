import { isFunction } from 'formik';
import { postCall } from '../api/postCall';
import { translate } from '../../../dictionaries';

export const setPostCall = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postCall(params);    
            
            if (isFunction(params.callbackSuccess)) {
                params.callbackSuccess()
            }
        } catch(error) {
            alert(translate("common.368"))
        }
    }
}