import React from 'react';
import Background from './background.png';
import BackgroundDisabled from './backgroundDisabled.png';
import classes from './style.module.css';
import StatusActive from './components/StatusActive';
import Description from './components/Description';
import PrizeFund from './components/PrizeFund';
import Time from './components/Time';
import Places from './components/Places';
import StatusDisabed from './components/StatusDisabled';
import { useRouter } from '../../hook/useRouter';
import { translate } from '../../../dictionaries';

const TournamentMobile = (props) => {
    const { name, description, fund, top_places, date_to, date_from, id, status } = props
    const router = useRouter();

    const handleRouter = () => {
        router.history.push(`/tournament/view/${id}`)
    }
    return (
        <div className={classes.root} style={{ backgroundImage: `url(${status ? Background : BackgroundDisabled})` }}>
            {
                status && <StatusActive />
            }
            {
                !status && <StatusDisabed />
            }

            <Description
                name={name}
                description={description}
                id={id}
                status={status}
            />
            <div>
                <PrizeFund
                    fund={fund}
                />

                <Time
                    date_to={date_to}
                    date_from={date_from}
                    status={status}
                />

                <Places
                    top_places={top_places}
                />
            </div> 
            <div className={classes.wrapBtn}>
                <div className={status ? classes.btn : classes.btnDisabled} onClick={handleRouter}>
                    <span className={classes.btnText}>{status ? translate('common.505') : translate('common.view')}</span>
                </div>
            </div> 
        </div>
    )
}

export default TournamentMobile;