import React from 'react';
import {getArray} from '../../../../../@casino/lib/getArray';
import {useStyles} from './helper'
import {translate} from '../../../../../dictionaries';

const CurrentResult = ({win, current}) => {
    const classes = useStyles();
    const places_table = getArray(win, Math.ceil(win.length / 3));
    const places_table_mobile = getArray(win, Math.ceil(win.length / 1));
    let placesSize = 0;
    let placesSizeMobile = 0;

    return (
        <div>
            <div className={classes.wrapBody}>
                <div style={{width: '100%'}}>
                   {
                        places_table.length === 0 &&
                        <div className={classes.wrapInfo}>
                            <span  className={classes.info}>
                                {translate('lottery.infoNotFound')}
                            </span>
                        </div>
                    }
                    <div className={classes.wrapPlacesTable}>
                        {
                            places_table.map((item, key) => {
                                return (
                                    <div key={key}>
                                        <div className={classes.wrapHeaderTable}>
                                            <span className={classes.participant}>{translate('common.participant')}</span>
                                            <span className={classes.result}>{current ? translate('common.tickets') : translate('common.win')}</span>
                                        </div>
                                    <div>
                                    {
                                        item.map((data, key) => {
                                            placesSize += 1;
                                            
                                            return (
                                                <div key={key} className={classes.wrapRow} style={{background: !(key % 2) && '#142A8F', }}>
                                                    <span className={classes.placesSize}>{placesSize}</span>
                                                    <span className={classes.login}>
                                                        {data.id}
                                                    </span>
                                                    <span className={classes.points}>
                                                    {current ? data.ticket : new Intl.NumberFormat('ru-RU').format(+data.amount)} 
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                                    </div>
                                </div>
                            )})
                        }
                    </div>
                    <div className={classes.wrapHeaderTableMobile}>
                        <span className={classes.participant}>{translate('common.participant')}</span>
                        <span className={classes.result}>{current ? translate('common.tickets') : translate('common.win')}</span>
                    </div>
                    <div className={classes.wrapPlacesTableMobile}>
                        {
                            places_table_mobile.map((item, key) => {
                                return (
                                    <div key={key}>
                                    {
                                        item.map((data, key) => {
                                            placesSizeMobile += 1;
                                            
                                            return (
                                                <div key={key} className={classes.wrapRow} style={{background: !(key % 2) && '#142A8F', }}>
                                                    <span className={classes.placesSize}>{placesSizeMobile}</span>
                                                    <span className={classes.login}>
                                                        {data.id}
                                                    </span>
                                                    <span className={classes.points}>
                                                        {+data.points - 10}
                                                    </span>
                                                </div>
                                            )
                                        })
                                    }
                
                                    </div>
                          
                            )})
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CurrentResult;