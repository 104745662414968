import { restorePassword } from '../api/restorePassword';

export const setPostRestorePassword = (params, callbackSuccess, callbackError) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await restorePassword(params);
            if (callbackSuccess) {
                callbackSuccess()
            }
        } catch(error) {
            if (error?.response?.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            } else {
                if (callbackError) {
                    callbackError()
                }
            }
        }
    }
}