import React from 'react';
import ConfidetcialRU from './blocks/ru';
import makeStyles from "@material-ui/core/styles/makeStyles";
import { translate } from '../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 1140,
        margin: '120px auto',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 40px)',
            padding: '0 20px'
        }
    }
}))

const Confidetcial = () => {
    document.title = translate('title.confidencial');
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ConfidetcialRU />
        </div>
    )
}

export default Confidetcial;