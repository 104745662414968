
import {getListLastWin} from '../api/getListLastWin';
import {setGetListLastWinAction} from '../action';

export const setGetListLastWin = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getListLastWin(params);

            if (data.status === 'success') {
                dispatch(setGetListLastWinAction(data.data));
            }
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}