import React from 'react';
import { useRouter } from '../../../@casino/hook/useRouter';
import {ReactComponent as SuccessIcon} from './success.svg';
import { translate } from '../../../dictionaries';

const SuccessPayment = () => {
    const router = useRouter();
    const {query} = router;
    
    React.useEffect(() => {
        if (query?.MERCHANT_ORDER_ID) {

        }
    }, [query?.MERCHANT_ORDER_ID])

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', rowGap: 20, padding: '0 30px', height: '100vh', width: '100wh'}}>   
            <div style={{display: 'flex', alignItems: 'center', columnGap: 20}}>
                <SuccessIcon  style={{width: 100, height: 100}}/>
                <span style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '20px',
                    lineHeight: '20px',
                    color: '#FFF',
                }}>
                    {translate('common.payment_success')}
                </span>
            </div>
            <div>
                <span 
                    onClick={() => window.location.replace('https://777winbet.net')}
                style={{
                    padding: '10px 15px', 
                    background: '#FF005C',
                    fontFamily: 'Montserrat, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '20px',
                    lineHeight: '15px',
                    display: 'flex',
                    alignItems: 'center',
                    color: '#FFFFFF',
                    borderRadius: '50px',
                    cursor: 'pointer'
                }}>
                    {translate('common.go_to_lk')}
                </span>
            </div>
        </div>
    )
}

export default SuccessPayment;