import React from 'react';
import Navigation from '../components/navigation';
import RoutesProfile from '../components/routes';
import ProfileBlock from '../components/profileBlock';
import Game from '../components/game';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ReactComponent as Face} from '../../../../@casino/img/face.svg';
import {ReactComponent as CoinProfile} from '../../../../@casino/img/coinProfile.svg';
import {ReactComponent as WithdrawalProfile} from '../../../../@casino/img/withdrawalProfile.svg';
import {ReactComponent as ReplenishmentProfile} from '../../../../@casino/img/replenishmentProfile.svg';
import { useRouter } from '../../../../@casino/hook/useRouter';
import User from '../../../../@casino/img/user.svg';
import {ReactComponent as SuccessVerification} from '../../../../@casino/img/sucessVerification.svg';
import {ReactComponent as ErrorVerification} from '../../../../@casino/img/errorVerification.svg';
import { useSelector } from 'react-redux';
import Loader from '../../../loader';
import { translate } from '../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    desktop: {
        display: 'block',
        padding: '0 20px',
        [theme.breakpoints.down('sm')]: {
            display: 'none', 
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block', 
        }
    },
    wrapRootNavigationAnimation: {
        position: 'absolute', 
        top: -40, 
        left: 0, 

        paddingRight: 3, 
        paddingTop: 3, 
        paddingBottom: 3, 
        borderTopRightRadius: 4, 
        borderBottomRightRadius: 4, 
        background: '#FF005C',
        animation: '$myEffect .9s infinite linear',  
        '-webkit-animation': '$myEffect .9s infinite linear', 
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block', 
        } 
    },
    "@keyframes myEffect": {
        "0%": {
          paddingLeft: 10,
        
        },
        
        "50%": {
          paddingLeft: 0
        },

        "100%": {
            paddingLeft: 10
          }
    },
    wrapRootNavigation: {
        position: 'absolute', 
        zIndex: 100, 
        right: -20
    },
    rootNavigation: {
        marginTop: 20,
        display: 'flex', 
        flexDirection: 'column', 
        width: 340,  
        background: '#002062',
        borderRadius: 3
    },
    wrapItemNavigation: {
        padding: '10px 0 10px 30px',
        background: '#142A8F',
        borderRadius: 3,
        marginBottom: 10,
        cursor: 'pointer',
        transition: 'background .3s ease',
        '&:hover': {
            background: '#7B61FF',
            transition: 'background .3s ease',
        }
    },
    itemNavigation: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#FFFFFF',
    },
    activeItemNavigation: {
        position: 'relative',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        color: '#FFFFFF',
        '&::after': {
            position: 'absolute',
            top: -5, 
            left: -20,
            content: '""',
            display: 'block',
            height: 30,
            width: 3,
            background: '#FF3156'
        }
    },
    activeWrapItemNavigation: {
        padding: '10px 0 10px 30px',
        background: 'linear-gradient(270deg, rgba(20, 42, 143, 0.5) 0%, rgba(0, 74, 236, 0.5) 100%), #142A8F',
        borderRadius: 3,
        marginBottom: 10,
    }
     
}))

const Profile = () => {
    document.title = translate('title.profile')
    const classes = useStyles();
    const router = useRouter();
    const {pathname} = router;
    const [nav, setNav] = React.useState(false);
    const profile = useSelector(state => state.profile.view);
    const loading = useSelector(state => state.loading.loading.loading);

    return (
        <div style={{position: 'relative'}}>
            {   
                loading && 
                <div style={{position: 'fixed',top: 0, width: '100%', height: '100%'}}>
                    <Loader background/>
                </div>
            }
            <div className={classes.wrapRootNavigationAnimation}
                onClick={() => setNav(!nav)}
            >
                <div style={{background: '#FFBF39', borderRadius: '50%', width: 50, height: 50, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <Face style={{width: 45, height: 45, stroke: '#7B61FF'}}/>
                </div>
            </div>
            <div style={{width: '340px', background: '#002062', borderRadius: 3, position: 'absolute', zIndex: 99999, top: -45, left: nav ? '10%' : -500, display: 'flex', flexDirection: 'column', padding: '30px 16px'}}>
                <div style={{width: '100%', display: 'flex', marginBottom: 41}}>
                    <div 
                        style={{display: 'flex', position: 'relative'}}
                       
                    >
                       <img src={User} style={{width: 66, height: 66, borderRadius: '50%', marginRight: 20}} alt=""/>
                        {
                            !!profile.verification_passport && !!profile.verification_selfie_passport &&
                            !!profile.verification_selfie_card && !!profile.verification_payments &&
                            <SuccessVerification style={{position: 'absolute', bottom: 0, right: 13}}/>
                        }
                        {
                            (!!!profile.verification_passport || !!!profile.verification_selfie_passport ||
                            !!!profile.verification_selfie_card || !!!profile.verification_payments) &&
                            <ErrorVerification style={{position: 'absolute', bottom: 0, right: 13}}/>
                        }
                    </div>
                    
                    <div style={{width: '100%'}}>
                        <span style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px',
                            lineHeight: '17px',
                            width: '100%',
                            color: '#D2D8E3',
                            marginBottom: 6
                        }}>{profile.name} {profile.surname}</span>
                        <span style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            lineHeight: '20px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            color: '#FFFFFF',
                            marginBottom: 9
                        }}>{profile?.login === '79653339666' ? '79********6' : isNaN(profile.login) ? profile.login : '+' + profile.login.slice(0, 1) + ' ' + profile.login.slice(1, 4) + ' ' + profile.login.slice(4, 7) + ' ' + profile.login.slice(7, 9) + '-' + profile.login.slice(9)}</span>
                        <span 
                            style={{
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '14px',
                                lineHeight: '17px',
                                display: 'flex',
                                alignItems: 'center',
                                textDecoration: 'underline',
                                color: '#FF679E'  
                            }}
                            onClick={() => {
                                localStorage.removeItem('token');
                                window.location.replace(window.location.origin)
                            }}
                        
                        >{translate('common.quit')}</span>
                    </div>
                </div>
                <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', columnGap: 5}}>
                    <div style={{position: 'relative', width: '33,33%', background: '#142A8F', height: 51, display: 'flex', flexDirection: 'column', padding: '21px 17px 0', alignItems: 'center'}}>
                        <span style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '12px',
                            lineHeight: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            color: '#8E9EC2',
                        }}>
                            {translate('navigation.bonuses')}
                        </span>
                        <span style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            color: '#FFFFFF',
                        }}>
                            {new Intl.NumberFormat('ru-RU').format(profile.sum_bonuses)}
                        </span>
                        <div style={{width: 48, height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: -23, left: 'calc(50% - 24px)', background: '#142A8F', borderRadius: 50}}>
                            <CoinProfile style={{paddingLeft:3}}/>
                        </div>
                    </div>
                    <div style={{position: 'relative', width: '33,33%', background: '#142A8F', height: 51, display: 'flex', flexDirection: 'column', padding: '21px 17px 0', alignItems: 'center'}}>
                        <span style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '12px',
                            lineHeight: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            color: '#8E9EC2',
                        }}>
                            {translate('common.withdrawals')}
                        </span>
                        <span style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            color: '#FFFFFF',
                        }}>
                            {new Intl.NumberFormat('ru-RU').format(profile.sum_withdrawals)}
                        </span>
                        <div style={{width: 48, height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: -23, left: 'calc(50% - 24px)', background: '#142A8F', borderRadius: 50}}>
                            <WithdrawalProfile style={{paddingLeft:3}}/>
                        </div>
                    </div>
                    <div style={{position: 'relative', width: '33,33%', background: '#142A8F', height: 51, display: 'flex', flexDirection: 'column', padding: '21px 17px 0', alignItems: 'center'}}>
                        <span style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '12px',
                            lineHeight: '15px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            color: '#8E9EC2',
                        }}>
                            {translate('common.repl')}
                        </span>
                        <span style={{
                            fontFamily: 'Montserrat',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            textAlign: 'center',
                            color: '#FFFFFF',
                        }}>
                            {new Intl.NumberFormat('ru-RU').format(profile.sum_deposit)}
                        </span>
                        <div style={{width: 48, height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: -23, left: 'calc(50% - 24px)', background: '#142A8F', borderRadius: 50}}>
                            <ReplenishmentProfile/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={classes.rootNavigation}>
                        <div 
                            className={pathname === '/profile/account' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                            onClick={() => {
                                router.history.push('/profile/account');
                                setNav(false)
                            }}
                        >
                        <span 
                            className={pathname === '/profile/account' ? classes.activeItemNavigation : classes.itemNavigation}
                        >{translate('navigation.account')}</span>
                        </div>
                        <div 
                            onClick={() => {
                            router.history.push('/profile/bonuses')
                            setNav(false)
                        }}
                            className={pathname === '/profile/bonuses' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}>
                            <span 
                                className={pathname === '/profile/bonuses' ? classes.activeItemNavigation : classes.itemNavigation}
                            >{translate('navigation.bonuses')}</span>
                        </div>
                        {
                            window.localStorage.getItem('token') && 
                            <div 
                                className={pathname === '/profile/view' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                                onClick={() => {
                                    router.history.push('/profile/view')
                                    setNav(false)
                                }}
                            >
                                <span 
                                    className={pathname === '/profile/view' ? classes.activeItemNavigation : classes.itemNavigation}
                                >{translate('navigation.profile')}</span>
                            </div>
                        }
                        <div 
                            className={pathname === '/profile/referral' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                            onClick={() => {
                                router.history.push('/profile/referral');
                                setNav(false);
                            }}
                        >
                            <span 
                                className={pathname === '/profile/referral' ? classes.activeItemNavigation : classes.itemNavigation}
                            >{translate('navigation.referralProfile')}</span>
                        </div>
                        {/* <div 
                            className={pathname.indexOf('/profile/history') > -1 ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                            onClick={() => {
                                router.history.push('/profile/history');
                                setNav(false);
                            }}
                        >
                            <span 
                                className={pathname.indexOf('/profile/history') > -1 ? classes.activeItemNavigation : classes.itemNavigation}       
                            >{translate('navigation.history')}</span>
                        </div> */}
                        <div 
                            className={pathname === '/profile/transaction' ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                            onClick={() => {
                                router.history.push('/profile/transaction');
                                setNav(false);
                            }}
                        >
                            <span 
                                className={pathname === '/profile/transaction' ? classes.activeItemNavigation : classes.itemNavigation}       
                            >{translate('navigation.transaction')}</span>
                        </div>
                        {/* <div 
                            className={pathname === '/profile/verification' > -1 ? classes.activeWrapItemNavigation : classes.wrapItemNavigation}
                            onClick={() => {
                                router.history.push('/profile/verification');
                                setNav(false);
                            }}
                        >
                            <span 
                                className={pathname === '/profile/verification' ? classes.activeItemNavigation : classes.itemNavigation}       
                            >{translate('navigation.verification')}</span>
                        </div> */}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                        <span
                            style={{
                                background: '#7B61FF',
                                borderRadius: '50px',
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px 0px',
                                color: '#FFFFFF',
                                width: '100%',
                                textAlign: 'center',
                                marginBottom: 8
                            }}
                            onClick={() => {
                                router.history.push('/profile/withdrawal')
                            }}
                        >{translate('btn.withdrawal')}</span>
                        <span  
                            style={{
                                background: '#FF005C',
                                borderRadius: '50px',
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px 0px',
                                color: '#FFFFFF',
                                width: '100%',
                                textAlign: 'center'
                            }}
                            onClick={() => {
                                router.history.push('/profile/replenishment')
                            }}
                        >{translate('btn.replenishment')}</span>
                    </div>
                </div>
            </div>
            <div className={classes.desktop}>
                <div style={{paddingTop: 50, width: '100%', maxWidth: 1140, margin: '0 auto', display: 'flex', justifyContent: 'space-between'}}>
                    <Navigation />
                    <RoutesProfile />
                    <ProfileBlock />
                </div>
                <Game />
            </div>
            <div className={classes.mobile}>
                <RoutesProfile />   
                <Game />
            </div>
        </div>
    )
}

export default Profile;