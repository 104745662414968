import React from 'react';
import classes from './style.module.css';
import { useRouter } from '../../../../hook/useRouter';

const Description = ({
    name,
    description,
}) => {
  

    return (
        <div className={classes.root}>
            <div className={classes.wrapName}>
                <h3 className={classes.title}>{name}</h3>
            </div>
            <div>
                <span className={classes.description}>{description?.length > 60 ? description?.slice(0, 60) + '...' : description}</span>
            </div>
            {/* */}
        </div>
    )
}

export default Description;