import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    info: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        marginTop: 50, 
        width: '100%',
    },
    infoTitle: {
        color: '#eee',
        fontSize: 14,
        fontStyle: 'normal',
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
    },
    wrapDots: {
        display: 'flex', 
        justifyContent: 'center',
        width: '100%'
    },
    wrapTournament: {
        paddingTop: 10,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        rowGap: 10,
        width: '100%',

    }
}))

export {
    useStyles,
}