import React from 'react';
import {ReactComponent as Visa} from '../../../../../../@casino/img/visamastercard.svg';
import {ReactComponent as Mir} from './mir-logo.svg';

const VisaWithdrawal = ({formik, setSelected}) => (
    <div style={{marginTop: 25, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 10}}>
        <div style={{display: 'flex', columnGap: 10}}>
            <div 
                onClick={() => {
                    setSelected(true);
                    formik.setFieldValue('type_operation', 'visa-rus')
                    formik.setFieldValue('method', 'visa-qiwi');
                }}
                    style={{cursor: 'pointer', width: 110, height: 140, background: '#fff', borderRadius: 3, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Visa />
                <Mir style={{width: 60}}/>
            </div>
        </div>
    </div>
)

export default VisaWithdrawal;