import React from 'react';
import RestorePassword from '../ModalRestorePassword';
import ModalFormAuth from '../ModalFormAuth';


const NewModalRegistration = ({ setShowModal }) => {
    const [isShowRestorePassowrd, setIsShowRestorePassword] = React.useState(false);

    return (
        <>
            {
                !isShowRestorePassowrd && (
                    <ModalFormAuth setShowModal={setShowModal} setIsShowRestorePassword={setIsShowRestorePassword} />
                )
            }
            {
                isShowRestorePassowrd && (
                    <RestorePassword setIsShowRestorePassword={setIsShowRestorePassword} />
                )
            }
        </>
    )
}

export default NewModalRegistration;