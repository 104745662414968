import React from 'react';
import { ReactComponent as Krug } from './krug.svg';
import Present from './present.png';
import "./style.css"
import Btn from '../../../../../../../@casino/ui/btn';
import { translate } from '../../../../../../../dictionaries';
import { useRouter } from '../../../../../../../@casino/hook/useRouter';

const BonusFD = () => {
    const router = useRouter();
    return (
        <div
            style={{ position: 'relative' }}

        >
            <div style={{
                textAlign: 'center',
                width: 320,
                maxWidth: 320,
                margin: '0 auto',
                height: 350,
                background: 'rgba(0, 32, 98, 0.3)',
                overflow: 'hidden',
                boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.40)',
                padding: 20,
                borderRadius: 5
            }}>
                <div>
                    <img src={Present} style={{ width: 130, textAlign: 'center' }} />
                </div>

                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div className='bonus__wrap-mobile'>
                        <div>
                            <h3 className='bonus__wrap__title-mobile'>{translate('common.469')}</h3>
                        </div>
                        <div>
                            <span className='bonus__wrap__text-mobile'>
                                {translate('common.470')}
                            </span>
                        </div>
                        <div>
                            <Btn
                                title={translate('common.384')}
                                event={(e) => {
                                    e.preventDefault();
                                    router.history.push('/bonuses/cashback')
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        {/*  */}
                    </div>
                    <Krug className="bonus__circle__background-mobile" />
                </div>
            </div>
        </div>
    )
}

export default BonusFD;