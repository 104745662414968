
import {postCreateSupport} from '../api/postCreateSupport';

export const setPostCreateSupport = (params, setShowModal) => {
    return async (dispatch, getState) => {
        try {
            await postCreateSupport(params);
            setShowModal('success');
        } catch(error) {
            setShowModal('error');
        }
    }
}