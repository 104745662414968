
import {getListBonuses} from '../api/getListBonuses';
import {setGetListBonusesAction} from '../action';

export const setGetListBonuses = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getListBonuses(params);
            
            if (data.status === 'success') {
                dispatch(setGetListBonusesAction(data.data));
            }
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}