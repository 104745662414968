import React from 'react';
import classes from './style.module.css';
import { useRouter } from '../../../../hook/useRouter';
import { translate } from '../../../../../dictionaries';

const Description = ({
    name,
    description,
    id,
    status
}) => {
    const router = useRouter();

    const handleRouter = () => {
        router.history.push(`/tournament/view/${id}`)
    }

    return (
        <div className={classes.root}>
            <div className={classes.wrapName}>
                <h3 className={classes.title}>{name}</h3>
            </div>
            <div>
                <span className={classes.description}>{description?.length > 60 ? description?.slice(0, 60) + '...' : description}</span>
            </div>
            <div className={classes.wrapBtn}>
                <div className={status ? classes.btn : classes.btnDisabled} onClick={handleRouter}>
                    <span className={classes.btnText}>{status ? translate('common.505') : translate('common.view')}</span>
                </div>
            </div>
        </div>
    )
}

export default Description;