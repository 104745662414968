import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Pagination from '../pagination';

const useStyles = makeStyles((theme) => ({
    wrapBody: {
        width: '100%', 
        display: 'flex', 
        justifyContent: 'space-between', 
        flexDirection: 'column',
        alignItems: 'center'
    },
    wrapHeader: {
        display: 'flex', 
        width: '100%',
        justifyContent: 'space-between'
    },
    headerItem: {
        display: 'flex',
        height: 60,
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8E9EC2',
    },
    wrapRows: {
        display: 'flex', 
        flexDirection: 'column', 
        width:'100%'
    },
    rowsItem: {
        width: '100%', 
        display: 'flex', 
        justifyContent: 'space-between', 
        height: 60, 
        alignItems: 'center',
    },
    columnsItem: {
        display: 'flex',
        justifyContent: 'center',
        wordBreak: 'break-word',
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        alignItems: 'center',
        color: '#D2D8E3',
        width: '100%'
    },
    wrapNotFound: {
        width: '100%', 
        display: 'flex', 
        justifyContent: 'space-between', 
        height: 60, 
        alignItems: 'center', 
        background: '#142A8F',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            textAlign: 'center'
        }
    },
    notFound: {
        display: 'flex',
        justifyContent: 'center',
        wordBreak: 'break-word',
        width: '100%',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        alignItems: 'center',
        color: '#D2D8E3',
    }
}))

const WinTable = ({columns, rows, changePage, pageCount, labelNotFound}) => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.body}>
                <div className={classes.wrapHeader}>
                    {
                        columns.map((item, key) => <span key={key} className={classes.headerItem} style={{width: item.width}}>{item.label}</span>)
                    }
                </div>
                <div className={classes.wrapRows} >
                    {
                        rows.map((item, key) => {
                            
                            return (
                                <div key={key} style={{background: key % 2 === 0 && '#142A8F'}} className={classes.rowsItem}>
                                    {
                                        columns.map((data, key) => {
                                            return (
                                                <div style={{width: data.width}} key={key}>
                                                    <span className={classes.columnsItem}>{item[data.id]}</span>
                                                </div>
                                            )
                                        })
                                            
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        rows.length === 0 &&
                        <div className={classes.wrapNotFound}>
                            <span className={classes.notFound}>{labelNotFound}</span>
                        </div>
                    }
                </div>
                {
                    !!pageCount && 
                    <Pagination
                        all={pageCount}
                        nextPage={(page) => changePage(page)}
                        prevPage={(page) => changePage(page)}
                    />
                }
                
            </div>
        </div>
    )
}

export default WinTable;
