import React from 'react';
import { ReactComponent as Label } from './label.svg';
import classes from './style.module.css';
import { translate } from '../../../../../dictionaries';

const StatusActive = () => {
    return (
        <div className={classes.root}>
            <div className={classes.wrap}>
                <Label className={classes.label} />
                <span className={classes.text}>{translate('common.497')}</span>
            </div>
        </div>
    )
}

export default StatusActive;