import React from 'react';
import { ReactComponent as Arrow } from './arrow.svg';
import classes from './style.module.css';
import { useRouter } from '../../../../hook/useRouter';
import { translate } from '../../../../../dictionaries';

const ShowAll = ({
    id
}) => {
    const router = useRouter();

    const handleRouter = () => {
        router.history.push(`tournament/view/${id}`)
    }
    return (
        <div className={classes.root}>
            <div 
                className={classes.wrap} 
                onClick={handleRouter}
            >
                <span className={classes.text}>
                    {translate('common.504')}
                </span>
                <Arrow />
            </div>
        </div>
    )
}

export default ShowAll;