import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    wrapRoot: {
        [theme.breakpoints.down('sm')]: {
            margin: '0 10px'
        }
    },
    root: {
        paddingTop: 108, 
        paddingBottom: 100, 
        width: '100%', 
        maxWidth: 1140, 
        margin: '0 auto'
    },
    wrap: {
        display: 'flex', 
        justifyContent: 'center',
        
    },
    title: {
        margin: 0, 
        padding: 0, 
        marginBottom: 50,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 30,
        lineHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF'
    },
    wrapChildren: {
        display: 'flex', 
        justifyContent: 'space-between', 
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            // maxWidth: 560,
            width: '100%',
        }
    }
}))

const MainPageBlock = ({title, children}) => {
    const classes = useStyles();
    
    return (
        <div className={classes.wrapRoot}>
            <div className={classes.root}>
                <div className={classes.wrap}>
                    <h3 className={classes.title}>{title}</h3>
                    </div>
                    
                    <div className={classes.wrapChildren}>
                    {
                        children
                    }
                </div>
            </div>               
        </div>
    )
}

export default MainPageBlock;