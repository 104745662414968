import React from 'react';
import { ReactComponent as Rus } from './rus.svg';
import AZ from '../../../../../../../@casino/img/azer.png';
import { ReactComponent as USA } from './usa.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../../../dictionaries';
import { countries } from '../../../../../../../config/countries.config';

const useStyles = makeStyles(() => ({
    root: {
        paddingBottom: 12,
    },
    title: {
        color: '#fff', fontFamily: 'Monserrat, sans-serif', marginLeft: 10
    },
    wrapLanguage: {
        margin: '0 10px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: '1px solid #fff',
        padding: 10,
        borderRadius: 8,
        marginBottom: 12,
    },
    flag: {
        width: 30,
        height: 30
    },
    titleFlag: {
        fontFamily: 'Monserrat, sans-serif',
        textTransform: 'uppercase',
        fontSize: 16,
        color: '#fff',
        marginLeft: 12
    }

}))

const Language = () => {
    const classes = useStyles();
    const [showSelect, setShowSelect] = React.useState(false)

    return (
        <div style={{ position: 'relative' }}>
            <div className={classes.root}>
                <span className={classes.title}>{translate('common.selectLanguage')}</span>
            </div>
            <div className={classes.wrapLanguage} onClick={() => setShowSelect(!showSelect)}>

                {
                    countries.map((item, key) => {
                        if (localStorage.getItem('language') === item.code) {
                            return (
                                <div style={{ display: 'flex' }} key={key}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                                        srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
                                        alt={`Flag of ${item.label}`}
                                    />
                                    <div style={{ color: '#fff', display: 'flex', marginLeft: 12 }}>
                                        {item.label}
                                    </div>

                                </div>
                            )
                        }
                    })
                }
                {
                    !localStorage.getItem('language') &&
                    <div className={classes.titleFlag} style={{ display: 'flex' }}>
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${countries[0].code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${countries[0].code.toLowerCase()}.png 2x`}
                            alt={`Flag of ${countries[0].label}`}
                        />
                        <div style={{ marginLeft: 12 }}>
                            {countries[0].label}
                        </div>
                    </div>
                }
            </div>
            {
                showSelect &&
                <div style={{ position: 'absolute', top: 0, left: 0, background: 'rgba(2, 0, 4, .8)', paddingTop: 10, width: '100%', zIndex: 99 }}>

                    {
                        countries.map((item, key) => {
                            return (
                                <>

                                    <div key={key} className={classes.wrapLanguage} onClick={() => {
                                        setShowSelect(!showSelect);
                                        localStorage.setItem('language', item.code);
                                        window.location.replace(window.location.origin)
                                    }}>
                                        <img
                                            loading="lazy"
                                            width="20"
                                            src={`https://flagcdn.com/w20/${item.code.toLowerCase()}.png`}
                                            srcSet={`https://flagcdn.com/w40/${item.code.toLowerCase()}.png 2x`}
                                            alt={`Flag of ${item.label}`}
                                        />
                                        <span className={classes.titleFlag}>{item.label}</span>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            }
        </div>
    )
}

export default Language;