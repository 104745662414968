import React from 'react';
import PlaceItem from './components/PlaceItem';
import classes from './style.module.css';
import { translate } from '../../../../../dictionaries';

const Places = ({
    top_places
}) => {
    return (
        <div className={classes.root}>
            <div className={classes.wrapHeader}>
                <div className={classes.wrapPlace}>
                    <span className={classes.text}>
                        {translate('common.494')}
                    </span>
                </div>
                <div className={classes.wrapPrize}>
                    <span className={classes.text}>
                        {translate('common.495')}
                    </span>
                </div>
            </div>
            {
                top_places.slice(0, 3).map((item, key) => <PlaceItem item={item} index={key} />)
            }
        </div>
    )
}

export default Places;