import React from 'react';
import { i18n, translate } from '../../../../../dictionaries';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    title: {
        display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'
    },
    text: {
        color: 'rgba(255,255,255,0.8)',
        fontFamily: 'Montserrat, sans-serif'
    }
}))

const ConfidetcialRU = () => {
    const classes = useStyles();
    return (
        <div class="page-content rules">
            <h2 className={classes.title}>{translate('common.1')}</h2>
            <h2 className={classes.title}>{translate('common.2')}</h2>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.3')}</p>
            <h2 className={classes.title}>{translate('common.4')}</h2>
            <p className={classes.text}>{translate('common.5')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.6')}</p>
            <p className={classes.text}>{translate('common.7')}</p>
            <p className={classes.text}>{translate('common.8')}</p>
            <p className={classes.text}>{translate('common.9')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.10')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.11')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.12')}</p>
            <p className={classes.text}>{translate('common.13')}</p>
            <p className={classes.text}>{translate('common.14')}</p>
            <h2 className={classes.title}>{translate('common.15')}</h2>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.16')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.17')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.18')}</p>
            <p className={classes.text}>{translate('common.19')}</p>
            <h2 className={classes.title}>{translate('common.20')}</h2>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.21')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.22')}</p>
            <p className={classes.text}>{translate('common.23')}</p>
            <p className={classes.text}>{translate('common.24')}</p>
            <p className={classes.text}>{translate('common.25')}</p>
            <p className={classes.text}>{translate('common.26')}</p>
            <p className={classes.text}>{translate('common.27')}</p>
            <p className={classes.text}>{translate('common.28')}</p>
            <ul className={classes.text}>
                <li>{translate('common.29')}</li>
                <li>{translate('common.30')}</li>
                <li>{translate('common.31')}</li>
                <li>{translate('common.32')}</li>
                <li>{translate('common.33')}</li>
            </ul>
            <p className={classes.text}>{translate('common.34')}</p>
            <p className={classes.text}>{translate('common.35')}</p>
            <p className={classes.text}>{translate('common.36')}</p>
            <h2 className={classes.title}>{translate('common.37')}</h2>
            <p className={classes.text}>{translate('common.38')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.39')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.40')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.41')}</p>
            <p className={classes.text}>{translate('common.42')}</p>
            <p className={classes.text}>{translate('common.43')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.44')}</p>
            <p className={classes.text}>{translate('common.45')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.46')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.47')}</p>
            <h2 className={classes.title}>{translate('common.48')}</h2>
            <p className={classes.text}>{translate('common.49')}</p>
            <p className={classes.text}>{translate('common.50')}</p>
            <p className={classes.text}>{translate('common.51')}</p>
            <p className={classes.text}>{translate('common.52')}</p>
            <p className={classes.text}>{translate('common.53')}</p>
            <h2 className={classes.title}>{translate('common.54')}</h2>
            <p className={classes.text}>{translate('common.55')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.56')}</p>
            <p className={classes.text}>{translate('common.57')}</p>
            <p className={classes.text}>{translate('common.58')}</p>
            <p className={classes.text}>{translate('common.59')}</p>
            <p className={classes.text}>{translate('common.60')}</p>
            <p className={classes.text}>{translate('common.61')}</p>
            <p className={classes.text}>{translate('common.62')}</p>
            <p className={classes.text}>{translate('common.63')}</p>
            <h2 className={classes.title}>{translate('common.64')}</h2>
            <p className={classes.text}>{translate('common.65')}</p>
            <p className={classes.text}>{translate('common.66')}</p>
            <p className={classes.text}>{translate('common.67')}</p>
            <p className={classes.text}>{translate('common.68')}</p>
            <p className={classes.text}>{translate('common.69')}</p>
            <p className={classes.text}>{translate('common.70')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.71')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.72')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.73')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.74')}</p>
            <p className={classes.text}>{translate('common.75')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.76')}</p>
            <h2 className={classes.title}>{translate('common.77')}</h2>
            <p className={classes.text}>{translate('common.78')}</p>
            <p className={classes.text}>{translate('common.79')}</p>
            <p className={classes.text}>{translate('common.80')}</p>
            <p className={classes.text}>{translate('common.81')}</p>
            <h2 className={classes.title}>{translate('common.82')}</h2>
            <p className={classes.text}>{translate('common.83')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.84')}</p>
            <p className={classes.text}>{translate('common.85')}</p>
            <p className={classes.text}>{i18n({ link: window.location.origin }, 'common.86')}</p>
            <p className={classes.text}>{translate('common.87')}</p>
        </div>
    )
}

export default ConfidetcialRU;