import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import RulesRU from './blocks/ru';
import {translate} from '../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%', 
        maxWidth: 1140, 
        margin: '120px auto',
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 40px)',
            padding: '0 20px'
        }
    },
}))

const Regilations = () => {
    document.title = translate('title.siteRules');
    const classes = useStyles();

    return (
        <RulesRU />
    )
}

export default Regilations;