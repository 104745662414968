import React from 'react';
import { ReactComponent as Krug } from './krug.svg';
import Present from './present.png';
import "./style.css"
import Btn from '../../../../../../../@casino/ui/btn';
import { useRouter } from '../../../../../../../@casino/hook/useRouter';
import { translate } from '../../../../../../../dictionaries';

const BonusFD = () => {
    const router = useRouter();
    return (
        <div
            style={{ position: 'relative', cursor: 'pointer', width: 560 }}
        >
            <div style={{
                maxWidth: 520,
                width: '100%',
                maxHeight: 218,
                height: '100%',
                background: 'rgba(0, 32, 98, 0.3)',
                position: 'absolute',
                overflow: 'hidden',
                boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.40)',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: 20,
                borderRadius: 5,
            }}>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: 214 }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 20,
                    }}>
                        <div>
                            <h3 className='bonus__wrap__title'>
                                {translate('common.469')}
                            </h3>
                        </div>
                        <div>
                            <span className='bonus__wrap__text'>
                                {translate('common.470')}
                            </span>
                        </div>
                        <div>
                            <Btn
                                title={translate('common.384')}
                                event={(e) => {
                                    e.preventDefault();
                                    router.history.push('/bonuses/cashback')
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <img src={Present} alt="" style={{ width: 230 }} />
                    </div>
                    <Krug className="bonus__circle__background" />
                </div>
            </div>
        </div>
    )
}

export default BonusFD;