import React from 'react';
import {useStyles} from './helper';
import { translate } from '../../../dictionaries';

const TextFieldPassword = ({name, value, title, onChange, placeholder, active}) => {
    const classes = useStyles();
    return (
        <label htmlFor={name} className={classes.labelField}>
            <div className={classes.wrapHelperPassword}>
                <span className={classes.helper}>{title}</span>
                {
                    active === 'auth' &&
                    <span className={classes.helperPassword}>
                        {translate('common.forgot')}
                    </span>
                }
            </div>
            <input 
                type="password" 
                name={name}
                id={name}
                placeholder={placeholder}
                value={value}
                onChange={(v) => onChange(v)}
                className={classes.field}
            />
        </label>
    )
}

export default TextFieldPassword;