import React from 'react';
// import Tournament from '../../../../../@casino/ui/tournamentHome';
import { useDispatch, useSelector } from 'react-redux';
import { setGetLastTournament } from '../../store/thunk/setGetLastTournament';
import { translate } from '../../../../../dictionaries';
import FirstDeposit from '../../../../../@casino/img/firstDeposit.png';
import Cashback from '../../../../../@casino/img/cashback_bonus.png';
import { makeStyles } from '@material-ui/core';
import BonusFD from '../../../bonuses/page/list/components/BonusFD';
import BonusFS from '../../../bonuses/page/list/components/BonusFS';
import BonusCashback from '../../../bonuses/page/list/components/BonusCashback';
import Tournament from '../../../../../@casino/new_ui/tournament';

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: 120,
        maxWidth: 1140,
        width: '100%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0
        }
    },
    title: {
        margin: 0,
        padding: 0,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        color: '#fff',
        textAlign: 'center',
        marginBottom: 52,
    },
    flexBox: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        }
    },
    wrapBonuses: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 85,
        [theme.breakpoints.down('sm')]: {
            margin: '0 10px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 20
        }
    },
    wrapTournament: {
        [theme.breakpoints.down('sm')]: {
            margin: '0 10px'
        }
    },
    firstDeposit: {
        width: 500,
        height: 233,
        background: `url(${FirstDeposit})`,
        display: 'flex',
        flexDirection: 'column',
        padding: '35px 20px 20px 40px',
        rowGap: 17,
        borderRadius: 3,
        boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)'
    },
    cashback: {
        width: 500,
        height: 233,
        background: `url(${Cashback})`,
        display: 'flex',
        flexDirection: 'column',
        padding: '35px 20px 20px 40px',
        rowGap: 15,
        borderRadius: 3,
        boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)'
    },
    label_fd: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 28,
        color: '#fff',
        fontWeight: 700,
        width: 300,
        wordBreak: 'break-word'
    },
    wrapDescription: {
        display: 'flex',
        flexDirection: 'column'
    },
    text: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 14,
        color: '#fff',
        fontWeight: 700,
        lineHeight: '23px'
    },
    textSize: {

    }
}));

const Bonuses = () => {
    const dispatch = useDispatch();
    const classes = useStyles();

    React.useEffect(() => {
        dispatch(setGetLastTournament())
    }, [])

    const tournament = useSelector(state => state.games.lastTournament);

    return (
        <div className={classes.root}>
            <h1 className={classes.title}>{translate('common.bonusesTournament')}</h1>
            <div className={classes.flexBox} style={{display: 'flex', flexDirection: 'column', rowGap: 10}}>
                <div className={classes.wrapBonuses} style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                    <BonusFD />
                    <BonusFS />
                </div>
                <div className={classes.wrapBonuses}  style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                    
                </div>
                <div className={classes.wrapBonuses}  style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row'}}>
                    <BonusCashback />
                    {
                        tournament?.id && (
                            <Tournament
                                {...tournament}
                            />
                        )
                    }
                </div>

            </div>
        </div>
    )
}

export default Bonuses;