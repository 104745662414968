import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMessanger } from '../../main/home/page/mobile_home/store/thunk/setGetMessanger';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../dictionaries';
import { ReactComponent as Telegram } from '../../../@casino/img/telegram.svg'
import { ReactComponent as WhatsApp } from '../../../@casino/img/whatsapp.svg'

const useStyles = makeStyles((theme) => ({
    "@global": {
        "@keyframes fadeIn": {
            "0%": {
                bottom: 0,
                // transform: "translateY(5rem)"
            },
            "30%": {
                bottom: 10,
                //   transform: "translateY(5rem)",
            },
            "100%": {
                bottom: 0,
                // transform: "translateY(0)"
            }
        },
    },
    header: {
        background: 'rgba(0, 32, 98, 0.3)',
        paddingBottom: 30,
    },
    wrapFooter: {
        [theme.breakpoints.down('sm')]: {
            padding: '0 15px'
        },
    },
    wrapAuth: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: 20,
            padding: 10,
            background: '#002062',
            boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
        },
    },
    signin: {
        padding: '10px 35px', background: '#7B61FF', borderRadius: '50px', fontWeight: 500, display: 'flex', alignItems: 'center', color: '#fff'
    },
    signup: {
        padding: '10px 35px', background: '#FF005C', color: '#fff', textAlign: 'center', borderRadius: '50px', fontWeight: 500
    },
    wrapWhatsApp: {
        marginTop: 100,
        position: 'absolute',
        cursor: 'pointer',
        bottom: 0,
        left: 0,
        zIndex: 9999999,
        padding: 5,
        display: 'flex',
        alignItems: 'center',
        columnGap: 12,
        animation: 'fadeIn .45s ease-in-out',
        animationIterationCount: 'infinite'
    },
    whatsApp: {
        width: 70,
        height: 70,

        "&:hover + #description": {
            opacity: 1
        }
    },
    description: {
        position: 'absolute',
        left: 50,
        bottom: 60,
        background: '#fff',
        padding: 5,
        borderRadius: 4,
        opacity: 0,
        transition: 'all .3s ease',
        width: 160
    },
    rootWhatsApp: {
        position: 'fixed',
        bottom: 0,
        left: 70,
        width: 100,
        height: 100,
        zIndex: 999,
        cursor: 'pointer',
        '&:hover > div': {
            opacity: 1
        }
    }
}))

const Messanger = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const messanger = useSelector(state => state.mobile_games.messanger);
    React.useEffect(() => {
        dispatch(setMessanger())
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            {messanger.map((item, key) => {
                if (item.type === 'telegram' && item.website === window.location.origin) {
                    return (
                        <div key={key} className={classes.rootWhatsApp} style={{ left: 10, bottom: 10 }}>
                            <div
                                onClick={() => window.open(item.link, '_blank')}
                                className={classes.wrapWhatsApp}
                                style={{ animation: 'none' }}
                            >
                                <Telegram className={classes.whatsApp} style={{ width: 50, height: 50 }} />
                            </div>

                            <div className={classes.description}>
                                <span>{translate('common.telegram')}</span>
                            </div>
                        </div>
                    )
                }
            })}
        </div>
    )
}

export default Messanger