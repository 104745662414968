import React from 'react';
import {ReactComponent as SteamPays} from '../../../../../../@casino/img/steampay.svg';
import { useSelector } from 'react-redux';

const SteamPay = ({setSelected, formik, limit_min, limit_max, payment_id,}) => {
    const profile = useSelector(state => state.profile.view);

    return (
        <div
            onClick={() => {
                setSelected(true);
                formik.setFieldValue('cardType', Number(payment_id));
                formik.setFieldValue('min', profile?.balance < limit_min ? limit_min : profile?.balance);
                formik.setFieldValue('max', limit_max);
            }}
            style={{overflow: 'hidden',  cursor: 'pointer', width: 110, height: 100, background: '#fff', borderRadius: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <SteamPays />
            <div style={{ background: '#D2D8E3', width: 90, height: 1, marginBottom: 4 }}></div>
            <span style={{
                fontFamily: 'Montserrat, sans-serif',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '12px',
                lineHeight: '17px',
                color: '#8E9EC2',
                paddingBottom: 5
            }}>Steam PAY</span>
        </div>
    )
}

export default SteamPay;