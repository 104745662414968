import {types} from './types';

const initialState = {
    create: {},
    list: [],
}

export function replenishmentReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_REPLENISHMENT_CREATE:
            return { ...state, create: action.payload};
        case types.SET_GET_LIST_PAYMENT:
            return { ...state, list: action.payload};
        default:
            return state
    }
}