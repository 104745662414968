import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        background: '#142A8F',
        boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)',
        borderRadius: '3px',
        width: 320,
        height: 160
    },
    wrapCurrency: {
        padding: 20, 
        borderBottom: '1px solid #0034A5', 
        display: 'flex',
    },
    labelCurrcency: {
        marginLeft: 7,
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '15px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
    },

    balance: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
    },
    labelBalance: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '15px',
        display: 'flex',
        alignItems: 'center',
        color: '#8E9EC2',
    },
    withdrawals: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
    },
    labelWithdrawals: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '15px',
        display: 'flex',
        alignItems: 'center',
        color: '#8E9EC2',
    },
    rootBtn: {
        display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 8
    },
    wrapBtn: {
        display: 'flex',  
        alignItems: 'center'
    },
    btnWithdrawals: {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '15px',
        alignItems: 'center',
        color: '#FFFFFF',
        width: 140,
        padding: '7px 0',
        textAlign: 'center',
        background: '#7B61FF',
        borderRadius: '50px', 
        cursor: 'pointer'   
    },
    btnReplenishment: {
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '15px',
        alignItems: 'center',
        color: '#FFFFFF',
        width: 140,
        padding: '7px 0',
        background: '#FF005C',
        borderRadius: '50px',  
        cursor: 'pointer',
        marginRight: 10   
    }
}))

export {
    useStyles
}