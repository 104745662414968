import React from 'react';
import { makeStyles } from '@material-ui/core';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    label: {
        display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'
    },
    text: {
        color: 'rgba(255,255,255,0.8)',
        fontFamily: 'Montserrat, sans-serif'
    }
}))

const ResponsibleRU = () => {
    const classes = useStyles();

    return (
        <div class="page-content rules">
            <h2 className={classes.label}>{translate('common.142')}</h2>
            <h2 className={classes.label}>{translate('common.143')}</h2>
            <p className={classes.text}>{translate('common.144')}</p>
            <h2 className={classes.label}>{translate('common.145')}</h2>
            <p className={classes.text}>{translate('common.146')}</p>
            <h2 className={classes.label}>{translate('common.147')}</h2>
            <p className={classes.text}>{translate('common.148')}</p>
            <h2 className={classes.label}>{translate('common.149')}</h2>
            <p className={classes.text}>{translate('common.150')}</p>
            <h2 className={classes.label}>{translate('common.151')}</h2>
            <p className={classes.text}>{translate('common.152')}</p>
            <ul className={classes.text}>
                <li>{translate('common.153')}</li>
                <li>{translate('common.154')}</li>
                <li>{translate('common.155')}</li>
                <li>{translate('common.156')}</li>
            </ul>
            <h2 className={classes.label}>{translate('common.157')}</h2>
            <p className={classes.text}>{translate('common.158')}</p>
            <h2 className={classes.label}>{translate('common.159')}</h2>
            <p className={classes.text}>{translate('common.160')}</p>
            <ul className={classes.text}>
                <li>{translate('common.161')}</li>
                <li>{translate('common.162')}</li>
                <li>{translate('common.163')}</li>
            </ul>
        </div>
    )
}

export default ResponsibleRU;