import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    wrapLabel: {
        display: 'flex', 
        justifyContent: 'center', 
    },
    wrapBody: {
        display: 'flex', 
        justifyContent: 'space-between',
        marginBottom: '121px'
    },
    info: {
        color: '#eee',
        fontSize: 14,
        fontStyle: 'normal',
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 600,
    },
    wrapInfo: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        marginTop: 50,
        width: '100%'
    },
    wrapPlacesTable: {
        display: 'grid', 
        gridTemplateColumns: '1fr 1fr 1fr', 
        columnGap: 10,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    wrapPlacesTableMobile: {
        display: 'none', 
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column'
        }
     
    },
    wrapHeaderTable: {
        display: 'flex', 
        paddingBottom: 12,
    },
    wrapHeaderTableMobile: {
        display: 'none', 
       
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            paddingBottom: 12,
        }
    },
    participant: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8E9EC2',
        width: 208,
        paddingLeft: 65,
        [theme.breakpoints.down('sm')]: {
            width: '40%'
        }
    },
    result: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#8E9EC2',
        paddingRight: 20,
        [theme.breakpoints.down('sm')]: {
            width: '40%'
        }
    },
    wrapRow: {
        display: 'flex', 
        alignItems: 'center', 
        paddingBottom: 16, 
        paddingTop: 14, 
        paddingLeft: 20,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'space-between'
        }
    },
    placesSize: {
        padding: 10,
        backgroundColor: '#001137', 
        borderRadius: 3, 
        color: '#fff',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        display: 'flex',
        alignItems: 'center',
    },
    login: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        display: 'flex',
        alignItems: 'center',
        color: '#D2D8E3',
        width: 207,
        paddingLeft: 20,
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    },
    points: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#D2D8E3',
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    }
}))

export {
    useStyles,
}