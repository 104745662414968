import { translate } from '../../../dictionaries';
import { setGetCardTransactionAction } from '../action';
import { getTransaction } from '../api/getTransaction';

export const setGetTransaction = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getTransaction(params);   
            dispatch(setGetCardTransactionAction(data?.data)) 
        } catch(error) {
            alert(translate("common.368"))
        }
    }
}