import {types} from './types';

const initialState = {
    check: {
        status: false,
        game_uuid: ''
    }
}

export function freespinReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_FREESPIN:
            return { ...state, check: action.payload};
        default:
            return state
    }
}