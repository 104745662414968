import React from 'react';
import { translate } from '../../dictionaries';

const Error = () => {
    const cals = window.screen.height - 485;

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: cals}}>
            <h3 style={{
                color: '#D2D8E3',
                display: 'flex',
                fontSize: '18px',
                fontStyle: 'normal',
                alignItems: 'center',
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                lineHeight: '20px',
                marginBottom: '6px',
                textTransform: 'uppercase',
                margin: 0, 
                padding: 0
            }}>{translate('common.error')}!</h3>
            <h4 style={{
                color: '#D2D8E3',
                display: 'flex',
                fontSize: '16px',
                fontStyle: 'normal',
                alignItems: 'center',
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                lineHeight: '40px',
                marginBottom: '6px',
                margin: 0, 
                padding: 0
            }}>{translate('common.pageNotFound')}.</h4>
            <h5 style={{
                color: '#D2D8E3',
                display: 'flex',
                fontSize: '13px',
                fontStyle: 'normal',
                alignItems: 'center',
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 600,
                lineHeight: '20px',
                marginBottom: '6px',
                textDecoration: 'underline',
                margin: 0, 
                padding: 0,
                cursor: 'pointer'
            }}
                onClick={() => window.location.replace(window.location.origin)}
            >{translate('common.backToMain')}</h5>
        </div>
    )
}

export default Error;