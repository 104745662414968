import {types} from './types';

const initialState = {
    slot: {
        list: [],
        page: 1,
    },
    live: {
        list: [],
        page: 1
    },
    pragmatic: {
        list: [],
        page: 1
    },
    hot: {
        list: [],
        page: 1
    },
    messanger: []
}

export function mobileGamesReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GAMES_MOBILE_LIVE:
            return { ...state, live: action.payload};
        case types.SET_GAMES_MOBILE_SLOT: 
            return { ...state, slot: action.payload};
        case types.SET_GAMES_MOBILE_HOT:
            return { ...state, hot: action.payload };
        case types.SET_GAMES_MOBILE_PRAGMATIC:
            return { ...state, pragmatic: action.payload };
        case types.SET_MESSANGER:
            return { ...state, messanger: action.payload};
        default:
            return state
    }
}