
import React from 'react';
import { ReactComponent as FD1 } from '../../../../BonusDeposit/components/BonusCard/icon/fd_1.svg';
import { ReactComponent as FD2 } from '../../../../BonusDeposit/components/BonusCard/icon/fd_2.svg';
import { BonusCard } from '../../../../BonusDeposit/components/BonusCard';
import { translate } from '../../../../../dictionaries';

const PinsaleBonusAfterDeposit = ({
    bonus,
    currency
}) => {
    

    const handleGetDetailBonus = (bonus) => {
        let min_wager = Math.min();
        let max_bonus = Math.max();
        let min_dep = Math.min();

        for (let i = 0; i < bonus?.details?.length; i++) {
            const item = bonus?.details[i];
     
            if (item.bonus > max_bonus) {
                max_bonus = item.bonus;
            }
            if (min_dep > Number(item.deposit_min)) {
                min_dep = Number(item.deposit_min);
            } 
            if (min_wager > item.wager) {
                min_wager = item.wager
            }
        }
        return {
            min_wager,
            max_bonus,
            min_dep
        }
    }

    const { 
        min_wager,
        max_bonus,
        min_dep
    } = React.useMemo(() => handleGetDetailBonus(bonus), [bonus]);

    return (
        <>
            {
                bonus.bonus === 'cash_percent_deposit' && (
                    <BonusCard
                        title={`Бонус на след. дезопит`}
                        description={`Вейджер от x${min_wager}`}
                        min_dep={`Min dep: ${min_dep} ${currency}`}
                        max_bonus={`до ${max_bonus}%`}
                        IconOne={FD1}
                        IconTwo={FD2}
                    />
                )
            }
            {
                bonus.bonus === 'cash_fix_deposit' && (
                    <BonusCard
                        title={`Бонус на след. дезопит`}
                        description={`Вейджер от x${min_wager}`}
                        min_dep={`Min dep: ${min_dep} ${currency}`}
                        max_bonus={`до ${max_bonus} ${currency}`}
                        IconOne={FD1}
                        IconTwo={FD2}
                    />
                )
            }
            {
                bonus.bonus === 'fs_deposit' && (
                    <BonusCard
                        title={`Бонус на след. дезопит`}
                        description={`Вейджер от x${min_wager}`}
                        min_dep={`Min dep: ${min_dep} ${currency}`}
                        max_bonus={`до ${max_bonus} FS`}
                        IconOne={FD1}
                        IconTwo={FD2}
                    />
                )
            }
        </>
    )
}

export default PinsaleBonusAfterDeposit;