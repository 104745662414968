
import { postConfirmationEmail } from '../api/postConfigrmationEmail';

export const setPostConfirmationEmail = (params) => {
    return async (dispatch, getState) => {
        try {
            await postConfirmationEmail(params);
        } catch(error) {
            console.log(error)
        }
    }
}