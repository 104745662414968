import React from 'react';
import {ReactComponent as Ruble} from '../../../../../@casino/img/ruble.svg';
import {useRouter} from '../../../../../@casino/hook/useRouter';
import {useStyles} from './helper';
import {translate} from '../../../../../dictionaries';

const Score = ({profile}) => {
    const classes = useStyles();
    const router = useRouter();
    const summ_with = profile.balance - profile.wager;
    const getCurrency = (currency) => {
        switch (currency) {
            case 'RUB': return '₽';
            case 'USD': return '$';
            case 'EUR': return '€';
            default: return currency
        }
    }
    return (
        <div className={classes.root}>
            <div className={classes.wrapCurrency}>
                <Ruble />
                <span className={classes.labelCurrcency}>{profile?.currency}</span>
            </div>
            <div className={classes.wrapBalance}>
                <span className={classes.balance}>{new Intl.NumberFormat('ru-RU').format(Math.floor((profile.balance)*100)/100)} {getCurrency(profile?.currency)}</span>
                <span className={classes.labelBalance}>{translate('common.amountBalance')}</span>
            </div>
            <div className={classes.wrapWithdrawals}>
                <span className={classes.withdrawals}>{summ_with < 0 ? 0 : new Intl.NumberFormat('ru-RU').format(Math.floor((summ_with)*100)/100)} {getCurrency(profile?.currency)}</span>
                <span className={classes.labelWithdrawals}>{translate('common.amountWithdrawal')}</span>
            </div>
            <div className={classes.rootBtn}>
                <div className={classes.wrapBtn}>
                    <span 
                        className={classes.btnWithdrawals}
                        onClick={() => router.history.push('/profile/replenishment')}
                    >{translate('btn.replenishment')}</span>
                    
                    <span 
                        className={classes.btnReplenishment}
                        onClick={() => router.history.push('/profile/withdrawal')}
                    >{translate('btn.withdrawal')}</span>
                </div>
            </div>
        </div>
    )
}

export default Score