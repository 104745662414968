
import {getFreespin} from '../api/getFreespin';
import { setFreespinAction } from '../action';

export const setGetFreespin = (params, setError) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getFreespin(params);
            
            if (data.status === 'success') {
                dispatch(setFreespinAction(data.data))
            }
          
        } catch(error) {
            console.error(error)
        }
    }
}