import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {setGetTransactionList} from '../layout/store/thunk/setGetTransactionList';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import WinTable from '../../../@casino/ui/WinTable';
import {columns, getRows, columnsMobile, getRowsMobile} from './helper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    desktop: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    }
}))

const Transaction = () => {
    document.title = translate('title.transaction');
    const dispatch = useDispatch();
    const classes = useStyles();

    React.useEffect(() => {
        dispatch(setGetTransactionList({
            page: 1,
            rowsPerPage: 10
        }));
    }, [])

    const transaction = useSelector(state => state.profile_page.transaction);
    const rows = React.useMemo(() => getRows(transaction.list), [transaction.list]);
    const rowsMobile = React.useMemo(() => getRowsMobile(transaction.list), [transaction.list]);
    
    return (
        <WinPageBlock
            title={translate('transaction.title')}
        >   
            <div className={classes.desktop}>
                <WinTable
                    columns={columns}
                    rows={rows}
                    pageCount={transaction.page}
                    changePage={(page) => dispatch(setGetTransactionList({
                        page,
                        rowsPerPage: 10
                    }))}
                    labelNotFound={translate('transaction.infoNotFound')}
                />   
            </div>
            <div  className={classes.mobile}>
                <WinTable
                    columns={columnsMobile}
                    rows={rowsMobile}
                    pageCount={transaction.page}
                    changePage={(page) => dispatch(setGetTransactionList({
                        page,
                        rowsPerPage: 10
                    }))}
                    labelNotFound={translate('transaction.infoNotFound')}
                />   
            </div>
            
        </WinPageBlock>
    )
}

export default Transaction;