import {types} from './types';

export const setBonusesListAction = (payload) => {
    return {
        type: types.SET_BONUSES_LIST,
        payload,
      }
}

export const setHistoryListAction = (payload) => {
  return {
      type: types.SET_HISTORY_LIST,
      payload,
    }
}

export const setTransactionListAction = (payload) => {
  return {
      type: types.SET_TRANSACTION_LIST,
      payload,
    }
}

export const setProfileAction = (payload) => {
  return {
      type: types.SET_PROFILE_VIEW,
      payload,
    }
}

export const setVerificationListAction = (payload) => {
  return {
    type: types.SET_LIST_VERIFICATION,
    payload
  }
}


export const setGetGamesListAction = (payload) => {
  return {
      type: types.SET_GAMES_LIST,
      payload,
    }
}

export const setGetReferralsCheckAction = (payload) => {
  return {
      type: types.SET_PROMOCODE,
      payload,
    }
}

export const setGetReferrerListAction = (payload) => {
  return {
    type: types.SET_REFERRALS_LIST,
    payload
  }
}

export const setGetBonusesViewAction = (payload) => {
  return {
    type: types.SET_BONUSES_VIEW,
    payload
  }
}