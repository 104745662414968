import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    textarea: {
        border: '1px solid #FFFFFF',
        borderRadius: 3,
        outline: 'none',
        background: 'inherit',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        padding: '15px 19px',
        width: '96.5%',
        height: 250,
        resize: 'none',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 13.5
        }
    },
}));

export {
    useStyles
}