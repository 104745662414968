import React from 'react';
import { ReactComponent as FD1 } from '../../../../BonusDeposit/components/BonusCard/icon/fd_1.svg';
import { ReactComponent as FD2 } from '../../../../BonusDeposit/components/BonusCard/icon/fd_2.svg';
import { ReactComponent as RD1 } from '../../../../BonusDeposit/components/BonusCard/icon/rd_1.svg';
import { ReactComponent as RD2 } from '../../../../BonusDeposit/components/BonusCard/icon/rd_2.svg';
import { ReactComponent as TD1 } from '../../../../BonusDeposit/components/BonusCard/icon/td_1.svg';
import { ReactComponent as TD2 } from '../../../../BonusDeposit/components/BonusCard/icon/td_2.svg';
import { ReactComponent as FOD1 } from '../../../../BonusDeposit/components/BonusCard/icon/fo_1.svg';
import { ReactComponent as FOD2 } from '../../../../BonusDeposit/components/BonusCard/icon/fo_2.svg';
import { BonusCard } from '../../../../BonusDeposit/components/BonusCard';
import { translate } from '../../../../../dictionaries';
import BonusUserWager from '../../BonusWager';

const WelcomeBonus = ({
    count_deposit,
    welcomeBonus,
    currency
}) => {
    console.log(welcomeBonus);
    return (
        <div style={{display: 'flex', flexDirection: 'column', rowGap: 15}}>
            {
                welcomeBonus?.status && (
                    <BonusUserWager
                        currency={currency}
                        {...welcomeBonus}
                        rule_one={welcomeBonus?.type === 'cash_fix_deposit' || welcomeBonus?.type === 'cash_percent_deposit' ? '' : welcomeBonus?.type !== 'bonus' ? count_deposit <= 1 ? translate('common.398') : count_deposit <= 2 ? translate('common.404') : count_deposit <= 3 ? translate('common.406') : translate('common.408') : ''}
                        rule_two={count_deposit <= 1 ? translate('common.399') : count_deposit <= 2 ? translate('common.405') : count_deposit <= 3 ? translate('common.407') : `Для полного отыгрыша бонуса необходимо сделать ставок на сумму, <br/>в ${welcomeBonus?.wager || 20} раз превышающую сумму полученного бонуса`}
                    />
                )
            }
            {
                count_deposit < 1 && (
                    <BonusCard
                        title={translate('common.436')}
                        description={translate("common.437")}
                        min_dep={translate("common.447")}
                        max_bonus={translate('common.451')}
                        IconOne={FD1}
                        IconTwo={FD2}
                    />
                )
            }
            {
                count_deposit < 2 && (
                    <BonusCard
                        title={translate("common.443")}
                        description={translate("common.452")}
                        min_dep={translate("common.447")}
                        max_bonus={translate("common.450")}
                        IconOne={RD1}
                        IconTwo={RD2}
                    />
                )
            }
            {
                count_deposit < 3 && (
                    <BonusCard
                        title={translate('common.444')}
                        description={translate("common.453")}
                        min_dep={translate("common.447")}
                        max_bonus={translate("common.449")}
                        IconOne={TD1}
                        IconTwo={TD2}
                    />
                )
            }
            {
                count_deposit < 4 && (
                    <BonusCard
                        title={translate("common.445")}
                        description={translate("common.454")}
                        min_dep={translate("common.447")}
                        max_bonus={translate("common.448")}
                        IconOne={FOD1}
                        IconTwo={FOD2}
                    />
                )
            }
        </div>
    )
}

export default WelcomeBonus;