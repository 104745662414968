import React from 'react';
import { BonusCard } from '../components/BonusCard';
import { ReactComponent as FD1 } from '../components/BonusCard/icon/fd_1.svg';
import { ReactComponent as FD2 } from '../components/BonusCard/icon/fd_2.svg';
import { ReactComponent as RD1 } from '../components/BonusCard/icon/rd_1.svg';
import { ReactComponent as RD2 } from '../components/BonusCard/icon/rd_2.svg';
import { ReactComponent as TD1 } from '../components/BonusCard/icon/td_1.svg';
import { ReactComponent as TD2 } from '../components/BonusCard/icon/td_2.svg';
import { ReactComponent as FOD1 } from '../components/BonusCard/icon/fo_1.svg';
import { ReactComponent as FOD2 } from '../components/BonusCard/icon/fo_2.svg';
import { ReactComponent as Arrow } from '../icon/arrow.svg';
import { ReactComponent as ArrowUp } from '../icon/arrow-up.svg';
import { makeStyles } from '@material-ui/core';
import { translate } from '../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        maxWidth: 1140,
        margin: '52px auto',
    },
    wrapTitle: {
        textAlign: 'center',
        paddingLeft: 13,
        marginBottom: 36
    },
    title: {
        color: '#FFF',
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 30,
        fontWeight: 600,
        textTransform: 'uppercase',
    },
    card: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        rowGap: 30
    },
    rules: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        marginTop: 10,
    },
    wrapRules: {
        display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'
    },
    titleRules: {
        color: '#FFF',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 24,
        fontWeight: 700,
    },
    text: {
        color: '#FFF',
        fontFamily: 'Montserrat',
        fontSize: 16,
        fontWeight: 400,
        lineHeight: '19.543px',
    },
    textBold: {
        color: '#FFF',
        fontFamily: 'Montserrat',
        fontSize: 16,
        fontWeight: 600,
        lineHeight: '19.543px',
    },
    icon: {
        cursor: 'pointer'
    },
    wrapText: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 10,
        width: '100%',
        position: 'relative'
    },
    shadowOption: {
        width: 'calc(100% - 150px)', height: 85, position: 'absolute', top: 0, left: -10, background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(0, 52, 165, 1) 90%)'
    },
    listOption: {
        margin: 0, padding: 0, listStyleType: 'none', marginLeft: 15
    }
}))

const BonusDepositDesktop = () => {
    const [isShowRules, setIsShowRules] = React.useState(false)
    const classes = useStyles();

    const handleChangeShow = () => {
        setIsShowRules((value) => !value)
    }

    return (
        <div className={classes.root}>
            <div className={classes.wrapTitle}>
                <h3 className={classes.title} dangerouslySetInnerHTML={{__html: translate('common.438')}} />
            </div>
            <div className={classes.card}>
                <BonusCard
                    title={translate('common.436')}
                    description={translate("common.437")}
                    min_dep={translate("common.447")}
                    max_bonus={translate('common.451')}
                    IconOne={FD1}
                    IconTwo={FD2}
                />
                <BonusCard
                    title={translate("common.443")}
                    description={translate("common.452")}
                    min_dep={translate("common.447")}
                    max_bonus={translate("common.450")}
                    IconOne={RD1}
                    IconTwo={RD2}
                />
                <BonusCard
                    title={translate('common.444')}
                    description={translate("common.453")}
                    min_dep={translate("common.447")}
                    max_bonus={translate("common.449")}
                    IconOne={TD1}
                    IconTwo={TD2}
                />
                <BonusCard
                    title={translate("common.445")}
                    description={translate("common.454")}
                    min_dep={translate("common.447")}
                    max_bonus={translate("common.448")}
                    IconOne={FOD1}
                    IconTwo={FOD2}
                />
            </div>
            <div className={classes.rules}>
                <div className={classes.wrapRules}>
                    <h3 className={classes.titleRules}>
                        {translate('common.411')}
                    </h3>
                    {
                        !isShowRules && (
                            <Arrow
                                className={classes.icon}
                                onClick={handleChangeShow}
                            />
                        )
                    }
                    {
                        isShowRules && (
                            <ArrowUp
                                className={classes.icon}
                                onClick={handleChangeShow}
                            />
                        )
                    }
                </div>

                <div
                    className={classes.wrapText}
                >
                    {
                        !isShowRules && (
                            <div className={classes.shadowOption} />
                        )
                    }

                    <span className={classes.text}>{translate('common.412')}</span>
                    <span className={classes.text}>{translate('common.413')}</span>
                    <ul className={classes.listOption}>
                        <li className={classes.text} dangerouslySetInnerHTML={{__html: translate('common.414')}} />
                        {
                            isShowRules && (
                                <>
                                    <li className={classes.text} dangerouslySetInnerHTML={{__html: translate('common.415')}} />
                                    <li className={classes.text} dangerouslySetInnerHTML={{__html: translate('common.416')}} />
                                    <li className={classes.text} dangerouslySetInnerHTML={{__html: translate('common.417')}} />
                                </>
                            )
                        }
                    </ul>
                    {
                        isShowRules && (
                            <>
                                <span className={classes.text}>{translate('common.418')}</span>
                                <span className={classes.text}>{translate('common.419')}</span>
                                <span className={classes.text}>{translate('common.420')}</span>
                                <span className={classes.text}>{translate('common.421')}</span>
                                <span className={classes.text}>{translate('common.422')}</span>
                                <span className={classes.text}>{translate('common.423')}</span>
                                <span className={classes.text}>{translate('common.424')}</span>
                                <span className={classes.text}>{translate('common.425')}</span>
                                <span className={classes.text} dangerouslySetInnerHTML={{__html: translate('common.426')}} />
                                <span className={classes.text}>{translate('common.427')}</span>
                                <span className={classes.text}>{translate('common.428')}</span>
                                <span className={classes.text}>{translate('common.429')}</span>
                                <ul className={classes.listOption}>
                                    <li className={classes.text}>{translate('common.430')}</li>
                                    <li className={classes.text}>{translate('common.431')}</li>
                                    <li className={classes.text}>{translate('common.432')}</li>
                                </ul>
                                <span className={classes.text}>{translate('common.433')}</span>
                                <span className={classes.text}>{translate('common.434')}</span>
                            </>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export { BonusDepositDesktop }