import {types} from './types';

export const setGamesLiveAction = (payload) => {
    return {
        type: types.SET_GAMES_MOBILE_LIVE,
        payload,
      }
}


export const setGamesSlotAction = (payload) => {
  return {
      type: types.SET_GAMES_MOBILE_SLOT,
      payload,
    }
}

export const setMessangerAction = (payload) => {
  return {
      type: types.SET_MESSANGER,
      payload,
    }
}

export const setGamesHotAction = (payload) => {
  return {
      type: types.SET_GAMES_MOBILE_HOT,
      payload,
    }
}

export const setGamesPragmaticAction = (payload) => {
  return {
      type: types.SET_GAMES_MOBILE_PRAGMATIC,
      payload,
    }
}
