import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Passport from '../../../@casino/img/passport.png';
import SelfiePassport from '../../../@casino/img/selfiepassport.png';
import SelfieCard from '../../../@casino/img/selfiecard.png';
import Screen from '../../../@casino/img/screen.png';
import ItemBlock from './block';
import { setGetListVerification } from '../layout/store/thunk/setGetListVerification';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    wrapVerification: {
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        flexDirection: 'column', 
        height: 300,
        [theme.breakpoints.down('sm')]: {
            textAlign: 'center'
        }
    },
    verification: {
        color: '#D2D8E3', 
        fontFamily: 'Montserrat, sans-serif', 
        fontSize: 18, 
    }
}))

const Verification = () => {
    document.title = translate('title.verification');
    const dispatch = useDispatch();
    const classes = useStyles();
    React.useEffect(() => {
        dispatch(setGetListVerification())
    }, [])

    const verification = useSelector((state) => state.profile_page.verification)
    const test = [];
    const profile = useSelector(state => state.profile.view);
    const [cardNumber, setCardNumber] = React.useState('')

    return (
        <WinPageBlock 
            title={translate('navigation.verification')}
        >
            
            {
                verification.map((item, key) => {
                if (item.types === 'passport' && !test.includes('passport')) {
                    test.push('passport');
                    return (
                        <ItemBlock 
                            key={key}
                            title={translate("common.passport")}
                            description={translate("common.passportDescription")} 
                            SVGIcon={Passport}
                            type="passport"
                            image={item.photo}
                        />
                    )
                }
                // if (item.types === 'selfie_passport' && !test.includes('selfie_passport')) {
                //     test.push('selfie_passport');
                //     return (
                //         <ItemBlock 
                //             title={translate("common.selfie")}
                //             description={translate("selfieDescription")} 
                //             SVGIcon={SelfiePassport}
                //             type="selfie_passport"
                //             image={item.photo}
                //         />
                //     )
                // }
                // if (item.types === 'selfie_card' && !test.includes('selfie_card')) {
                //     test.push('selfie_card');
                //     return (
                //         <ItemBlock 
                //             title={translate("common.selfieCard" )}
                //             description={translate("common.selfieCardDescription")} 
                //             SVGIcon={SelfieCard}
                //             cardNumber={cardNumber}
                //             setCardNumber={setCardNumber}
                //             type="selfie_card"
                //             image={item.photo}
                //         />
                //     )
                // }
                // if (item.types === 'photo_window_passport' && !test.includes('screen')) {
                //     test.push('screen');
                //     return (
                //         <ItemBlock 
                //             title={translate("common.payment")}
                //             description={translate("common.paymentDescription")}
                //             SVGIcon={Screen}
                //             type="photo_window_passport"
                //             image={item.photo}
                //         />
                //         )
                //     }
                })
            }
               
            {/* {
                !test.includes('passport') && !profile.verification_passport &&
                <ItemBlock 
                    title={translate("common.passport")}
                    description={translate("common.passportDescription")} 
                    SVGIcon={Passport}
                    type="passport"
                />
            } */}
            {/* {
                !test.includes('selfie_passport') && !profile.verification_selfie_passport &&
                <ItemBlock 
                    title={translate("common.selfie")}
                    description={translate("common.selfieDescription")} 
                    SVGIcon={SelfiePassport}
                    type="selfie_passport"
                />
            }  
            {
                !test.includes('selfie_card') && !profile.verification_selfie_card &&
                <ItemBlock 
                    title={translate("common.selfieCard")}
                    description={translate("common.selfieCardDectription")} 
                    SVGIcon={SelfieCard}
                    cardNumber={cardNumber}
                    setCardNumber={setCardNumber}
                    type="selfie_card"
                />
            }
            {
                !test.includes('screen') && !profile.verification_payments &&
                <ItemBlock 
                    title={translate("common.payment")}
                    description={translate("common.paymentDescription")}
                    SVGIcon={Screen}
                    type="photo_window_passport"
                />
            } */}
            {
                profile.verification_passport && //profile.verification_selfie_passport && profile.verification_selfie_card && profile.verification_payments &&
                <div className={classes.wrapVerification}>
                    <span className={classes.verification}>{translate('common.successVerification')}</span>
                </div>
            }
        </WinPageBlock>
    )
}

export default Verification;