import React from 'react';
import { makeStyles } from '@material-ui/core';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    title: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        fontFamily: 'Montserrat, sans-serif', 
        fontSize: 28, 
        color: 'gold'
    },
    label: {
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center', 
        fontFamily: 'Montserrat, sans-serif', 
        fontSize: 24, 
        color: 'gold'
    },
    text: {
        color: 'rgba(255,255,255,0.8)',
        fontFamily: 'Montserrat, sans-serif'
    }
}))

const HelpRU = () => {
    const classes = useStyles();

    return (
        <div class="page-content rules">
            <h2 className={classes.title}>{translate('common.88')}</h2>
            <h3 className={classes.label}>{translate('common.89')}</h3>
            <p className={classes.text}>{translate('common.90')}</p>
            <h3 className={classes.label}>{translate('common.91')}</h3>
            <p className={classes.text}>{translate('common.92')}</p>
            <h3 className={classes.label}>{translate('common.93')}</h3>
            <p className={classes.text}>{translate('common.94')}</p>
            <h3 className={classes.label}>{translate('common.95')}</h3>
            <p className={classes.text}>{translate('common.96')}</p>
            <h3 className={classes.label}>{translate('common.97')}</h3>
            <p className={classes.text}>{translate('common.98')}</p>
            <h3 className={classes.label}>{translate('common.99')}</h3>
            <p className={classes.text}>{translate('common.100')}</p>
            <h3 className={classes.label}>{translate('common.101')}</h3>
            <p className={classes.text}>{translate('common.102')}</p>
            <h3 className={classes.label}>{translate('common.103')}</h3>
            <p className={classes.text}>{translate('common.104')}</p>
            <h3 className={classes.label}>{translate('common.105')}</h3>
            <p className={classes.text}>{translate('common.106')}</p>
            <h3 className={classes.label}>{translate('common.107')}</h3>
            <p className={classes.text}>{translate('common.108')}</p>
            <h3 className={classes.label}>{translate('common.109')}</h3>
            <p className={classes.text}>{translate('common.110')}</p>
        </div>
    )
}

export default HelpRU;