import React from 'react';
import Usdt from '../../../../../../@casino/img/trc20.png';

const USDTWithdrawal = ({formik, setSelected}) => (
    <div style={{marginTop: 25, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 10}}>
        <div style={{display: 'flex', columnGap: 10}}>
            <div 
                onClick={() => {
                    setSelected(true);
                    formik.setFieldValue('type_operation', 'usdt-trc')
                    formik.setFieldValue('method', 'crypto');
                }}
                    style={{cursor: 'pointer', background: '#fff', borderRadius: 3, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <img src={Usdt} style={{ height: 60, paddingBottom: 7, paddingTop: 6 }} />
                <div style={{ background: '#D2D8E3', width: 90, height: 1, marginBottom: 4 }}></div>
                <span style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '12px',
                    lineHeight: '17px',
                    color: '#8E9EC2',
                    paddingBottom: 5
                }}>USDT(TRC20)</span>
            </div>
        </div>
    </div>
)

export default USDTWithdrawal;