import React from 'react';
import { useSelector } from 'react-redux';
import {useRouter} from '../../../../../../../@casino/hook/useRouter';
import { translate } from '../../../../../../../dictionaries';

const SlotGame = () => {
    const games = useSelector((state) => state.mobile_games.slot);
    const router = useRouter();

    return (
        <div style={{
            background: 'rgba(2, 0, 36, 0.8)',
            display: 'flex', 
            flexDirection: 'column',
            padding: 12,
            position: 'relative',
            minHeight: 120
        }}>
            <div  style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 0'}}>
               <span style={{
                   fontFamily: 'Montserrat, sans-serif',
                   fontSize: 20,
                   color: '#fff'
               }}>
                   {translate('navigation.slot')}
               </span>
                <span 
                onClick={() => router.history.push('/mobile/slot')}
                style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 14,
                    color: 'rgb(255, 0, 92)'
                }}>{translate('common.add')}</span>
           </div>
           <div style={{display: 'flex', minHeight: 110, overflowY: 'scroll'}}>
            {
                games.list.map((item, key) => (
                    <div
                        key={key}
                        onClick={() => {
                            window.location.replace(window.location.origin + '/games/' + item.platform_uuid)
                        }}
                        style={{marginRight: 8, borderRadius: 4, position: 'relative'}}
                    >
                        <img 
                            src={item.image}
                            style={{width: 150, height: 110, borderRadius: 4, 
                            }}
                            alt=""/>
                        <div style={{
                            background: 'linear-gradient(0deg, #000 0, rgba(10,14,21,0) 30%',
                            width: 150,
                            height: 110,
                            position: 'absolute',
                            zIndex: 10,
                            top: 0,
                            left: 0
                        }}>

                        </div>
                        <span style={{position: 'absolute', bottom: 10, left: 5, zIndex: 11, color: '#fff', fontSize: 12, fontFamily: 'Montserrat, sans-serif'}}>{item.name}</span>
                    </div>
                ))
            }
           </div>
        </div>
    )
}

export default SlotGame;