import React from 'react';
import Background from './background.png';
import BackgroundDisabled from './backgroundDisabled.png';
import classes from './style.module.css';
import StatusActive from './components/StatusActive';
import Description from './components/Description';
import PrizeFund from './components/PrizeFund';
import Time from './components/Time';
import Places from './components/Places';
import ShowAll from './components/ShowAll';
import StatusDisabed from './components/StatusDisabled';

const Tournament = (props) => {
    const { name, description, fund, top_places, date_to, date_from, id, status } = props

    return (
        <div className={classes.root} style={{ backgroundImage: `url(${status ? Background : BackgroundDisabled})` }}>
            {
                status && <StatusActive />
            }
            {
                !status && <StatusDisabed />
            }

            <Description
                name={name}
                description={description}
                id={id}
                status={status}
            />
            <div className={classes.wrapRight}>
                <PrizeFund
                    fund={fund}
                />

                <Time
                    date_to={date_to}
                    date_from={date_from}
                    status={status}
                />

                <Places
                    top_places={top_places}
                />
                <ShowAll id={id} />
            </div>
        </div>
    )
}

export default Tournament;