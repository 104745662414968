import { combineReducers } from 'redux'
import { bonusesReducer } from '../../components/main/bonuses/store/reducer';
import { authorizationReducer } from '../../components/template/header/store/reducer';
import {gamesReducer} from '../../components/main/home/store/reducer';
import { profileReducer } from '../../components/profile/layout/store/reducer';
import { tournamentReducer } from '../../components/main/tournament/store/reducer';
import {authReducer} from '../authorization/reducer';
import { jackpotReducer } from '../../components/main/jackpot/store/reducer';
import { paymentReducer } from '../../components/payment/store/reducer';
import { callbackReducer } from '../../components/template/callback/store/reducer';
import { replenishmentReducer } from '../../components/profile/replenishment/store/reducer';
import { loadingReducer } from '../../components/loader/store/reducer';
import { loterryReducer } from '../../components/main/lottery/store/reducer';
import { mobileGamesReducer } from '../../components/main/home/page/mobile_home/store/reducer';
import { freespinReducer } from '../../components/template/FreespinModal/store/reducer';
import { gamesBetReducer } from '../../components/main/bet/store/reducer';
import { settingsReducer } from '../settings/reducer';
import { welcomeBonusReducer } from '../welcomeBonus/reducer';
import { cardReducer } from '../card/reducer';
import { cashbackReducer } from '../cashback/reducer';

export const rootReducer = (history) => {
  return combineReducers({
    auth: authorizationReducer,
    games: gamesReducer,
    profile: authReducer,
    profile_page: profileReducer,
    bonuses: bonusesReducer,
    tournament: tournamentReducer,
    jackpot: jackpotReducer,
    payment: paymentReducer,
    callback: callbackReducer,
    replenishment: replenishmentReducer,
    loading: loadingReducer,
    loterry: loterryReducer,
    mobile_games: mobileGamesReducer,
    fs: freespinReducer,
    bet: gamesBetReducer,
    settings: settingsReducer,
    welcomeBonus: welcomeBonusReducer,
    card: cardReducer,
    cashback: cashbackReducer
})}