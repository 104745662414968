import RU from './ru.json';
import EN from './en.json';
import KZ from './kz.json';
import UZ from './uz.json';

export const translate = (values) => {
    const language = localStorage.getItem('language');
    
    switch (language) {
       
        case 'US': {
            if (!EN[values]) {
                return values
            }
            return EN[values]; 
        }
        case 'KZ': {
            if (!KZ[values]) {
                return values
            }
            return KZ[values]; 
        }
        case 'UZ': {
            if (!UZ[values]) {
                return values
            }
            return UZ[values]; 
        }
        
        default: {
            if (!RU[values]) {
                return values
            }
            return RU[values]; 
        }
    }
}

export const i18n = (replaceObject, dictionary) => {
    let textTranslate = translate(dictionary);
    for (let key in replaceObject) {
        textTranslate = textTranslate.replaceAll(`{${key}}`, replaceObject[key]);
    }
    return textTranslate;
}