import React from 'react';

const CircularProgress = ({ percent }) => {
  const circumference = 2 * Math.PI * 50;

  const strokeDashoffset = circumference - (circumference * percent) / 100;

  return (
    <svg width="180" height="180" viewBox="0 0 110 110">
      <circle cx="55" cy="55" r="50" fill="none" stroke="rgba(255, 0, 92, .4)" strokeWidth="8" />
      <circle
        cx="55"
        cy="55"
        r="50"
        fill="none"
        stroke="#FF005C"
        strokeWidth="8"
        strokeLinecap="square"
        strokeDasharray={circumference}
        strokeDashoffset={strokeDashoffset}
        transform="rotate(-90, 55, 55)"
      />
    </svg>
  );
};

export default CircularProgress;