import React from 'react';
import Jackpot from './jackpot';
import {ReactComponent as Cubes} from '../../../../../@casino/img/cubes.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ReactComponent as Slot} from '../../../../../@casino/img/slot.svg';
import {ReactComponent as Live} from '../../../../../@casino/img/live.svg';
import {ReactComponent as Provider} from '../../../../../@casino/img/provider.svg';
import {ReactComponent as StarProvider} from '../../../../../@casino/img/starProvider.svg';
import { useDispatch, useSelector } from 'react-redux';
import {setGetListProvider} from '../../store/thunk/setGetListProvider';
import { setGamesList } from '../../store/thunk/setGamesList';
import {ReactComponent as Search} from '../../../../../@casino/img/search.svg';
import { useRouter } from '../../../../../@casino/hook/useRouter';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex', 
        marginTop: 70, 
        maxWidth: 1140, 
        margin: '0 auto',
    },
    item: {
        boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)',
        borderRadius: 3,
        cursor: 'pointer', 
        width: '100%', 
        minHeight: 152,
        transition: 'background .2s ease', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        marginRight: 19
    },
    text: {
        fontFamily: 'Montserrat, sans-serif',
        transition: 'color .2s ease', 
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#fff'
    },
    wrapProviders: {
        display: 'flex',  
        cursor: 'pointer',
        padding: '10px 13px',
        marginRight: 0,
        borderRadius: 3,
        '&:hover': {
            background: '#FF005C',
        }
    },
    provider: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '15px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',  
    },
    rootWrapProviders: {

        position: 'absolute', 
        top: 1000, 
        left: 10, 
        width: 'calc(100% - 40px)',
        maxWidth: 1110, 
        background: '#142A8F',
        boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)',
        borderRadius: 3,
        borderTop: '1px solid #FF005C',
        padding: '20px 0 20px 22px',
        zIndex: 100,
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    },
    search: {
        border: 'none',
        borderRadius: 3,
        paddingTop: 2,
        height: 40,
        width: 442,
        color: '#D2D8E3',
        fontSize: 16,
        fontFamily: 'Montserrat, sans-serif',
        background: '#142A8F',
        boxShadow: 'inset 0px 4px 4px #07215B',
        paddingLeft: 20,
        outline: 'none',
        margin: '0 10px',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    }
}));

const NavigationMobile = ({jackpot, setProvider, setType}) => {
    const router = useRouter();
    const [active, setActive] = React.useState(router.pathname === '/live' ? 'live' : 'all');
    const [activeNavigation, setActiveNavigation] = React.useState(router.pathname === '/live' ? 'live' : 'all');
    const [viewProvider, setViewProvider] = React.useState(false);
    const [search, setSearch] = React.useState('')
    const classes = useStyles();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetListProvider())    
    }, [])

    const provider = useSelector(state => state.games.list_provider)

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <Jackpot jackpot={jackpot}/>
            </div>
            <div style={{position: 'relative', display: 'flex', justifyContent: 'center'}}>
                <input 
                    type="text" 
                    name="" 
                    id=""
                    placeholder={translate('common.search')}
                    className={classes.search}
                    value={search}
                    onChange={(v) => {
                        dispatch(setGamesList({
                            page: 1,
                            type: '',
                            provider: '',
                            search: v.target.value
                        }));
                        setSearch(v.target.value)
                    }}
                />
                <Search style={{position: 'absolute', top: 15, right: 20}}/>
            </div>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 10, margin: '15px 10px 0'}}>
                <div 
                    onMouseOver={() => {
                        setActive('all')
                        setViewProvider(false)
                    }}
                    onMouseLeave={() => setActive(activeNavigation)}
                    className={classes.item}
                    style={{
                        background: active === 'all' ? '#FF005C' : '#142A8F', 
                    }}
                    onClick={() => {
                        dispatch(setGamesList({
                            page: 1,
                            type: '',
                            provider: '',
                            search: ''
                        }));
                        setSearch('')
                        setActiveNavigation('all');
                        router.history.push('/')
                    }}
                    >
                    <Cubes style={{stroke: active === 'all' ? '#FFF' : '#7B61FF', transition: 'stroke .2s ease',  paddingBottom: 14}}/>
                    <span
                        className={classes.text}
                        
                    >{translate('common.allGames')}</span>
                </div>
                <div 
                    onMouseOver={() => {
                        setActive('slot')
                        setViewProvider(false) 
                    }}
                    onMouseLeave={() => setActive(activeNavigation)}
                    className={classes.item}
                    style={{
                        background: active === 'slot' ? '#FF005C' : '#142A8F'
                    }}
                    onClick={() => {
                        dispatch(setGamesList({
                            page: 1,
                            type: 'slot',
                            provider: '',
                            search: ''
                        }));
                        setSearch('')
                        setActiveNavigation('slot')
                        setType('slot')
                    }}
                >
                    <Slot style={{stroke: active === 'slot' ? '#FFF' : '#7B61FF', transition: 'stroke .2s ease',  paddingBottom: 14}}/>
                    <span
                        className={classes.text}
                    
                    >{translate('common.slot')}</span>
                </div>
                <div 
                    onMouseOver={() => {
                        setActive('live')
                        setSearch('')
                        setViewProvider(false)
                    }}
                    onMouseLeave={() => setActive(activeNavigation)}
                    className={classes.item}
                    style={{
                        background: active === 'live' ? '#FF005C' : '#142A8F'
                    }}
                    onClick={() => {
                        dispatch(setGamesList({
                            page: 1,
                            type: '',
                            provider: 'live',
                            search: ''
                        }));
                        setSearch('')
                        setActiveNavigation('live')
                        setProvider('live');
                        router.history.push('/live')
                    }}    
                    >
                    <Live style={{stroke: active === 'live' ? '#FFF' : '#7B61FF', transition: 'stroke .2s ease',  paddingBottom: 14}}/>
                    <span
                        className={classes.text}
                    
                    >Live</span>
                </div>
                <div 
                    onMouseOver={() => {
                        setActive('provider');
                        setViewProvider(true)
                    }}
                    onMouseLeave={() => setActive(activeNavigation)}
        
                    className={classes.item}
                    style={{
                        background: active === 'provider' ? '#FF005C' : '#142A8F', 
                        
                    }}>
                    <Provider style={{stroke: active === 'provider' ? '#FFF' : '#7B61FF', transition: 'stroke .2s ease',  paddingBottom: 14}}/>
                    <span
                        className={classes.text}
                    >{translate('common.provider')}</span>
                    
                </div>
                <div className={classes.rootWrapProviders}
                    style={{
                        display: viewProvider ? 'flex' : 'none'
                    }}
                    onMouseLeave={() => setViewProvider(false)}
                >
                    {
                        provider.map((item, key) => (
                            <div className={classes.wrapProviders} key={key}
                                onClick={() => {
                                    dispatch(setGamesList({
                                        page: 1,
                                        provider: item.provider,
                                        type: '',
                                        search: ''
                                    }));
                                    setSearch('')
                                    setViewProvider(false)
                                    setProvider(item.provider)
                                }}
                            >
                                <StarProvider style={{paddingRight: 18}}/>
                                <span className={classes.provider}>{item.provider}</span>
                            </div>
                        ))   
                    }           
                </div>
            </div>
        </div>
    )
}

export default NavigationMobile;