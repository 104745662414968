import { postWithdrawalCreateSport } from '../api/postWithdrawalCreateSport';

export const setPostWithdrawalCreateSport = (params, setSuccess, setError) => {
    return async (dispatch, getState) => {
        try {
            const { data } = await postWithdrawalCreateSport(params);
            if (data.status === 'success') {
                setSuccess(true)
            }
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            } else {
                setError(true)
            }
        }
    }
}