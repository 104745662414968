import React from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../dictionaries';
import { useFormik, FormikContext } from "formik";
import { setPostChangePassword } from '../../../../../store/authorization/thunk/setChangePassword';
import { Dialog } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    desktop: {
        display: 'flex',
        justifyContent: 'space-between',
        columnGap: 20,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    mobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center'
        }
    },
    field: {
        border: '1px solid #8E9EC2',
        boxSizing: 'border-box',
        borderRadius: '3px',
        padding: '15px 19px',
        background: 'inherit',
        color: '#fff',
        outline: 'none',
        fontFamily: 'Montserrat, sans-serif',
        '&::placeholder': {
            color: 'rgba(255,255,255,.6)'
        }
    },
    labelHelper: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        width: 171,

    },
    savebtn: {
        padding: '10px 40px',
        background: '#FF005C',
        borderRadius: '50px',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
        cursor: 'pointer'
    }
}))

const ChangePasswordMobile = () => {
    document.title = translate('title.account')
    const classes = useStyles();
    const dispatch = useDispatch();
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [error, setError] = React.useState('');

    const onSubmit = (store) => {
        dispatch(setPostChangePassword(store, setShowSuccess, setError));
        formik.resetForm();
    };
    const initialValues = {
        currentPassword: "",
        newPassword: "",
        newPassword2: ""
    };

    const formik = useFormik({ onSubmit, initialValues });
    const isValid = () => formik.values.currentPassword && formik.values.newPassword && formik.values.newPassword2 && formik.values.newPassword === formik.values.newPassword2 && formik.values.newPassword?.length >= 6 && formik.values.newPassword2?.length >= 6;

    return (
        <FormikContext.Provider value={formik} style={{ width: '100%', marginTop: 20 }}>
            <form onSubmit={formik.handleSubmit} style={{ width: '100%', marginTop: 50 }}>
                <label htmlFor="currentPassword" style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 12 }}>
                    <span className={classes.labelHelper}>{translate('common.currentPassword')}:</span>
                    <input
                        type="password"
                        autocomplete="disabled"
                        placeholder={translate('common.currentPasswordPlaceholder')}
                        className={classes.field}
                        id="currentPassword"
                        onChange={(v) => {
                            formik.setFieldValue('currentPassword', v.target.value);
                        }}
                        value={formik.values.currentPassword}
                    />
                </label>
                {
                    Boolean(error) && (
                        <div style={{ marginBotton: 12 }}>
                            <span style={{ color: 'red' }}>{error}</span>
                        </div>
                    )
                }
                <label htmlFor="newPassword" style={{ display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 12 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className={classes.labelHelper}>{translate('common.newPassword2')}:</span>
                        <span className={classes.labelHelper} style={{ fontSize: 12, width: 'auto' }}>{translate('common.newPasswordHelper')}</span>
                    </div>
                    <input
                        type="password"
                        autocomplete="disabled"
                        placeholder={translate('common.newPasswordPlaceholder')}
                        className={classes.field}
                        id="newPassword"
                        style={{
                            borderColor: formik.values.newPassword2 !== formik.values.newPassword && 'red'
                        }}
                        onChange={(v) => {
                            formik.setFieldValue('newPassword', v.target.value);
                        }}
                        value={formik.values.newPassword}
                    />
                </label>
                <label htmlFor="newPassword2" style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <span className={classes.labelHelper}>{translate('common.repeatPassword')}:</span>
                        <span className={classes.labelHelper} style={{ fontSize: 12, width: 'auto' }}>{translate('common.newPasswordHelper')}</span>
                    </div>
                    <input
                        type="password"
                        autocomplete="disabled"
                        placeholder={translate('common.repeatPasswordPlaceholder')}
                        className={classes.field}
                        id="newPassword2"
                        style={{
                            borderColor: formik.values.newPassword2 !== formik.values.newPassword && 'red'
                        }}
                        onChange={(v) => {
                            formik.setFieldValue('newPassword2', v.target.value);
                        }}
                        value={formik.values.newPassword2}
                    />
                </label>
                {
                    formik.values.newPassword2 !== formik.values.newPassword && (
                        <div style={{ marginBotton: 12 }}>
                            <span style={{ color: 'red' }}>{translate('common.newPassword')}</span>
                        </div>
                    )
                }
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: formik.values.newPassword2 === formik.values.newPassword && 12 }}>
                    <span
                        onClick={() => {
                            if (isValid()) formik.submitForm();
                        }}
                        className={classes.savebtn}
                        style={{ background: !isValid() && 'none', cursor: !isValid() && 'default' }}
                    >{translate('profile.save')}</span>
                </div>
            </form>
            <Dialog
                open={showSuccess}
                onClose={() => setShowSuccess(false)}
            >
                <div style={{ background: '#7B61FF', padding: 20 }}>
                    <span style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '16px',
                        lineHeight: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#FFFFFF'
                    }}>{translate('common.passwordSuccess')}</span>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <span style={{
                            marginTop: 12,
                            borderRadius: '50px',
                            padding: '5px 60px',
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            display: 'flex',
                            alignItems: 'center',
                            background: '#FF005C',
                            color: '#FFFFFF',
                            cursor: 'pointer'
                        }}
                            onClick={() => setShowSuccess(false)}
                        >{translate('common.ok')}</span>
                    </div>

                </div>
            </Dialog>
        </FormikContext.Provider>
    )
}

export default ChangePasswordMobile;