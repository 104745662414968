import JackpotGold from '../../../../../../@casino/img/gold.svg';
import JackpotPlatinum from '../../../../../../@casino/img/platinum.svg';
import JackpotSilver from '../../../../../../@casino/img/silver.svg';
import JackpotBronze from '../../../../../../@casino/img/bronze.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from 'react';
import { translate } from '../../../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    root: {
        cursor: 'pointer', 
        width: 442, 
        height: 138, 
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: 400, 
            height: 125, 
        },
        
    },
    labelJackpot: {
        fontFamily: 'Montserrat, sans-serif',
        transition: 'color .2s ease', 
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        padding: '5px 10px',
        background: 'rgba(0, 0, 0, 0.3)',
        borderRadius: 20
    },
    amountJackpot: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '45px',
        lineHeight: '55px',
        color: '#fff',
    },
    title: {
        margin: 0,
        padding: 0,
        marginBottom: 73,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '30px',
        lineHeight: '37px',
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
        textTransform: 'uppercase',
        color: '#FFFFFF',
    },
    wrapLabel: {
        display: 'flex', 
        justifyContent: 'center'
    },
    wrapJackpot: {
        display: 'flex', 
        justifyContent: 'center', 
        flexWrap: 'wrap', 
        marginBottom: 50, 
        gap: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: 400,
            margin: '0 auto',
            marginBottom: 50
        },
    },
    wrapRoot: {
        width: '100%',
        maxWidth: 1140, 
        margin: '0 auto'
    },
    tableDesktop: {
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    tableMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        },
    }
}))
const getLabel = (val) => {
    switch(val) {
        case 'bronze': return translate('jackpot.bronze');
        case 'silver': return translate('jackpot.silver');
        case 'gold': return translate('jackpot.gold');
        case 'platinum': return translate('jackpot.platinum');
        default: return translate('common.default');
    }
}

const getBackground = (val) => {
    switch(val) {
        case 'bronze': return JackpotBronze;
        case 'silver': return JackpotSilver;
        case 'gold': return JackpotGold;
        case 'platinum': return JackpotPlatinum;
        default: return translate('common.default');
    }
}

const getTime = (val) => {
    const date = new Date(+val);
    const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    const min = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

    return `${hours}:${min}`
}
const columns = [
    {id: 'game', label: translate('common.game'), width: '24%'},
    {id: 'winner', label: translate('common.winners'), width: '19%'},
    {id: 'amount', label: translate('common.bet'), width: '19%'},
    {id: 'date', label: translate('common.time'), width: '19%'},
    {id: 'win', label:  translate('common.win'), width: '19%'}
]

const columnsMobile = [
    {id: 'game', label: translate('common.game'), width: '19%'},
    {id: 'winner', label: translate('common.winners'), width: '39%'},
    {id: 'win', label:  translate('common.win'), width: '39%'},
]

const getRows = (values, router) => {
    return values.map((item, key) => {
        return {
            game:
            <div 
                style={{
                    display: 'flex',
                    width: '100%'
                }}>
                <div style={{display: 'flex', alignItems: 'center', width: '100%', columnGap: 0}}>
                    <div style={{width: '35%', display: 'flex', justifyContent: 'flex-end'}}>
                        <img src={item.image} style={{width: 45, height: 45, background: '#eee', borderRadius: '50%', display: 'block', cursor: 'pointer'}} alt="" onClick={() => window.location.replace(window.location.origin + `/games/${item.uuid}`)}/>
                    </div>
                    <div style={{width: '65%', display: 'flex', justifyItems: 'flex-start', textAlign: 'left'}}>
                    <span style={{
                        paddingLeft: '20px', 
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '18px',
                        lineHeight: '24px',
                        // color: '#fff',
                        cursor: 'pointer'
                        }} onClick={() => window.location.replace(window.location.origin + `/games/${item.uuid}`)}>{item.name}</span>
                        </div>
                </div>
            </div>,
           
            winner: item.user_id,
            amount: item.bet,
            date: getTime(item.event_date),
            win: new Intl.NumberFormat('ru-RU').format(item.amount?.toFixed(2))
        }
    })
}

const getRowsMobile = (values) => {
    return values.map((item, key) => {
        return {
            game:  
            <div style={{
                width: '100%',
                display: 'flex', justifyContent: 'center'
            }}>
                <div style={{display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center'}}>
                    <img src={item.image} style={{width: 45, height: 45, background: '#eee', borderRadius: '50%', display: 'block'}} alt=""/>
                </div>
            </div>,
            winner: item.user_id,
            win: <span style={{color: '#FF005C', fontWeight: 600}}>{new Intl.NumberFormat('ru-RU').format(item.amount)}</span>,
        }
    })
}

export {
    getLabel,
    getBackground,
    columns,
    columnsMobile,
    getRows,
    getRowsMobile,
    useStyles
}