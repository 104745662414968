import React from 'react';
import { translate } from '../../../../../dictionaries';
import BonusesRegistration from '../../bonus_registration';
import BonusesFreespin from '../../freespin';
import FreespinWin from '../../win_freespin';
import { useRouter } from '../../../../../@casino/hook/useRouter';
import { useDispatch } from 'react-redux';
import { setPostBonusConfirm } from '../../../../../store/freespin/thunk/setPostBonusConfirm';
import { setPostCreateFreespin } from '../../../../../store/freespin/thunk/setPostCreateFreespin';
import { setPostCreateFreespinPlayed } from '../../../../../store/freespin/thunk/setPostCreateFreespinPlayed';

const FreespinBonus = ({
    fs,
    fs_played,
    registration,
    dublicate,
    active_bonuses_registration,
    profile,
    loading,
    setLoading
}) => {
    const router = useRouter();
    const dispatch = useDispatch();

    return (
        <div style={{display: 'flex', flexWrap: 'wrap', gap: 15, width: '100%', marginBottom: 15}}>
            {
                (Number(registration) + 864000000 > new Date().getTime()) && !dublicate && !Boolean(active_bonuses_registration) && <BonusesRegistration profile={profile} />
            }
            {
                (Number(fs?.freespin_id) + 172800000 > new Date().getTime()) && Boolean(fs?.freespin_id && fs?.quantity > 0) && <BonusesFreespin profile={profile} btn={translate('common.gaming')} onSubmit={() => router.history.push(`/games/${fs?.game_uuid}`)} date={fs?.freespin_id} max_date={172800000} loading={loading} />
            }
            {
                (Number(fs?.date_from) + 172800000 > new Date().getTime()) && Boolean(!fs?.freespin_id && fs?.status) && <BonusesFreespin profile={profile} btn={translate('common.activate')} onSubmit={() => dispatch(setPostCreateFreespin({ fs_id: fs?.id }, setLoading))} date={fs?.date_from} max_date={172800000} loading={loading} />
            }
            {
                (Number(fs?.freespin_id) + 172800000 > new Date().getTime()) && Boolean(fs?.freespin_id && Number(fs?.quantity) === 0 && fs?.status && fs?.win_amount) && <FreespinWin profile={profile} btn={translate('common.activate')} onSubmit={() => { setLoading(true); if (!loading) { dispatch(setPostCreateFreespinPlayed({ fs_id: fs?.id })) } }} date={fs?.freespin_id} max_date={172800000} wager={fs?.wager_amount} amount={fs?.win_amount} />
            }
            {
                (Number(fs_played?.date_from) + 172800000 > new Date().getTime()) && Boolean(fs_played?.status) && <FreespinWin profile={profile} onSubmit={() => {
                    setLoading(true)
                    if (!loading) {
                        dispatch(setPostBonusConfirm({ fs_played_id: fs_played?.id }))
                    }
                }} date={fs_played?.date_from} max_date={172800000} wager={fs_played?.wager_count} wager_amount={fs_played?.wager} wager_played={fs_played?.wager_played > fs_played?.wager ? fs_played?.wager : fs_played?.wager_played} amount={fs_played?.amount} />
            }
        </div>
    )
}

export default FreespinBonus;