import {getHistoryList} from '../api/getHistoryList';
import { setHistoryListAction } from '../action';


export const setGetHistoryList = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getHistoryList(params);
            if (data.status === 'success') {
                dispatch(setHistoryListAction({
                    list: data.data.history,
                    page: data.data.countPage
                }))
            }
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}