
import {getGamesList} from '../api/getGamesList';
import {setGamesAction} from '../action';
import { setLoadingAction } from '../../../../loader/store/action';

export const setGamesList = (params) => {
    return async (dispatch, getState) => {
        try {
            console.log(params)
            dispatch(setLoadingAction({loading: true}))
            const {data} = await getGamesList(params);
            const prevData = getState().games.list;

            dispatch(setGamesAction({
                list: params.page === 1 ? data.data : [...prevData.list, ...data.data],
                page: Number(data.currentPage),
                totalSize: data.totalSize
            }));
            dispatch(setLoadingAction({loading: false}))
        } catch(error) {
            if (error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}