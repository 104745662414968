import React from 'react';
import { ReactComponent as Telegram } from '../../../../../../../@casino/img/telegram.svg';
import { translate } from '../../../../../../../dictionaries';

const Messanger = () => {
    return (
        <div>
            <div
                onClick={() => window.open('https://t.me/winbingo', '_blank')}
                style={{ display: 'flex', alignItems: 'center', columnGap: 10, padding: 10, background: 'rgba(244, 244, 244,.2)' }}>
                <div style={{ width: 40, height: 25, textAlign: 'center' }}>
                    <Telegram style={{ width: 25, height: 25 }} />
                </div>
                <div style={{ display: 'flex' }}>
                    <span
                        style={{ fontFamily: 'Montserrat, sans-serif', fontSize: 12, color: '#FFF', fontWeight: 400 }}
                    >
                        {translate('common.telegram')}
                    </span>
                </div>
                <div style={{ padding: '5px 10px', background: '#017AFE', borderRadius: '50px', display: 'flex', alignItems: 'ceenter' }}>
                    <span style={{
                        fontFamily: 'Montserrat, sans-serif',
                        color: '#FFFFFF',
                        fontSize: 14,
                    }}>{translate('common.488')}</span>
                </div>
            </div>


        </div>
    )
}

export default Messanger;