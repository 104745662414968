import React from 'react';
import { ReactComponent as Krug } from './krug.svg';
import { ReactComponent as Present } from './present.svg';
import "./style.css"
import Btn from '../../../../../../../@casino/ui/btn';
import { translate } from '../../../../../../../dictionaries';

const BonusFS = () => {
    const [isActiveAnim, setActiveAnim] = React.useState(false);
    return (
        <div
            style={{ position: 'relative', cursor: 'pointer' }}
            onClick={() => setActiveAnim(!isActiveAnim)}
        >
            <div className="bonus" style={{
                transform: isActiveAnim ? 'rotateY(180deg)' : 'rotateY(0deg)',
                opacity: isActiveAnim ? 0 : 1,
                transition: 'opacity 0.2s ease, transform 0.5s ease',
                maxHeight: 218
            }}>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: 264  }}>
                    <div className='bonus__wrap'>
                        <div>
                            <h3 className='bonus__wrap__title' dangerouslySetInnerHTML={{ __html: translate('common.387')}} />
                        </div>
                        <div>
                            <span className='bonus__wrap__text'>
                                {translate('common.388')}
                            </span>
                        </div>
                        <div>
                            <Btn
                                title={translate('common.384')}
                                event={(e) => {
                                    e.preventDefault();
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <Present className="bonus__icon" />
                    </div>
                    <Krug className="bonus__circle__background" />
                </div>
            </div>
            <div className="bonus2" style={{
                transform: !isActiveAnim ? 'rotateY(180deg)' : 'rotateY(0deg)',
                opacity: !isActiveAnim ? 0 : 1,
                transition: 'opacity 0.2s ease, transform 0.5s ease',
                maxHeight: 218
            }}>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: 264  }}>
                    <div className='bonus__wrap'>
                        <div>
                            <h3 className='bonus__wrap__title'>{translate('common.385')}</h3>
                        </div>
                        <div>
                            <div style={{ paddingBottom: 20 }}>
                                <span className='bonus__wrap__text' >
                                    {translate('common.389')}                                    
                                </span>
                            </div>
                            <div>
                                <Btn
                                    title={translate('common.384')}
                                    event={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </div>

                        </div>
                    </div>
                    <Krug className="bonus__circle__background" />
                </div>
            </div>
        </div>
    )
}

export default BonusFS;