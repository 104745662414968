import React from 'react';
import {getArray} from '../../../../../@casino/lib/getArray';
import Label from '../../../../../@casino/ui/label';
import {useStyles} from './helper'
import {translate} from '../../../../../dictionaries';

const TopPlaces = ({tournament}) => {
    const count = tournament.tournament.top_places.length;
 
    const fund_table = getArray(tournament.tournament.top_places, Math.ceil(count/5));
    const fund_table_mobile = getArray(tournament.tournament.top_places, Math.ceil(count/2));

    let fundSize = 0;
    let fundSizeMobile = 0;
    const classes = useStyles();

    return (
        <>
            <div className={classes.wrapLabel}>
                <Label title={translate("lottery.prizePlace")} />
            </div>
            <div className={classes.wrapBody}>
                {
                    fund_table && fund_table.length && fund_table.map((item, key) => (
                        <div key={key}> 
                            {
                                item.map((data, keys) => { 
                                    fundSize += 1;
                                    return (
                                        <div key={keys} className={classes.wrapRow}>
                                            <span className={classes.fundSize} style={{backgroundColor: fundSize === 1 ? '#FFBF39' : fundSize === 2 ? '#A9B9DC' : fundSize === 3 ? '#D3840F' : '#001137', }}>
                                                {fundSize}
                                            </span>
                                            <span className={classes.data} style={{color:  fundSize === 1 ? '#FFBF39' : fundSize === 2 ? '#A9B9DC' : fundSize === 3 ? '#D3840F' : '#FFFFFF'}}>
                                                {data ? new Intl.NumberFormat('ru-RU').format(data) : keys === 0 ? 'iPad pro 12.9(128ГБ)' : 'iPhone 14 pro(128ГБ)'}
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ))
                }
            </div>
            <div className={classes.wrapwrap}>

       
            <div className={classes.wrapBodyMobile}>
                {
                    fund_table_mobile && fund_table_mobile.length && fund_table_mobile.map((item, key) => (
                        <div className={classes.wrapRows} key={key}> 
                            {
                                item.map((data, key) => { 
                                    fundSizeMobile += 1;
                                    return (
                                        <div key={key} className={classes.wrapRow}>
                                            <span className={classes.fundSize} style={{backgroundColor: fundSizeMobile === 1 ? '#FFBF39' : fundSizeMobile === 2 ? '#A9B9DC' : fundSizeMobile === 3 ? '#D3840F' : '#001137', }}>
                                                {fundSizeMobile}
                                            </span>
                                            <span className={classes.data} style={{color:  fundSizeMobile === 1 ? '#FFBF39' : fundSizeMobile === 2 ? '#A9B9DC' : fundSizeMobile === 3 ? '#D3840F' : '#FFFFFF'}}>
                                                {new Intl.NumberFormat('ru-RU').format(data)} 
                                            </span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    ))
                }
            </div>
            </div>
        </>
    )
}

export default TopPlaces