import { types } from './types';

export const setGetSettingsCodeCountryAction = (payload) => {
  return {
    type: types.SET_GET_CODE_COUNTRY,
    payload,
  }
}

export const setGetSettingsCurrencyAction = (payload) => {
  return {
    type: types.SET_GET_CURRENCY,
    payload,
  }
}

export const setGetSettingsBannersAction = (payload) => {
  return {
    type: types.SET_GET_BANNERS,
    payload
  }
}

export const setGetSettingsCountryAction = (payload) => {
  return {
    type: types.SET_GET_COUNTRY,
    payload
  }
}