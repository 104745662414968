import { postPinsale } from '../api/postPinsale';
import { setProfile } from '../../authorization/thunk/setProfile';
import {translate} from '../../../dictionaries';

export const setPostPinsale = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postPinsale(params);
            dispatch(setProfile())
        } catch(error) {
            alert(error?.response?.data?.message || translate('common.error'))
        }
    }
}