import React from 'react';
import { useSelector } from 'react-redux';

const Game = () => {
    const url = useSelector((state) => state.games.gameId);

    
    return (
        <div style={{height: '90%', background: '#1b1b1b'}}>            
            <iframe 
                id="frame"
                src={url} 
                width="100%" 
                height="100%" 
                
                frameborder="1" 
                frameBorder="none" 
                allowFullScreen
                loading="eager"
            >
                    
            </iframe>
        </div>
    )
}

export default Game;