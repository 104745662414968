import React from 'react';
import {ReactComponent as PiastrixIcon} from './piastrix.svg';

const Piastrix = ({formik, setSelected}) => (
    <div style={{marginTop: 25, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 10}}>
        <div style={{display: 'flex', columnGap: 10}}>
            <div 
                onClick={() => {
                    setSelected(true);
                    formik.setFieldValue('type_operation', 'piastrix')
                    formik.setFieldValue('method', 'piastrix');
                }}
                    style={{cursor: 'pointer', width: 150, height: 60, background: '#fff', borderRadius: 3, display: 'flex', alignItems: 'center'}}>
                <PiastrixIcon />
            </div>
        </div>
    </div>
)

export default Piastrix;