import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setPostCall } from '../../../../../../store/call/thunk/setPostCall';
import { translate } from '../../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        background: 'rgba(0,0,0,.3)',
        zIndex: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    wrap: {
        background: '#0034A5',
        padding: 40,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        position: 'relative'
    },
    wrapField: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2.5,
        position: 'relative'
    },
    field: {
        display: 'flex',
        width: 324,
        padding: '16px 17px',
        alignItems: 'center',
        gap: 10,
        borderRadius: 3,
        border: '1px solid #FFF',
        background: 'none',
        outline: 'none',
        color: '#fff',
        "&::placeholder": {
            color: '#8E9EC2',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 16,
            fontWeight: 600,
        },
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    wrapBtn: {
        display: 'flex',
        padding: '20px 80px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        background: '#FF005C',
        boxShadow: '0px 4px 10px 0px #D3193A',
        cursor: 'pointer',
        marginBottom: 20,
        border: 'none'
    },
    btn: {
        color: '#FFF',
        textShadow: '0px 3px 3px rgba(187, 0, 45, 0.70)',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 18,
        fontWeight: 600,
    },
    cross: {
        position: 'absolute',
        top: 15,
        right: 15,
        cursor: 'pointer'
    },
    wrapSelectBlock: {
        display: 'flex',
        columnGap: 20
    },
    wrapSelect: {
        height: 24,
        border: '1px solid #fff',
        position: 'relative',
        padding: 12,
        width: 97,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        cursor: 'pointer',
    },
    text: {
        color: '#8E9EC2',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 10,
        fontWeight: 400,
    },
    arrow: {
        position: 'absolute',
        top: 18,
        right: 12
    },
    flag: {
        width: 35,
        height: 26,
        display: 'block'
    },
    options: {
        padding: 12,
        border: '1px solid #fff',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        width: 97,
        left: -1,
        top: 69,
        background: '#0034A5',
        zIndex: 10,
        // rowGap: 10,
        maxHeight: 250,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 2,
        },

        '&::-webkit-scrollbar-track': {
            background: '#f7f7f7',
            border: '5px solid transparent',
            backgroundClip: 'content-box',
        },

        '&::-webkit-scrollbar-thumb': {
            background: '#e5e5e5',
            border: '1px solid #e5e5e5',
            borderRadius: '3px',
            height: 30,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'grey',
            borderColor: 'grey',
        },
    },
    codeText: {
        color: '#8E9EC2',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 16,
        fontWeight: 600,
    },
    wrapBlockOption: {
        display: 'flex',
        columnGap: 10,
        padding: '5px 0'
    }
}))

const ModalPhone = ({ setIsShowModalPhone }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const arrayCodePhone = useSelector(state => state.settings.code_country);

    const { register, handleSubmit, setError, formState: { errors }, control, setValue } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            iso: 'ru',
            phone: '',
            code: '7',
            name: ''
        },
    });

    const iso = useWatch({
        control,
        name: 'iso',
    })

    const code = useWatch({
        control,
        name: 'code'
    })

    const phone = useWatch({
        control,
        name: 'phone'
    })

    const name = useWatch({
        control,
        name: 'name'
    })

    const [isShowCode, setIsShowCode] = React.useState(false);

    const handleIsShowCode = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsShowCode(!isShowCode);
    }

    const handleSetCodeCountry = ({ iso, code }) => {
        setValue('code', code);
        setValue('iso', iso);
        setIsShowCode(false);
    }

    const isFormValid = !phone
    || !name

    const handleCheckErrors = () => {
        if (!name) {
            setError("name", {
                types: {
                    required: translate("common.348"),
                },
            });
        }
        if (!phone) {
            setError("phone", {
                types: {
                    required: translate("common.348"),
                },
            });
        }
    }
        

    const onSubmit = (store) => {
        if (isFormValid) {
            handleCheckErrors()
        } else {

            const params = {
                phone: String(store.code) + String(store.phone),
                name: store.name,
                callbackSuccess: () => {
                    setIsShowModalPhone(false);
                }
            }

            dispatch(setPostCall(params))
        }
    }

    return (

        <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
            <div className={classes.wrap} style={{display: 'flex', flexDirection: 'column', rowGap: 20}}>
                <Cross className={classes.cross} onClick={() => setIsShowModalPhone(false)} />
                <div style={{ maxWidth: 360, display: 'flex', flexDirection: 'column', rowGap: 20 }}>
                    <h1 style={{
                        padding: 0,
                        margin: 0,
                        color: '#FFF',
                        textAlign: 'center',
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: 16,
                        fontWeight: 600,
                    }}>{translate("common.365")}</h1>
                    <label htmlFor="name" className={classes.wrapField}>
                        <input
                            {...register('name')}
                            type="text"
                            id="name"
                            placeholder={translate("common.366")}
                            className={classes.field}
                            style={{
                                borderColor: errors?.name?.types?.required ? '#FF005C' : '#FFFFFF'
                            }}
                        />
                        <span style={{
                            position: 'absolute',
                            bottom: -15,
                            fontSize: 12,
                            color: '#FF005C'
                        }}>{errors?.name?.types?.required}</span>
                    </label>
                    <div className={classes.wrapSelectBlock}>
                        <div className={classes.wrapSelect} onClick={handleIsShowCode}>
                            <img
                                loading="lazy"
                                src={`https://flagcdn.com/w40/${iso}.png`}
                                srcSet={`https://flagcdn.com/w40/${iso}.png 2x`}
                                className={classes.flag}
                            />
                            <span className={classes.codeText} style={{ color: '#FFFFFF' }}>+{code}</span>
                            <Arrow className={classes.arrow} />
                            {
                                isShowCode && (
                                    <div
                                        className={classes.options}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                        }}
                                    >
                                        {
                                            arrayCodePhone?.map((item) => (
                                                <div
                                                    className={classes.wrapBlockOption}
                                                    onClick={() => {
                                                        handleSetCodeCountry({
                                                            iso: item.iso,
                                                            code: item.code,
                                                        })
                                                    }}
                                                >
                                                    <img
                                                        loading="lazy"
                                                        src={`https://flagcdn.com/w40/${item.iso}.png`}
                                                        srcSet={`https://flagcdn.com/w40/${item.iso}.png 2x`}
                                                        className={classes.flag}
                                                    />
                                                    <span className={classes.codeText}>+{item.code}</span>
                                                </div>
                                            ))
                                        }
                                    </div>
                                )
                            }
                        </div>
                        <label htmlFor="phone" className={classes.wrapField}>
                            <input
                                {...register('phone')}
                                type="number"
                                id="phone"
                                placeholder={translate("common.369")}
                                className={classes.field}
                                style={{
                                    width: 181,
                                    borderColor: errors?.phone?.types?.required ? '#FF005C' : '#FFFFFF'
                                }}
                            />
                            <span style={{
                                position: 'absolute',
                                bottom: -15,
                                fontSize: 12,
                                color: '#FF005C'
                            }}>{errors?.phone?.types?.required}</span>
                        </label>
                    </div>
                </div>
                <button
                    type="submit"
                    className={classes.wrapBtn}
                >
                    <span className={classes.btn}>{translate("common.367")}</span>
                </button>
            </div>
        </form>

    )
}

export default ModalPhone;