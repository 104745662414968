import React from 'react';
import { ReactComponent as Krug } from './krug.svg';
import Present from './present.png';
import "./style.css"
import Btn from '../../../../../../../@casino/ui/btn';
import { useRouter } from '../../../../../../../@casino/hook/useRouter';
import { translate } from '../../../../../../../dictionaries';

const BonusFD = () => {
    const router = useRouter();
    return (
        <div
            style={{ position: 'relative', cursor: 'pointer', width: 560, minHeight: 238 }}
        >
            <div className="bonus" style={{ maxHeight: 218,}}>
                <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'space-between', minHeight: 264 }}>
                    <div className='bonus__wrap'>
                        <div>
                            <h3 className='bonus__wrap__title'>
                                {translate('common.439')}
                            </h3>
                        </div>
                        <div>
                            <span className='bonus__wrap__text' dangerouslySetInnerHTML={{__html: translate('common.442')}}/>
                        </div>
                        <div>
                            <Btn
                                title={translate('common.384')}
                                event={(e) => {
                                    e.preventDefault();
                                    router.history.push('/bonus-deposit')
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <img src={Present} alt="" style={{ width: 230 }} />
                    </div>
                    <Krug className="bonus__circle__background" />
                </div>
            </div>
        </div>
    )
}

export default BonusFD;