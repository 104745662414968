import React from 'react';
import { ReactComponent as Krug } from './krug.svg';
import classes from './style.module.css';
import CircularProgress from '../Donut';
import { translate } from '../../../../dictionaries';

const BonusUserWager = ({
    currency,
    balance_bonus,
    wager_bonus,
    wager_fixed,
    rule_one,
    rule_two
}) => {
    return (
        <div className={classes.bonus}>
            <div style={{ position: 'relative', display: 'flex', width: '100%', flexDirection: 'column' }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    rowGap: 8,
                    alignItems: 'center'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: 'center'
                    }}>
                        <span style={{
                            color: '#FFF',
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: 16,
                            fontWeight: 600,
                            textTransform: 'uppercase',
                        }}>{new Intl.NumberFormat('ru-RU').format((balance_bonus).toFixed(2))} {currency}</span>
                        <span style={{
                            color: '#FFF',
                            textAlign: 'center',
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: 12,
                            fontWeight: 400,
                        }}>{translate('common.403')}</span>
                    </div>
                    <div style={{
                        position: 'relative',
                        height: 180,
                        textAlign: 'center'
                    }}>
                        <CircularProgress percent={100 - (wager_bonus / wager_fixed * 100)} />
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                        }}>
                            <span style={{

                                color: '#FFFFFF',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '10px',
                                fontWeight: 400,
                                textTransform: 'uppercase',
                            }}>
                                <span>{translate('common.446')}:</span><br />
                            </span>
                            <span style={{
                                color: '#FFFFFF',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: '12px',
                                fontWeight: 600,
                                textTransform: 'uppercase',
                            }}>
                                {new Intl.NumberFormat('ru-RU').format((wager_bonus).toFixed(2))} {currency}
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <ul style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 10
                        }}>
                            <li style={{
                                color: '#FFF',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: 12,
                                fontWeight: 400,
                                marginLeft: -20
                            }} dangerouslySetInnerHTML={{ __html: rule_one }}></li>
                            <li style={{
                                color: '#FFF',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: 12,
                                fontWeight: 400,
                                marginLeft: -20
                            }} dangerouslySetInnerHTML={{ __html: rule_two }}></li>
                        </ul>
                    </div>
                </div>
                <Krug className={classes.bonus__circle__background} />
            </div>
        </div>
    )
}

export default BonusUserWager;