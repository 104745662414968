import {types} from './types';

const initialState = {
    listBonuses: [],
}

export function bonusesReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_LIST_BONUSES:
            return { ...state, listBonuses: action.payload};
        default:
            return state
    }
}