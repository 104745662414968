import React from 'react';
import HelpRU from './blocks/ru';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../dictionaries';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '120px auto',
        width: '100%',
        maxWidth: 1140,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 40px)',
            margin: '0 20px'
        }

    }
}))
const Help = () => {
    document.title = translate('title.help');
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <HelpRU />
        </div>
    )
}

export default Help;