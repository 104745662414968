import React from 'react';
import { translate } from '../../../../../../dictionaries';
import { ReactComponent as PiastrixIcon } from './piastrix.svg';
import { useSelector } from 'react-redux';

const PiastrixRub = ({setSelected, formik, limit_min, limit_max, payment_id}) => {
    const profile = useSelector(state => state.profile.view);

    return (
        <div
            onClick={() => {
                setSelected(true);
                formik.setFieldValue('cardType', Number(payment_id));
                formik.setFieldValue('min', profile?.balance < limit_min ? limit_min : profile?.balance);
                formik.setFieldValue('max', limit_max);
            }}
            style={{
                filter: formik.values.cardType === Number(payment_id) ? 'invert(0%)' : 'invert(20%)', overflow: 'hidden', cursor: 'pointer', width: 100, height: 100, background: '#fff', borderRadius: 3, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <PiastrixIcon  style={{width: 'calc(100% - 10px)'}}/>
        </div>
    )
}

export default PiastrixRub;