import {getLastTournament} from '../api/getLastTournament';
import {setGetLastTournamentAction} from '../action';

export const setGetLastTournament = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getLastTournament(params);
    
            if (data.status === 'success') {
                dispatch(setGetLastTournamentAction(data.data));
            }
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}