import React from 'react';
import {ReactComponent as Slot} from '../../../../../../../@casino/img/slot.svg';
import {ReactComponent as Cup} from '../../../../../../../@casino/img/cup.svg';
import {ReactComponent as Live} from './block/live.svg';
import {ReactComponent as Present} from './block/present.svg';
import {ReactComponent as Bonuses} from './block/bonuses.svg';
import {ReactComponent as Home} from '../../../../../../../@casino/img/home.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useRouter} from '../../../../../../../@casino/hook/useRouter';
import { translate } from '../../../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        background: 'rgba(2,0,36,.8)', 
        padding: 10, 
        display: 'flex',
        overflow: 'scroll',
        justifyContent: 'space-between'
    },
    wrapItem: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center', 
        alignItems: 'center', 
        width: 50
    },
    textItem: {
        color: '#fff', 
        fontFamily: 'Montserrat, sans-serif', 
        fontSize: 14, 
        opacity: 0.5
    }
}))

const NavigationMobile = () => {
    const classes = useStyles();
    const router = useRouter();

    const array = [
        {
            id: 1,
            icon: <Home style={{fill: '#fff', width: 30, height: 30}} />,
            text: translate('navigation.main'),
            route: '/'
        },
        {
            id: 2,
            icon: <Live />,
            text: 'Live',
            route: '/mobile/live'
        },
        {
            id: 3,
            icon: <Slot  style={{fill: '#fff', width: 30, height: 30}}/>,
            text: translate('navigation.slot'),
            route: '/mobile/slot'
        },
        {
            id: 4,
            icon: <Cup  style={{fill: '#fff', width: 30, height: 30}}/>,
            text: translate('navigation.tournament'),
            route: '/mobile/tournament'
        }, 
        // {
        //     id: 5,
        //     icon: <Present />,
        //     text: translate("navigation.lotteries"),
        //     route: '/bet'
        // },
        {
            id: 6,
            icon: <Bonuses />,
            text: translate("navigation.bonuses"),
            route: localStorage.getItem('token') ? '/profile/bonuses' : '/mobile/bonuses'
        }
    ]
    return (
        <div className={classes.root}>
            {
                array.map((item, key) => (
                    <div key={key} className={classes.wrapItem} onClick={() => {router.history.push(item.route); }}>
                        {item.icon}
                        <span className={classes.textItem}>{item.text}</span>
                    </div>
                ))
            }
        </div>
    )
}
export default NavigationMobile;