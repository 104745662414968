import { translate } from '../../../dictionaries';
import { postSuccessTransaction } from '../api/postSuccessTransaction';

export const setPostSuccessTransaction = (params, callbackSuccess) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postSuccessTransaction(params);    
            
            if (callbackSuccess) {
                callbackSuccess();
            }

        } catch(error) {
            alert(translate("common.368"))
        }
    }
}