import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: 40,
        display: 'flex', 
        justifyContent: 'space-between',
        paddingBottom: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    wrapLeft: {
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between'
    },
    wrapLogo: {
        [theme.breakpoints.down('sm')]: {
            heght: '50px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around'
        }
    },
    logo: {
        marginBottom: 72,
        width: 230,
        [theme.breakpoints.down('sm')]: {
            heght: '50px',
            marginBottom: 0
            // width: '100%'
        }
    },
    copyright: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '30px',
        color: '#8E9EC2',
    },
    language: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#fff',
        cursor: 'pointer',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    languageMobile: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#fff',
        cursor: 'pointer',
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block'
        }
    },
    activeLanguage: {
        color: '#FF005C'
    },
    navigation: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '30px',
        color: '#8E9EC2',
    },
    navigationItem: {
        cursor: 'pointer',
        color: '#ffffff',
        transition: 'color .3s ease',
        '&:hover': {
            color: '#FF005C',
            transition: 'color .3s ease',
        }
    },
    wrapRight: {
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '30px',
        color: '#8E9EC2',
    },
    wrapRightNavigation: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between'
        }
    }
}));

export {
    useStyles
}