import { postBonusConfirm } from '../api/postBonusConfirm';
import { setProfile } from '../../authorization/thunk/setProfile';
import {translate} from '../../../dictionaries';

export const setPostBonusConfirm = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await postBonusConfirm(params);
            dispatch(setProfile())
        } catch(error) {
            alert(translate('common.error_repeat'))
            console.log(error);
        }
    }
}