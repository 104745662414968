import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    wrap: {
        display: 'flex', 
        alignItems: 'center', 
        marginRight: 30, 
        position: 'relative',
    },
    face: {
        width: 58, 
        height: 58, 
        marginRight: 13, 
        cursor: 'pointer',
        fill: '#fff'
    },
    wrapProfile: {
        position: 'absolute', 
        bottom: -60, 
        left: 0, 
        width: 100, 
        background: 'rgb(29, 46, 125)',
        boxShadow: 'rgb(0 11 36 / 50%) 0px 10px 20px',
        borderRadius: 3,
        zIndex: 9999999999,
    },
    login: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '17px',
        display: 'flex',
        alignItems: 'center',
        color: '#D2D8E3',
        marginBottom: 6,
    },
    balance: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '16px',
        lineHeight: '20px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',
    },
    blockItem: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        color: '#D2D8E3',
        cursor: 'pointer',
        padding: '5px 10px',
        '&:hover': {
            background: 'rgba(255,255,255,0.1)'
        }
    }
}))

const getLogin = (val) => {
    return isNaN(+val) ? val :  '+' + val.slice(0, 1) + ' ' + val.slice(1, 4) + ' ' + val.slice(4, 7) + ' ' + val.slice(7, 9) + '-' + val.slice(9);
}

export {
    useStyles,
    getLogin
}