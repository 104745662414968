import React from 'react';
import Tournament from '../../../../../../../@casino/ui/tournament';
import { useDispatch, useSelector } from 'react-redux';
import {useStyles} from './helper';
import { setGetListTournament } from '../../../../../tournament/store/thunk/setGetListTournament';
import TournamentMobile from '../../components/tournamentMobile';
import LotteryMobile from '../../components/lotteryMobile';
import { setGetLoterryList } from '../../../../../lottery/store/thunk/setGetLoterry';
import { translate } from '../../../../../../../dictionaries';

const LotteryListMobile = ({auth}) => {
    document.title = translate('title.present');
    const dispatch = useDispatch();
    const classes = useStyles();
    const [page, setPage] = React.useState(1);

    React.useEffect(() => {
        dispatch(setGetLoterryList({
            page: 1,
            rowsPerPage: 4
        }))
    }, []);
    
    const loterry = useSelector(state => state.loterry.list);
   
    const cals = window.screen.height - 930;

    return (
        <div style={{background: 'rgba(2, 0, 4, 0.55)', marginBottom: 12}}>
            {
                loterry.data && loterry.data.length === 0 && 
                <div className={classes.info} style={{height: cals}}>
                    <span className={classes.infoTitle} >
                        {translate('lottery.infoNotFound')}
                    </span>
                </div>
            }
            <div className={classes.wrapTournament}>
                {
                    loterry.data.map((item, key) => <LotteryMobile key={key} item={item} />) 
                }
            </div> 
        </div>
    )
}

export default LotteryListMobile;