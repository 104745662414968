import React from 'react';
import { Route, Switch } from 'react-router';
import Footer from '../footer/page/list';
import Header from '../../template/header/page/list';
import BgBottom from '../../../@casino/img/bgbottom.png';
import { configNagiation } from '../../main/config';
import Profile from '../../profile/layout/page';
import { useRouter } from '../../../@casino/hook/useRouter';
import Support from '../../support/page';
import ViewTournament from '../../main/tournament/page/view';
import Error from '../../error';
import ViewPayment from '../../payment/page/view/view';
import Home from '../../main/home/page';
import NotDemo from '../../notDemo';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AuthMobile from '../authMobile';
import Confirmation from '../header/components/confirmation';
import Regilations from '../../regulations/page/view';
import CancellationPolicy from '../../regulations/page/politicalCanselPayment';
import ResponsibleGame from '../../regulations/page/responsible';
import PrivacyPolicy from '../../regulations/page/confidetcial';
import Help from '../../regulations/page/help';
import ViewLoterry from '../../main/lottery/page/view';
import BonusesList from '../../main/bonuses/page/list';
import FreespinModal from '../FreespinModal';
import { translate } from '../../../dictionaries';
import { setMessanger } from '../../main/home/page/mobile_home/store/thunk/setGetMessanger';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Visa } from './visa.svg';
import { ReactComponent as MasterCard } from '../../../@casino/img/mastercard.svg';
import { ReactComponent as Usdt } from '../../../@casino/img/usdt.svg';
import { ReactComponent as Eth } from '../../../@casino/img/ethereum.svg';
import { ReactComponent as LiteCoin } from '../../../@casino/img/litecoin.svg';
import { ReactComponent as Bitcoin } from '../../../@casino/img/bitcoin.svg';
import { ReactComponent as Trx } from '../../../@casino/img/trx.svg';
import { ReactComponent as Ripple } from '../../../@casino/img/ripple.svg';
import { ReactComponent as Dogecoin } from '../../../@casino/img/dogecoin.svg';
import { ReactComponent as Erc } from './erc.svg';

import Kyc from '../../regulations/page/kyc';
import Contacts from '../../main/contacts';
import AuthModal from '../../main/home/page/mobile_home/components/auth/block/authModal';
import SuccessPayment from '../../main/success';
import Messanger from '../messanger';
import { BonusDeposit } from '../../BonusDeposit';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import JackpotList from '../../main/jackpot/page/list';

const useStyles = makeStyles((theme) => ({
    "@global": {
        "@keyframes fadeIn": {
            "0%": {
                bottom: 0,
                // transform: "translateY(5rem)"
            },
            "30%": {
                bottom: 10,
                //   transform: "translateY(5rem)",
            },
            "100%": {
                bottom: 0,
                // transform: "translateY(0)"
            }
        },
    },
    header: {
        background: 'rgba(0, 32, 98, 0.3)',
        paddingBottom: 30,
    },
    wrapFooter: {
        [theme.breakpoints.down('sm')]: {
            padding: '0 15px'
        },
    },
    wrapAuth: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            columnGap: 20,
            padding: 10,
            background: '#002062',
            boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)',
            position: 'fixed',
            bottom: 0,
            left: 0,
            width: '100%',
        },
    },
    signin: {
        padding: '10px 35px', background: '#7B61FF', borderRadius: '50px', fontWeight: 500, display: 'flex', alignItems: 'center', color: '#fff'
    },
    signup: {
        padding: '10px 35px', background: '#FF005C', color: '#fff', textAlign: 'center', borderRadius: '50px', fontWeight: 500
    },
    wrapWhatsApp: {
        marginTop: 100,
        position: 'absolute',
        cursor: 'pointer',
        bottom: 0,
        left: 0,
        zIndex: 9999999,
        padding: 5,
        display: 'flex',
        alignItems: 'center',
        columnGap: 12,
        animation: 'fadeIn .45s ease-in-out',
        animationIterationCount: 'infinite'
    },
    whatsApp: {
        width: 70,
        height: 70,

        "&:hover + #description": {
            opacity: 1
        }
    },
    description: {
        position: 'absolute',
        left: 50,
        bottom: 60,
        background: '#fff',
        padding: 5,
        borderRadius: 4,
        opacity: 0,
        transition: 'all .3s ease',
        width: 160
    },
    rootWhatsApp: {
        position: 'fixed',
        bottom: 0,
        left: 70,
        width: 100,
        height: 100,
        zIndex: 999,
        cursor: 'pointer',
        '&:hover > div': {
            opacity: 1
        }
    }
}))

const Layout = ({ auth }) => {
    const router = useRouter();
    const classes = useStyles();
    const [authModal, setAuthModal] = React.useState(router.pathname === '/auth/mobile');
    const { pathname } = router;

    const bet = router.pathname.indexOf('/bet');
 
    return (
        <div className={classes.root}>
            {
                !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) &&
                <Messanger />

            }
            {
                pathname !== '/' && pathname !== '/auth' && pathname !== '/auth/mobile' && pathname !== '/live' && pathname.indexOf('mobile') === -1 &&
                <div className={classes.header}>
                    <Header auth={auth} />
                </div>
            }

            <div style={{
                background: `url(${BgBottom})`,
                backgroundPositionY: 'bottom',
                backgroundRepeat: 'no-repeat',
            }}>

                <React.Suspense fallback={<div style={{ height: 1000 }}></div>}>
                    <Switch>
                        {
                            configNagiation.map((item, key) => item?.component && <Route exact={item.exact} path={item.path} component={() => <item.component auth={auth} />} />)
                        }
                        <Route path="/auth" exact={true} component={() => <Home />} />
                        <Route path="/mobile" component={() => <Home />} />
                        <Route path="/live" exact={true} component={() => <Home auth={auth} />} />
                        <Route path="/auth/mobile" exact={true} component={() => <Home />} />
                        {!!auth && <Route exact={false} path="/profile" component={() => <Profile />} />}
                        <Route path="/support" component={() => <Support />} />
                        <Route path="/bonuses" exact={true} component={() => <BonusesList />} />
                        <Route path="/tournament/view/:id" component={() => <ViewTournament />} />
                        <Route path="/lotteries/view/:id" component={() => <ViewLoterry />} />
                        {!!auth && <Route path="/payment/billId=:billid" component={() => <ViewPayment />} />}
                        <Route path='/regulations' component={() => <Regilations />} />
                        <Route path='/kyc' component={() => <Kyc />} />
                        <Route path='/cancellation_policy' component={() => <CancellationPolicy />} />
                        <Route path='/responsible_game' component={() => <ResponsibleGame />} />
                        <Route path='/privacy_policy' component={() => <PrivacyPolicy />} />
                        <Route path='/help' component={() => <Help />} />
                        <Route path="/not-demo" render={() => <NotDemo />} />
                        <Route path="/contacts" render={() => <Contacts />} />
                        <Route path="/bonus-deposit" render={() => <BonusDeposit />} />
                        <Route path="/jackpot" render={() => <JackpotList />} />
                        {
                            !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && !localStorage.getItem('token') && (
                                <Redirect to="/?signin=true" />
                            )
                        }
                        {
                            (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && !localStorage.getItem('token') && (
                                <Redirect to="/?signin=true" />
                            )
                        }
                        <Route path="" render={() => <Error />} />
                    </Switch>
                </React.Suspense>
                {
                    bet === -1 && (
                        <>
                            <div style={{ background: 'rgba(4, 27, 78, 0.7)', paddingTop: 10, position: 'relative' }}>

                                <div style={{ width: '100%', maxWidth: 1140, margin: '0 auto', dispay: 'flex', alignItems: 'center' }}>
                                    <Visa />
                                    <MasterCard style={{ height: 50 }} />
                                    <Usdt style={{ marginRight: 12 }} />
                                    <Eth style={{ height: 50, width: 30, marginRight: 12 }} />
                                    <LiteCoin style={{ height: 50, width: 40, marginRight: 12 }} />
                                    <Bitcoin style={{ height: 50, width: 40, marginRight: 12 }} />
                                    <Trx style={{ height: 50, width: 40, marginRight: 12 }} />
                                    <Ripple style={{ marginRight: 12 }} />
                                    <Dogecoin style={{ height: 50, width: 40, marginRight: 12 }} />
                                    <Erc style={{ height: 50, width: 40, marginRight: 12 }} />
                                </div>
                            </div>
                            <div style={{ background: 'rgba(4, 27, 78, 0.7)' }} className={classes.wrapFooter}>
                                <div style={{ width: '100%', maxWidth: 1140, margin: '0 auto' }}>
                                    <Footer />
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
            <AuthModal isShowAuthModal={authModal ? 'signIn' : null} setIsShowAuthModal={setAuthModal} />
        </div>
    )
}

export default Layout;