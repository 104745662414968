import React from 'react';
import { useRouter } from '../../../../../@casino/hook/useRouter';
import Logo from '../../../../../@casino/img/logo.svg';
import {useStyles} from './helper';
import {translate} from '../../../../../dictionaries';
import { ReactComponent as Plus18 } from './plus18.svg';
const Footer = () => {
    const router = useRouter();
    const classes = useStyles();
    
    const isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent));

    return (
        <>
        <div style={{paddingTop: 10}}>
            <span className={classes.copyright}>
                <div dangerouslySetInnerHTML={{__html: translate('common.textfooter')}}/>
           
            </span>
        </div>
        
        <div className={classes.root}>
            <div className={classes.wrapLeft}>
                <div className={classes.wrapLogo}>
                    <img src={Logo} alt="logo" className={classes.logo}/>
                </div>
                <Plus18 style={{ width: 50, height: 50, paddingLeft: 40, paddingBottom: 40, fill: '#fff' }} />
                <span className={classes.copyright}>© {new Date().getFullYear()} {translate('common.copyright')} {translate('common.level')} </span>
            </div>
            {/* <div>
            <a href="https://www.fkwallet.ru"><img src="https://www.fkwallet.ru/assets/2017/images/btns/iconsmall_wallet7.png" title={translate('common.payments.cripto')} /></a>
            </div> */}
            <div className={classes.wrapRightNavigation}>
                <div style={{marginRight: 20}}>
                    <nav className={classes.navigation}>
                        <span className={classes.navigationItem} onClick={() => {
                            if (isMobile) {
                                router.history.push(localStorage.getItem('token') ? '/profile/bonuses' : '/mobile/bonuses'); 
                                window.scrollTo(0, 0);
                            } else {
                                router.history.push(localStorage.getItem('token') ? '/profile/bonuses' : '/bonuses'); 
                                window.scrollTo(0, 0);
                            }
                        }}>{translate('navigation.bonuses')}</span>
                        <span className={classes.navigationItem}  onClick={() => {    
                            router.history.push('/jackpot');
                            window.scrollTo(0, 0);
                        }}>{translate('navigation.jackpot')}</span>
                        <span className={classes.navigationItem}  onClick={() => {
                            if (isMobile) {
                                router.history.push('/mobile/tournament'); 
                                window.scrollTo(0, 0);
                            } else {
                                router.history.push('/tournament'); 
                                window.scrollTo(0, 0);
                            }
                        }}>{translate('navigation.tournament')}</span>
                        <span className={classes.navigationItem}  onClick={() => {router.history.push('/help');  window.scrollTo(0, 0)}}>{translate('navigation.help')}</span>
                        <span className={classes.navigationItem}  onClick={() => {router.history.push('/support'); window.scrollTo(0, 0)}}>{translate('navigation.support')}</span>
                        <span className={classes.navigationItem}  onClick={() => {router.history.push('/contacts'); window.scrollTo(0, 0)}}>{translate('navigation.contact')}</span>
                        <a href="https://win777.partners" target='_blank' className={classes.navigationItem} style={{textDecoration: 'none'}}>{translate('common.490')}</a>
                    </nav>
                </div>
                <div>
                    <nav className={classes.wrapRight}>
                        <span className={classes.navigationItem} onClick={() => {router.history.push('/kyc'); window.scrollTo(0, 0)}}>{translate('navigation.kyc')}</span>
                        <span className={classes.navigationItem} onClick={() => {router.history.push('/regulations'); window.scrollTo(0, 0)}}>{translate('common.regulations')}</span>
                        <span className={classes.navigationItem} onClick={() => {router.history.push('/cancellation_policy'); window.scrollTo(0, 0)}}>{translate('common.cancelPay')}</span>
                        <span className={classes.navigationItem} onClick={() => {router.history.push('/responsible_game'); window.scrollTo(0, 0)}}>{translate('common.responsibleGaming')}</span>
                        <span className={classes.navigationItem} onClick={() => {router.history.push('/privacy_policy'); window.scrollTo(0, 0)}}>{translate('common.privacyPolicy')}</span>
                    </nav>
                </div> 
            </div>
            
        </div>
        </>
    )
}

export default Footer;