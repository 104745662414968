import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useRouter} from '../../../../../@casino/hook/useRouter';
import { setGetViewTournament } from '../../store/thunk/setGetViewTournament';
import BannerTournament from '../../components/bannerTournament';
import TopPlaces from '../../components/topPlaces';
import CurrentResult from '../../components/currentResult';
import {useStyles} from './helper';
import Game from '../../components/game';
import DescriptionTournament from '../../components/description';
import Tournament from '../../../../../@casino/ui/tournament';
import DescriptionMobile from '../../components/descriptionMobile';
import { translate } from '../../../../../dictionaries';

const ViewTournament = () => {
    document.title = translate('title.tournamentWeekly');
    const classes = useStyles();
    const dispatch = useDispatch();
    const router = useRouter();

    const {id} = router.match.params;

    React.useEffect(() => {
        dispatch(setGetViewTournament({
            id
        }));
    }, [id]);

    const tournament = useSelector(state => state.tournament.view);
    
    return (

        <div className={classes.rootDesktop}>
            <div className={classes.banner}>
                <BannerTournament tournament={tournament}/>
            </div>
            <div className={classes.bannerMobile}>
                <h3 style={{
                    fontFamily: 'Montserrat, sans-serif',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '30px',
                    lineHeight: '37px',
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    color: '#FFFFFF',
                    justifyContent: 'center'
                }}>{translate('tournament.title')}</h3>
                <Tournament 
                    name={tournament.tournament.name} 
                    fund={tournament.tournament.fund} 
                    id={tournament.tournament.id} 
                    users={tournament.users_all} 
                    date_to={tournament.tournament.status ? tournament.tournament.date_to : 0} 
                    date_from={tournament.tournament.date_from}
                />
            </div>
            <div style={{width: '100%', marginBottom: 25}}>
            </div>
            <div className={classes.descriptionDesktop}>
                <DescriptionTournament tournament={tournament} />
            </div>
            <div className={classes.descriptionMobile}>
                <DescriptionMobile tournament={tournament}/>
                
            </div>

            <Game tournament={tournament}/>
            <TopPlaces tournament={tournament}/>
            <CurrentResult tournament={tournament} />
        </div>
        
    )
}

export default ViewTournament