import React from 'react';
import { ReactComponent as Cross } from './cross.svg';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { setNewPasswordAfterRestore } from '../../../../../store/authorization/thunk/setNewPasswordAfterRestore';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    field: {
        display: 'flex',
        width: 'calc(100% - 34px)',
        padding: '17px',
        alignItems: 'center',
        gap: 10,
        borderRadius: 3,
        border: '1px solid #FFF',
        background: 'none',
        outline: 'none',
        color: '#fff',
        "&::placeholder": {
            color: '#8E9EC2',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 16,
            fontWeight: 600,
        },
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    wrapBtn: {
        display: 'flex',
        padding: '20px 80px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        background: '#FF005C',
        boxShadow: '0px 4px 10px 0px #D3193A',
        cursor: 'pointer',
        marginBottom: 20,
        border: 'none'
    },
    btn: {
        color: '#FFF',
        textShadow: '0px 3px 3px rgba(187, 0, 45, 0.70)',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 18,
        fontWeight: 600,
    },
}))
const ModalNewPassword = ({
    setIsShowRestorePassword,
    restored_password
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isSuccess, setIsSuccess] = React.useState(false)

    const { register, handleSubmit, setValue, control, setError, formState: { errors } } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            password: ""
        },
    });

    const handleCallbackSuccess = () => {
        setIsSuccess(true)
    }

    const handleCallbackError = (value) => {
        const handleError = () => {
            switch(value) {
                case "expired_at": return translate('common.500');
                case "unknow": return translate('common.501');
            }
        }

        setError(`password`, {
            types: {
                required: handleError(),
            },
        })
    }

    const onSubmit = (store) => {
        if (store.password && restored_password) {
            dispatch(setNewPasswordAfterRestore({
                verification_code: restored_password,
                password: store?.password
            }, handleCallbackSuccess, handleCallbackError))
        }

    }

    return (
        <div style={{ position: 'fixed', top: 0, left: 0, height: '100vh', width: '100vw', background: 'rgba(0,0,0,.2)', zIndex: 5000, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <form onSubmit={handleSubmit(onSubmit)} style={{
                padding: 40,
                paddingBottom: 20,
                background: '#0034A5',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                rowGap: 20,
                borderRadius: 8
            }}>
                <Cross style={{
                    position: 'absolute',
                    top: 15,
                    right: 15,
                    cursor: 'pointer'
                }}
                    onClick={() => setIsShowRestorePassword(false)} />
                    <div style={{textAlign: 'center'}}>
                        <h1 style={{
                            color: '#FFF',
                            fontFamily: 'Montserrat, sans-serif',
                            fontSize: 30,
                            fontWeight: 600,
                            textTransform: 'uppercase',
                            margin: 0,
                            padding: 0
                        }}>{translate('common.newPassword2')}</h1>
                    </div>
                {
                    !isSuccess && (
                        <>
                            <label htmlFor="password" style={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: 2.5,
                                position: 'relative'
                            }}>
                                <input
                                    {...register('password')}
                                    type="password"
                                    id="password"
                                    placeholder={translate('common.newPasswordPlaceholder')}
                                    className={classes.field}
                                    style={{
                                        borderColor: errors?.password?.types?.required ? '#FF005C' : '#FFFFFF'
                                    }}
                                />
                                <span style={{
                                    position: 'absolute',
                                    bottom: -15,
                                    fontSize: 12,
                                    color: '#FF005C'
                                }}>{errors?.password?.types?.required}</span>
                            </label>
                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <button
                                    type="submit"
                                    className={classes.wrapBtn}
                                >
                                    <span className={classes.btn}>{translate('common.493')}</span>
                                </button>
                            </div>
                        </>
                    )
                }
                {
                    isSuccess && (
                        <div style={{textAlign: 'center'}}>
                            <span style={{
                                color: '#FFF',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: 16,
                                fontWeight: 600,
                                margin: 0,
                                padding: 0
                            }} dangerouslySetInnerHTML={{__html: translate('common.492')}} />
                        </div>
                    )
                }

            </form>

        </div>
    )
}

export default ModalNewPassword;