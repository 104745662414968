import React from 'react';
import Bg from './bg-card.png';
import { translate } from '../../../../dictionaries';
import { useRouter } from '../../../../@casino/hook/useRouter';

const BonusCardMobile = ({
    title,
    description,
    min_dep,
    max_bonus,
    IconOne,
    IconTwo,
    route
}) => {
    const router = useRouter();
    return (
        <div style={{
            width: 344,
            height: 187,
            background: `url(${Bg})`,
            position: 'relative'
        }}>
            <div style={{ textAlign: 'center', paddingTop: 25, paddingLeft: 5, display: 'flex', flexDirection: 'column', rowGap: 5 }}>
                <h3 style={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 18,
                    fontWeight: 600,
                    margin: 0,
                    padding: 0
                }}>{title}</h3>
                <span style={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 10,
                    fontWeight: 400,
                }}>{description}</span>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: 27}}>
                <div onClick={() => router.history.push(route)} style={{cursor: 'pointer', background: '#FF005C', borderRadius: 50, minWidth: 106, minHeight: 27, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <span style={{
                        color: '#FFF',
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: 10,
                        fontWeight: 600,
                    }}>{translate('common.435')}</span>
                </div>
            </div>
            <div style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                rowGap: 5,
                marginTop: 20
            }}>
                <span style={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 14,
                    fontWeight: 400,
                }}>{min_dep}</span>
                <span style={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 14,
                    fontWeight: 400,
                    paddingLeft: 5
                }}>{max_bonus}</span>
            </div>
            <IconOne style={{position: 'absolute', top: 65, left: 62, width: 30}}/>
            <IconTwo style={{position: 'absolute', bottom: 38, right: 40, width: 35}}/>
        </div>
    )
}

export { BonusCardMobile }