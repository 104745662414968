import { translate } from '../../../dictionaries';
import { setGetCardAction } from '../action';
import { getCard } from '../api/getCard';

export const setGetCard = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getCard(params);   
            dispatch(setGetCardAction(data)) 
        } catch(error) {
            alert(translate("common.368"))
        }
    }
}