import React from 'react';
import {ReactComponent as Cubes} from '../../../../../@casino/img/cubes.svg';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {ReactComponent as Slot} from '../../../../../@casino/img/slot.svg';
import {ReactComponent as Live} from '../../../../../@casino/img/live.svg';
import {ReactComponent as Provider} from '../../../../../@casino/img/provider.svg';
import Jackpot from './jackpot';
import {ReactComponent as StarProvider} from '../../../../../@casino/img/starProvider.svg';
import { useDispatch, useSelector } from 'react-redux';
import {setGetListProvider} from '../../store/thunk/setGetListProvider';
import { setGamesList } from '../../store/thunk/setGamesList';
import { setLoadingAction } from '../../../../loader/store/action';
import { setGamesAction } from '../../store/action';
import { useRouter } from '../../../../../@casino/hook/useRouter';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex', marginTop: 70, maxWidth: 1140, margin: '0 auto' 
    },
    item: {
        boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)',
        borderRadius: 3,
        cursor: 'pointer', 
        width: 154, 
        height: 138, 
        transition: 'background .2s ease', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        marginRight: 19
    },
    text: {
        fontFamily: 'Montserrat, sans-serif',
        transition: 'color .2s ease', 
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#fff'
    },
    wrapProviders: {
        display: 'flex',  
        cursor: 'pointer',
        width: '15%',
        padding: '10px 13px',
        borderRadius: 3,
        columnGap: 10,
        '&:hover': {
            background: '#FF005C',
        }
    },
    provider: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '12px',
        lineHeight: '15px',
        display: 'flex',
        alignItems: 'center',
        color: '#FFFFFF',  
    },
    rootWrapProviders: {
        position: 'absolute', 
        top: 150, 
        left: 0, 
        width: 1090, 
        background: '#142A8F',
        boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)',
        borderRadius: 3,
        borderTop: '1px solid #FF005C',
        padding: '20px 20px 20px 22px',
        zIndex: 100,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr'
    }

}));


const Navigation = ({jackpot, setProvider, setType}) => {
    const router = useRouter();
    const [active, setActive] = React.useState(router.pathname === '/live' ? 'live' : 'all');
    const [activeNavigation, setActiveNavigation] = React.useState(router.pathname === '/live' ? 'live' : 'all');
    const [viewProvider, setViewProvider] = React.useState(false);
    const classes = useStyles();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(setGetListProvider())    
    }, [])

    const provider = useSelector(state => state.games.list_provider)
    
    return (
        <div className={classes.root} style={{position: 'relative'}}>
           
            <div 
                onMouseOver={() => {
                    setActive('all')
                    setViewProvider(false)
                }}
                onMouseLeave={() => setActive(activeNavigation)}
                className={classes.item}
                style={{
                    background: active === 'all' ? '#FF005C' : '#142A8F', 
                }}
                onClick={() => {
                    setActiveNavigation('all');
                    setType('slots')
                    router.history.push('/')
                }}
                >
                <Cubes style={{stroke: active === 'all' ? '#FFF' : '#7B61FF', transition: 'stroke .2s ease',  paddingBottom: 14}}/>
                <span
                    className={classes.text}
                    
                >{translate('common.allGames')}</span>
            </div>
            <div 
                onMouseOver={() => {
                    setActive('slots')
                    setViewProvider(false) 
                }}
                onMouseLeave={() => setActive(activeNavigation)}
                className={classes.item}
                style={{
                    background: active === 'slots' ? '#FF005C' : '#142A8F'
                }}
                onClick={() => {
                    setActiveNavigation('slots');
                    dispatch(setLoadingAction({loading: true}))
                    dispatch(setGamesAction({
                        list: []
                    }))
                    dispatch(setGamesList({
                        page: 1,
                        pageSize: 25,
                        isDesktop: true,
                        gameType: []
                    }));
                    setType('slots');
                }}
            >
                <Slot style={{stroke: active === 'slots' ? '#FFF' : '#7B61FF', transition: 'stroke .2s ease',  paddingBottom: 14}}/>
                <span
                    className={classes.text}
                  
                >{translate('common.slot')}</span>
            </div>
            <div 
                onMouseOver={() => {
                    setActive('live')
                    setViewProvider(false)
                }}
                onMouseLeave={() => setActive(activeNavigation)}
                className={classes.item}
                style={{
                    background: active === 'live' ? '#FF005C' : '#142A8F'
                }}
                onClick={() => {
                    
                    setActiveNavigation('live');
                    dispatch(setLoadingAction({loading: true}))
                    dispatch(setGamesAction({
                        list: []
                    }))
                    dispatch(setGamesList({
                        page: 1,
                        pageSize: 25,
                        isDesktop: true,
                        gameType: ['dragon tiger', 'sic bo', 'poker', 'andar bahar', 'live dealer', 'baccarat', 'roulette', 'blackjack', 'lottery']
                    }));
                    setType('live dealer');
                }}    
                >
                <Live style={{stroke: active === 'live' ? '#FFF' : '#7B61FF', transition: 'stroke .2s ease',  paddingBottom: 14}}/>
                <span
                    className={classes.text}
                  
                >Live</span>
            </div>
            <div 
                onMouseOver={() => {
                    setActive('provider');
                    setViewProvider(true)
                }}
                onMouseLeave={() => setActive(activeNavigation)}
    
                className={classes.item}
                style={{
                    background: active === 'provider' ? '#FF005C' : '#142A8F', 
                    
                }}>
                <Provider style={{stroke: active === 'provider' ? '#FFF' : '#7B61FF', transition: 'stroke .2s ease',  paddingBottom: 14}}/>
                <span
                    className={classes.text}
                >{translate('common.provider')}</span>
                
            </div>
            <div className={classes.rootWrapProviders}
                style={{
                    display: viewProvider ? 'flex' : 'none',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    zIndex: 1000
                }}
                onMouseLeave={() => setViewProvider(false)}
            >
                {
                    provider.map((item, key) => (
                        <div 
                        key={key}
                        className={classes.wrapProviders} 
                            onClick={() => {
                                setActive('all');
                                setActiveNavigation('all');
                                setType()
                                dispatch(setLoadingAction({loading: true}))
                                dispatch(setGamesAction({
                                    list: []
                                }))
                                dispatch(setGamesList({
                                    page: 1,
                                    pageSize: 25,
                                    providerId: item.id,
                                    isDesktop: true
                                }));
                                setProvider(item.id)
                            }}
                        >
                            <StarProvider />
                            <span className={classes.provider}>{item.name}</span>
                        </div>
                    ))
                    
                }           
            </div>
            <Jackpot jackpot={jackpot}/>
        </div>
    )
}

export default Navigation;