
import { postCreateFreespin } from '../api/postCreateFreespin';
import { setProfile } from '../../authorization/thunk/setProfile';
import { translate } from '../../../dictionaries';

export const setPostCreateFreespin = (params, setLoading) => {
    return async (dispatch, getState) => {
        try {
            if (setLoading) {
                setLoading(true)
            }
            const {data} = await postCreateFreespin(params);
            if (setLoading) {
                setTimeout(() => {
                    dispatch(setProfile(null, setLoading))
                }, 8000);
            }
            
        } catch(error) {
            alert(translate('common.error_repeat'))
            console.log(error);
        }
    }
}