import React from 'react';
import { useDispatch } from 'react-redux';
import {useFormik, FormikContext} from "formik";
import { setPostCreateSupport } from '../store/thunk/setPostCreateSupport';
import Btn from '../../../@casino/ui/btn';
import TextField from '../../../@casino/ui/TextFieldEmail';
import TextFieldEmail from '../../../@casino/ui/TextFieldEmail';
import TextareaField from '../../../@casino/ui/TextareaField';
import {useStyles} from './helper';
import Dialog from '@material-ui/core/Dialog';
import {translate} from '../../../dictionaries';

const Support = () => {
    document.title = translate('title.support')
    const dispatch = useDispatch();
    const classes = useStyles();
    const [showModal, setShowModal] = React.useState('');

    const initialValues = {
        label_name: '',
        message: '',
        email: ''
    }

    const onSubmit = (store) => {
        dispatch(setPostCreateSupport(store, setShowModal));
        formik.resetForm();
    }

    const formik = useFormik({onSubmit, initialValues});

    return (        
        <div className={classes.wrapRoot}>
        <div className={classes.root}>
            <div className={classes.wrapTitle}>
                <h3 className={classes.title}>
                    {translate('support.title')}
                </h3>
            </div>
            <div className={classes.wrapForm}>
                <FormikContext.Provider value={formik}>
                    <form 
                        onSubmit={formik.handleSubmit} 
                        className={classes.form}
                    >
                        <div className={classes.wrapLabel}>
                            <div className={classes.w50}>
                                <TextField
                                    name="label_name"
                                    value={formik.values.label_name}
                                    onChange={(v) => formik.setFieldValue('label_name', v.target.value)}
                                    placeholder={translate("support.appeal")}
                                />
                            </div>
                            <div className={classes.w50}>
                                <TextFieldEmail
                                    name="email"
                                    value={formik.values.email}
                                    onChange={(v) => formik.setFieldValue('email', v.target.value)}
                                    placeholder="email"
                                />
                            </div>
                        </div>
                        <div className={classes.wrapTextArea}>
                            <TextareaField 
                                value={formik.values.message}
                                onChange={(v) => formik.setFieldValue('message', v.target.value)}
                                placeholder={translate("support.message")}
                            />
                        </div>
                        

                        <div className={classes.wrapBtn}>
                            <Btn 
                                title={translate('support.send')}
                                event={() => formik.submitForm()}
                            />
                        </div>
                    </form>
                </FormikContext.Provider>
            </div>
            <Dialog
                open={!!showModal}
                onClose={() => setShowModal('')}
            >
                <div style={{width: 300, padding: 20, textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {
                        showModal === 'error' && 
                        <span style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#2F2F2F',
                        }}>{translate('support.nosend')}</span>
                    }
                    {
                        showModal === 'success' && 
                        <span style={{
                            fontFamily: 'Montserrat, sans-serif',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '16px',
                            lineHeight: '20px',
                            color: '#2F2F2F',
                        }}>{translate('support.successSend')}. <br />{translate('support.willContact')}.</span>
                    }
                    <div style={{display: 'flex'}}>
                        <span 
                            style={{padding: '10px 40px', background: '#FF005C', color: '#fff', marginTop: 20, borderRadius: '50px', cursor: 'pointer'}}
                            onClick={() => setShowModal('')}
                            >
                            {translate('support.close')}
                        </span>
                    </div>
                    
                </div>
            </Dialog>
        </div>
        </div>
    )
}

export default Support;