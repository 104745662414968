import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import { translate } from '../../../dictionaries';
import { setGetWelcomeBonus } from '../../../store/welcomeBonus/thunk/setGetWelcomeBonus';
import BonusWagerMobile from './BonusWagerMobile';
import { ReactComponent as FD1 } from '../../BonusDeposit/components/BonusCard/icon/fd_1.svg';
import { ReactComponent as FD2 } from '../../BonusDeposit/components/BonusCard/icon/fd_2.svg';
import { ReactComponent as RD1 } from '../../BonusDeposit/components/BonusCard/icon/rd_1.svg';
import { ReactComponent as RD2 } from '../../BonusDeposit/components/BonusCard/icon/rd_2.svg';
import { ReactComponent as TD1 } from '../../BonusDeposit/components/BonusCard/icon/td_1.svg';
import { ReactComponent as TD2 } from '../../BonusDeposit/components/BonusCard/icon/td_2.svg';
import { ReactComponent as FOD1 } from '../../BonusDeposit/components/BonusCard/icon/fo_1.svg';
import { ReactComponent as FOD2 } from '../../BonusDeposit/components/BonusCard/icon/fo_2.svg';
import { BonusCardMobile } from '../../BonusDeposit/components/BonusCardMobile';
import PinsaleField from './components/PinsaleField';
import PinsaleBonusAfterDeposit from './components/PinsaleBonus';
import WelcomeBonus from './components/WelcomeBonus';
import FreespinBonus from './components/FreespinBonus';

const Bonuses = () => {
    document.title = translate('title.bonuses')
    const dispatch = useDispatch();


    const profile = useSelector(state => state.profile.view);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        dispatch(setGetWelcomeBonus());
    }, []);

    const welcomeBonus = useSelector((state) => state.welcomeBonus.view);
    
    return (
        <div>
            <WinPageBlock
                title={translate('navigation.bonuses')}
            >
                {
                    !profile?.dublicate && profile?.currency === 'RUB' && profile?.groups_cashiers === 'all' && (
                        <PinsaleField />
                    )
                }
                {
                    profile?.connection_groups !== "true" && (
                        <FreespinBonus 
                            fs={profile?.fs} 
                            fs_played={profile?.fs_played} 
                            registration={profile?.registration}
                            dublicate={profile?.dublicate}
                            active_bonuses_registration={profile?.active_bonuses_registration}
                            profile={profile}
                            loading={loading}
                            setLoading={setLoading}
                        />
                    )
                }

                
                {
                    profile.next_bonus && (
                        <PinsaleBonusAfterDeposit bonus={profile.next_bonus} currency={profile.currency} />
                    )
                }

                {
                    !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && !profile?.dublicate && profile?.connection_groups !== "true" && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 20,
                            marginTop: 20
                        }}>
                            <WelcomeBonus count_deposit={profile?.count_deposit} welcomeBonus={welcomeBonus} currency={profile?.currency} />
                        </div>
                    )
                }
                {
                    (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && !profile?.dublicate && profile?.connection_groups !== "true" && (
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            rowGap: 20,
                            marginTop: 20
                        }}>
                            {
                                welcomeBonus?.status && (
                                    <BonusWagerMobile
                                        currency={profile?.currency}
                                        {...welcomeBonus}
                                        rule_one={welcomeBonus?.type !== 'bonus' ? profile?.count_deposit <= 1 ? translate('common.398') : profile?.count_deposit <= 2 ? translate('common.404') : profile?.count_deposit <= 3 ? translate('common.406') : translate('common.408') : ''}
                                        rule_two={profile?.count_deposit <= 1 ? translate('common.399') : profile?.count_deposit <= 2 ? translate('common.405') : profile?.count_deposit <= 3 ? translate('common.407') : translate('common.409')}
                                    />
                                )
                            }

                            {
                                profile?.count_deposit < 1 && (
                                    <BonusCardMobile
                                        title={translate('common.436')}
                                        description={translate("common.437")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate('common.451')}
                                        IconOne={FD1}
                                        IconTwo={FD2}
                                        route={localStorage.getItem('token') ? "/profile/replenishment" : '/?registration=true'}
                                    />
                                )
                            }
                            {
                                profile?.count_deposit < 2 && (
                                    <BonusCardMobile
                                        title={translate("common.443")}
                                        description={translate("common.452")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate("common.450")}
                                        IconOne={RD1}
                                        IconTwo={RD2}
                                        route={localStorage.getItem('token') ? "/profile/replenishment" : '/?registration=true'}
                                    />
                                )
                            }
                            {
                                profile?.count_deposit < 3 && (
                                    <BonusCardMobile
                                        title={translate('common.444')}
                                        description={translate("common.453")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate("common.449")}
                                        IconOne={TD1}
                                        IconTwo={TD2}
                                        route={localStorage.getItem('token') ? "/profile/replenishment" : '/?registration=true'}
                                    />
                                )
                            }
                            {
                                profile?.count_deposit < 4 && (
                                    <BonusCardMobile
                                        title={translate("common.445")}
                                        description={translate("common.454")}
                                        min_dep={translate("common.447")}
                                        max_bonus={translate("common.448")}
                                        IconOne={FOD1}
                                        IconTwo={FOD2}
                                        route={localStorage.getItem('token') ? "/profile/replenishment" : '/?registration=true'}
                                    />
                                )
                            }
                        </div>
                    )
                }
            </WinPageBlock>
        </div>
    )
}

export default Bonuses;