import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ReactComponent as Cross } from './cross.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useWatch } from 'react-hook-form';
import { translate } from '../../../../../../../../../dictionaries';
import { ReactComponent as Phone } from '../../../../../../../../../@casino/img/phone2.svg';
import { ReactComponent as Email } from '../../../../../../../../../@casino/img/email2.svg';
import { setPostAuthorization } from '../../../../../../../../template/header/store/thunk/setPostAuthorization';

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        background: 'rgba(0,0,0,.3)',
        zIndex: 13,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    wrap: {
        background: '#0034A5',
        padding: 20,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 8,
        position: 'relative',
        height: 420,
        margin: '0 15px'
    },
    wrapTitle: {
        textAlign: 'center',
        marginBottom: 40
    },
    title: {
        color: '#FFF',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 30,
        fontWeight: 600,
        textTransform: 'uppercase',
        margin: 0,
        padding: 0
    },
    wrapField: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 2.5,
        position: 'relative'
    },
    textField: {
        color: '#FFF',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 16,
        fontWeight: 600
    },
    field: {
        display: 'flex',
        width: 324,
        padding: '17px',
        alignItems: 'center',
        gap: 10,
        borderRadius: 3,
        border: '1px solid #FFF',
        background: 'none',
        outline: 'none',
        color: '#fff',
        "&::placeholder": {
            color: '#8E9EC2',
            fontFamily: 'Montserrat, sans-serif',
            fontSize: 16,
            fontWeight: 600,
        },
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    link: {
        color: '#FFF',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 10,
        fontWeight: 400,
        textDecoration: 'underline',
        cursor: 'pointer'
    },
    wrapBtn: {
        display: 'flex',
        padding: '20px 80px',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        background: '#FF005C',
        boxShadow: '0px 4px 10px 0px #D3193A',
        cursor: 'pointer',
        marginBottom: 20,
        border: 'none'
    },
    btn: {
        color: '#FFF',
        textShadow: '0px 3px 3px rgba(187, 0, 45, 0.70)',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 18,
        fontWeight: 600,
    },
    cross: {
        position: 'absolute',
        top: 15,
        right: 15,
        cursor: 'pointer'
    },
    wrapBlockFields: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: 20,
        marginBottom: 10
    },
    wrapSelectBlock: {
        display: 'flex',
        columnGap: 20
    },
    wrapSelect: {
        height: 24,
        border: '1px solid #fff',
        position: 'relative',
        padding: 12,
        width: 97,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        cursor: 'pointer',
    },
    text: {
        color: '#8E9EC2',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 10,
        fontWeight: 400,
    },
    wrapCheckbox: {
        position: 'relative',
        display: 'flex',
        columnGap: 14,
        alignItems: 'flex-start'
    },
    arrow: {
        position: 'absolute',
        top: 18,
        right: 12
    },
    flag: {
        width: 35,
        height: 26,
        display: 'block'
    },
    options: {
        padding: 12,
        border: '1px solid #fff',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        width: 97,
        left: -1,
        top: 69,
        background: '#0034A5',
        zIndex: 10,
        // rowGap: 10,
        maxHeight: 250,
        overflowY: 'scroll',
        '&::-webkit-scrollbar': {
            width: 2,
        },

        '&::-webkit-scrollbar-track': {
            background: '#f7f7f7',
            border: '5px solid transparent',
            backgroundClip: 'content-box',
        },

        '&::-webkit-scrollbar-thumb': {
            background: '#e5e5e5',
            border: '1px solid #e5e5e5',
            borderRadius: '3px',
            height: 30,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: 'grey',
            borderColor: 'grey',
        },
    },
    codeText: {
        color: '#8E9EC2',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 16,
        fontWeight: 600,
    },
    wrapBlockOption: {
        display: 'flex',
        columnGap: 10,
        padding: '5px 0'
    },
    activeTabTwo: {
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        justifyContent: 'center',
        border: '1px solid #FFF',
        padding: 10,
        cursor: 'pointer',
        borderRadius: 5
    },
    noActiveTabTwo: {
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        justifyContent: 'center',
        border: '1px solid rgba(255,255,255,0.5)',
        padding: 10,
        cursor: 'pointer',
        borderRadius: 5
    }
}))

const ModalFormAuth = ({ setShowModal, setIsShowRestorePassword }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { register, handleSubmit, setValue, control, setError, formState: { errors } } = useForm({
        mode: "onChange",
        reValidateMode: "onChange",
        defaultValues: {
            email: "",
            password: "",
            currency: "RUB",
            code: "7",
            iso: "ru",
            phone: "",
            country: translate('common.342'),
            name: "",
            surname: "",
            promocode: "",
            is_rules: true
        },
    });

    const phone = useWatch({
        control,
        name: 'phone'
    })

    const iso = useWatch({
        control,
        name: 'iso'
    })

    const code = useWatch({
        control,
        name: 'code'
    })

    const [isShowCode, setIsShowCode] = React.useState(false);
    const [method, setMethod] = React.useState('phone');


    const handleIsShowCode = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsShowCode(!isShowCode);
    }

    const handleSetCodeCountry = ({ iso, code }) => {
        setValue('code', code);
        setValue('iso', iso);
        setIsShowCode(false);
    }

    const handleCheckErrors = () => {

    }

    const arrayCodePhone = useSelector(state => state.settings.code_country);

    const isFormValid = false;

    const onSubmit = (store) => {
        if (isFormValid) {
            handleCheckErrors()
        } else {
            const tmp = {
                login: method === 'phone' ? String(store.code)+String(store.phone) : store.login,
                password: store.password
            }
            
            const handleSetErrors = () => {
                setError("password", {
                    types: {
                        required: translate('common.343')
                    },
                });
            }

            dispatch(setPostAuthorization(tmp, handleSetErrors));
        }
    }

    React.useEffect(() => {

    }, [phone]);

  

    return (
        <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
            <div className={classes.wrap}>
                <Cross className={classes.cross} onClick={() => setShowModal(false)} />
                <div className={classes.wrapTitle}>
                    <h1 className={classes.title}>{translate("common.371")}</h1>
                </div>
                <div style={{
                    display: 'flex',
                    columnGap: 20,
                    marginBottom: 40
                }}>
                    <div
                        onClick={() => {
                            setMethod('phone');
                        }}
                        className={method === 'phone' ? classes.activeTabTwo : classes.noActiveTabTwo}

                    >
                        <Phone className={classes.mr18} style={{ fill: method === 'phone' ? '#ffffff' : '#8E9EC2', height: 24 }} />
                        <span className={classes.label} style={{ color: method === 'phone' ? '#FFFFFF' : '#8E9EC2' }}>
                            {translate('common.phoneUpper')}
                        </span>
                    </div>
                    <div
                        onClick={() => {
                            setMethod('email');

                        }}
                        className={method === 'email' ? classes.activeTabTwo : classes.noActiveTabTwo}

                    >
                        <Email className={classes.mr5} style={{ fill: method === 'email' ? '#ffffff' : '#8E9EC2' }} />
                        <span className={classes.label2} style={{ color: method === 'email' ? '#FFFFFF' : '#8E9EC2' }}>{translate('common.344')}</span>
                    </div>
                </div>
                {
                    method === 'phone' && (
                        <div className={classes.wrapBlockFields} style={{ marginBottom: 20 }}>
                            <div className={classes.wrapSelectBlock}>
                                <div className={classes.wrapSelect} onClick={handleIsShowCode}>
                                    <img
                                        loading="lazy"
                                        src={`https://flagcdn.com/w40/${iso}.png`}
                                        srcSet={`https://flagcdn.com/w40/${iso}.png 2x`}
                                        className={classes.flag}
                                    />
                                    <span className={classes.codeText} style={{ color: '#FFFFFF' }}>+{code}</span>
                                    <Arrow className={classes.arrow} />
                                    {
                                        isShowCode && (
                                            <div
                                                className={classes.options}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                }}
                                            >
                                                {
                                                    arrayCodePhone?.map((item) => (
                                                        <div
                                                            className={classes.wrapBlockOption}
                                                            onClick={() => {
                                                                handleSetCodeCountry({
                                                                    iso: item.iso,
                                                                    code: item.code,
                                                                })
                                                            }}
                                                        >
                                                            <img
                                                                loading="lazy"
                                                                src={`https://flagcdn.com/w40/${item.iso}.png`}
                                                                srcSet={`https://flagcdn.com/w40/${item.iso}.png 2x`}
                                                                className={classes.flag}
                                                            />
                                                            <span className={classes.codeText}>+{item.code}</span>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                                <label htmlFor="phone" className={classes.wrapField}>
                                    <input
                                        {...register('phone')}
                                        type="number"
                                        id="phone"
                                        placeholder={translate("common.369")}
                                        className={classes.field}
                                        style={{
                                            width: 181,
                                            borderColor: errors?.phone?.types?.required ? '#FF005C' : '#FFFFFF'
                                        }}
                                        inputMode='decimal'
                                    />
                                    <span style={{
                                        position: 'absolute',
                                        bottom: -15,
                                        fontSize: 12,
                                        color: '#FF005C'
                                    }}>{errors?.phone?.types?.required}</span>
                                </label>
                            </div>

                            <label htmlFor="password" className={classes.wrapField}>
                                <input
                                    {...register('password')}
                                    type="password"
                                    id="password"
                                    placeholder={translate('common.345')}
                                    className={classes.field}
                                    style={{
                                        borderColor: errors?.password?.types?.required ? '#FF005C' : '#FFFFFF'
                                    }}
                                />
                                <span style={{
                                    position: 'absolute',
                                    bottom: -15,
                                    fontSize: 12,
                                    color: '#FF005C'
                                }}>{errors?.password?.types?.required}</span>
                            </label>
                        </div>
                    )
                }
                {
                    method === 'email' && (
                        <div className={classes.wrapBlockFields} style={{ marginBottom: 20 }}>
                            <label htmlFor="login" className={classes.wrapField}>
                                <input
                                    {...register('login')}
                                    type="text"
                                    id="login"
                                    placeholder={translate('common.346')}
                                    className={classes.field}
                                    style={{
                                        borderColor: errors?.login?.types?.required ? '#FF005C' : '#FFFFFF'
                                    }}
                                />
                                <span style={{
                                    position: 'absolute',
                                    bottom: -15,
                                    fontSize: 12,
                                    color: '#FF005C'
                                }}>{errors?.login?.types?.required}</span>
                            </label>

                            <label htmlFor="password" className={classes.wrapField}>
                                <input
                                    {...register('password')}
                                    type="password"
                                    id="password"
                                    placeholder={translate('common.345')}
                                    className={classes.field}
                                    style={{
                                        borderColor: errors?.password?.types?.required ? '#FF005C' : '#FFFFFF'
                                    }}
                                />
                                <span style={{
                                    position: 'absolute',
                                    bottom: -15,
                                    fontSize: 12,
                                    color: '#FF005C'
                                }}>{errors?.password?.types?.required}</span>
                            </label>
                        </div>
                    )
                }

                <button
                    type="submit"
                    className={classes.wrapBtn}
                >
                    <span className={classes.btn}>{translate('common.347')}</span>
                </button>
                <div style={{ textAlign: 'center', marginTop: 10, cursor: 'pointer' }}>
                    <span onClick={() => setIsShowRestorePassword(true)}style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '20px',
                        color: '#FFFFFF',
                    }}>{translate('common.499')}</span>
                </div>
            </div>
        </form>
    )
}

export default ModalFormAuth;