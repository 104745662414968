import React from 'react';
import { makeStyles } from '@material-ui/core';
import { i18n, translate } from '../../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%', maxWidth: 1140, margin: '0 auto', color: '#fff'
    },
    paragraf: {
        color: 'rgba(255,255,255,0.8)!important',
        fontFamily: 'Montserrat, sans-serif'
    },
    title: {
        display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold'
    },
    text: {
        color: 'rgba(255,255,255,0.8)',
        fontFamily: 'Montserrat, sans-serif'
    }
}))

const Kyc = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h2 className={classes.title}>{translate('common.111')}</h2>
            <h2 className={classes.title}>
                {translate('common.112')}
            </h2>

            <p className={classes.text}>
                {translate('common.113')}
                <br />
                {translate('common.114')}
                <br />
                {translate('common.115')}
                <br />
                {translate('common.116')}
                <br />
                {translate('common.117')}
            </p>

            <h2 className={classes.title}>
                {translate('common.118')}
            </h2>

            <p className={classes.text}>
                {translate('common.119')}
                <br />
                {translate('common.120')}
                <br />
                {translate('common.121')}
                <br />
                {translate('common.122')}
                <br />
                {translate('common.123')}
                <br />
                {translate('common.124')}
            </p>

            <h2 className={classes.title}>
                {translate('common.125')}
            </h2>

            <p className={classes.text}>
                {translate('common.126')}
                <br />
                {translate('common.127')}
            </p>

            <h2 className={classes.title}>
                {translate('common.128')}
            </h2>

            <p className={classes.text}>
                {translate('common.129')}
            </p>

            <h2 className={classes.title}>
                {translate('common.130')}
            </h2>

            <p className={classes.text}>
                {translate('common.131')}
                <br />
                {i18n({ link: window.location.origin }, 'common.132')}
            </p>

            <h2 className={classes.title}>
                {translate('common.133')}
            </h2>

            <p className={classes.text}>
                {translate('common.134')}
            </p>
        </div>
    )
}

export default Kyc;