import {types} from './types';

export const setGetLoterryListAction = (payload) => {
    return {
        type: types.SET_LIST_LOTERRY,
        payload,
      }
}

export const setGetLoterryViewAction = (payload) => {
  return {
      type: types.SET_VIEW_LOTERRY,
      payload,
    }
}

export const getLoterryWinAction = (payload) => {
  return {
    type: types.SET_WIN_LOTERRY,
    payload
  }
}

export const getLoterryResultAction = (payload) => {
  return {
    type: types.SET_RESULT_LOTERRY,
    payload
  }
}