import {types} from './types';

export const setJackpotListAction = (payload) => {
    return {
        type: types.SET_JACKPOT,
        payload,
      }
}

export const setJackpotHistoryAction = (payload) => {
  return {
      type: types.SET_HISTORY,
      payload,
    }
}

