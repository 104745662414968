import React from 'react';
import {useFormik, FormikContext} from "formik";
import { useDispatch, useSelector } from 'react-redux';
import { setGetProfile } from '../layout/store/thunk/setGetProfile';
import {setPostUpdateProfile} from '../layout/store/thunk/setPostUpdateProfile';
import {ReactComponent as Edit} from '../../../@casino/img/edit.svg';
import WinPageBlock from '../../../@casino/ui/WinPageBlock';
import {useStyles, getDate} from './helper';
import {translate} from '../../../dictionaries';

const Profile = () => {
    document.title = translate('title.profile')
    const dispatch = useDispatch();
    const classes = useStyles();

    React.useEffect(() => {
        dispatch(setGetProfile());
    }, []);

    const initialValues = useSelector(state => state.profile_page.profile);

    const onSubmit = (store) => {
        store.code = 7;
        dispatch(setPostUpdateProfile(store))
    }

    const enableReinitialize = true;
   
    const formik = useFormik({onSubmit, initialValues, enableReinitialize});

    return (
        <FormikContext.Provider value={formik}>
            <form onSubmit={formik.handleSubmit} >
            <WinPageBlock
                title={translate('profile.title')}
                description={translate('profile.personalInformation')}
                Icon={Edit}
            >
                <label htmlFor="name" style={{display: 'flex', alignItems: 'center', marginBottom: 11}}>
                    <span className={classes.labelHelper}>{translate('profile.name')}</span>
                    <input 
                        type="text" 
                        name="name" 
                        id="name" 
                        placeholder={translate('profile.fieldName')}
                        value={formik.values.name}
                        onChange={(v) => formik.setFieldValue('name', v.target.value)}
                        className={classes.field2}
                        disabled={initialValues.name}
                    />
                </label>
                <label htmlFor="surname" style={{display: 'flex', alignItems: 'center', marginBottom: 11}}>
                    <span className={classes.labelHelper}>{translate('profile.surname')}</span>
                    <input 
                        type="text" 
                        name="surname" 
                        id="surname" 
                        placeholder={translate('profile.fieldSurname')}
                        value={formik.values.surname}
                        onChange={(v) => formik.setFieldValue('surname', v.target.value)}
                        className={classes.field2}
                        disabled={initialValues.surname}
                    />
                </label>
                <label htmlFor="username" style={{display: 'flex', alignItems: 'center', marginBottom: 11}}>
                    <span className={classes.labelHelper}>{translate('profile.happyBithday')}</span>
                    <input 
                        type="date" 
                        name="username" 
                        id="username" 
                        disabled={initialValues.birthday}
                        className={classes.calendar}
                        value={formik.values.birthday ? getDate(formik.values.birthday) : ''}
                        onChange={(v) => formik.setFieldValue('birthday', new Date(v.target.value).getTime())}
                    />
                </label>
                <div htmlFor="gender" style={{display: 'flex', alignItems: 'center', marginBottom: 11}}>
                    <span className={classes.labelHelper3}>{translate('profile.male')}</span>
                    <div style={{display: 'flex'}}>
                        <div style={{marginRight: 20, display: 'flex', alignItems: 'center'}}>
                            {
                                formik.values.gender === 'male' &&
                                <div         
                                    style={{width: 15, height: 15, border: '1px solid #fff', borderRadius: '50%', position: 'relative', zIndex: 1, marginRight: 10}}>
                                    <span style={{width: 11, height: 11, background: '#fff', cursor: 'pointer', borderRadius: '50%', position: 'absolute', top: 'calc(50% - 5px)', left: 'calc(50% - 5px)', zIndex: 10}}></span>
                                </div>
                            }
                            {
                                formik.values.gender !== 'male' && 
                                <div 
                                    onClick={() => formik.setFieldValue('gender', 'male')}
                                    style={{width: 15, height: 15, borderRadius: '50%', border: '1px solid #8E9EC2', marginRight: 10, cursor: 'pointer', }}>
                                </div>
                            }
                            <span 
                                onClick={() => formik.setFieldValue('gender', 'male')}
                                style={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontStyle: 'normal',
                                    fontWeight: 'normal',
                                    fontSize: '16px',
                                    lineHeight: '20px',
                                    color: formik.values.gender === 'male' ? '#FFFFFF' : '#8E9EC2',
                                    cursor: 'pointer',
                                }}
                                >{translate('profile.man')}</span>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {
                                formik.values.gender === 'female' &&
                                <div style={{width: 15, cursor: 'pointer', height: 15, border: '1px solid #fff', borderRadius: '50%', position: 'relative', zIndex: 1, marginRight: 10}}>
                                    <span style={{width: 11, height: 11, background: '#fff', borderRadius: '50%', position: 'absolute', top: 'calc(50% - 5px)', left: 'calc(50% - 5px)', zIndex: 10}}></span>
                                </div>
                            }
                            {
                                formik.values.gender !== 'female' && 
                                <div onClick={() => formik.setFieldValue('gender', 'female')} style={{cursor: 'pointer', width: 15, height: 15, borderRadius: '50%', border: '1px solid #8E9EC2', marginRight: 10}}>
                                </div>
                            }
                            <span 
                                onClick={() => formik.setFieldValue('gender', 'female')}
                                style={{
                                    fontFamily: 'Montserrat, sans-serif',
                                    fontStyle: 'normal',
                                    fontWeight: 'normal',
                                    fontSize: '16px',
                                    lineHeight: '20px',
                                    color: formik.values.gender === 'female' ? '#FFFFFF' : '#8E9EC2',
                                    cursor: 'pointer',
                                }}>
                                    {translate('profile.woman')}
                            </span>
                        </div>
                    </div>
                </div>
                <label htmlFor="country" style={{display: 'flex', alignItems: 'center', marginBottom: 11}}>
                    <span className={classes.labelHelper}>{translate('profile.country')}</span>
                    <input 
                        type="text" 
                        name="country" 
                        id="country" 
                        placeholder={translate('profile.fieldCountry')}
                        value={formik.values.country}
                        onChange={(v) => formik.setFieldValue('country', v.target.value)}
                        className={classes.field2}
                        disabled={initialValues.country}
                    />
                </label>
                <label htmlFor="city" style={{display: 'flex', alignItems: 'center', marginBottom: 11}}>
                    <span className={classes.labelHelper}>{translate('profile.city')}</span>
                    <input 
                        type="text" 
                        name="city" 
                        id="city" 
                        placeholder={translate('profile.fieldCity')}
                        value={formik.values.city}
                        onChange={(v) => formik.setFieldValue('city', v.target.value)}
                        className={classes.field2}
                        disabled={initialValues.city}
                    />
                </label>
                <label htmlFor="email" style={{display: 'flex', alignItems: 'center', marginBottom: 11}}>
                    <span className={classes.labelHelper}>E-mail</span>
                    <input 
                        type="text" 
                        name="email" 
                        id="email" 
                        placeholder={translate('profile.fieldEmail')}
                        value={formik.values.email}
                        onChange={(v) => formik.setFieldValue('email', v.target.value)}
                        className={classes.field2}
                        disabled={initialValues.email}
                    />
                </label>           
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 40}}>
                    <span 
                        onClick={() => formik.submitForm()}
                        className={classes.savebtn}
                    >{translate('profile.save')}</span>
                </div>
   
            </WinPageBlock>
            </form>
        </FormikContext.Provider>
    )
}

export default Profile;