import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useStyles} from './helper';
import { setGetListTournament } from '../../../../../tournament/store/thunk/setGetListTournament';
import { translate } from '../../../../../../../dictionaries';
import TournamentMobile from '../../../../../../../@casino/new_ui/tournamentMobile';

const PromotionsList = ({auth}) => {
    document.title = translate('title.tournament')
    const dispatch = useDispatch();
    const classes = useStyles();
    const [page, setPage] = React.useState(1);

    React.useEffect(() => {
        dispatch(setGetListTournament({
            page: 1,
            rowsPerPage: 4
        }));
    }, [])

    const tournament = useSelector(state => state.tournament.list);
    const cals = window.screen.height - 930;

    return (
        <div style={{background: 'rgba(2, 0, 36, 0.8)', paddingBottom: 12,}}>
            {
                tournament.tournaments.length === 0 && 
                <div className={classes.info} style={{height: cals}}>
                    <span className={classes.infoTitle} >
                        {translate('lottery.infoNotFound')}
                    </span>
                </div>
            }
            <div className={classes.wrapTournament}>
                {
                    tournament.tournaments.map((item, key) => <TournamentMobile {...item} />) 
                }
            </div>
        </div>
    )
}

export default PromotionsList;