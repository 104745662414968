import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from '../../../../../../@casino/hook/useRouter';
import { setGetSettingsBanners } from '../../../../../../store/settings/thunk/setGetSettingsBanners';
import { Carousel } from 'react-responsive-carousel';

const BuildBanner = () => {
    const dispatch = useDispatch();
    const router = useRouter();

    React.useEffect(() => {
        dispatch(setGetSettingsBanners({
            type: "MOBILE",
            language: localStorage.getItem('language') || 'ru'
        }));
    }, [])

    const list = useSelector((state) => state.settings.banners);

    return (
        <div style={{ maxHeight: 180 }}>
            {
                Boolean(list?.length) && (
                    <Carousel
                        autoPlay={true}
                        infiniteLoop
                        showStatus={false}
                        showThumbs={false}
                        showArrows={false}
                        emulateTouch
                        interval={10000}
                        showIndicators={false}
                    >
                        {
                            list.map(({ link_redirect, url }, key) => (
                                <div onClick={() => link_redirect && router.history.push(link_redirect)}>
                                    <img src={url} alt="" style={{maxHeight: 180}} />
                                </div>
                                
                            ))
                        }
                    </Carousel>
                )
            }
        </div>
    )
}

export default BuildBanner;