import {types} from './types';

const initialState = {
    view: {}
}

export function welcomeBonusReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_WELCOME_BONUS:
            return { ...state, view: action.payload };
        default:
            return state
    }
}