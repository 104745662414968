import { setProfileAction } from '../action';
import {getProfile} from '../api/getProfile';

export const setGetProfile = (params) => {
    return async (dispatch, getState) => {
        try {
            const {data} = await getProfile(params);
            
            if (data.status === 'success') {
                const profile = {
                    username: data.data.login ? data.data.login : '',
                    name: data.data.name ? data.data.name : '',
                    surname: data.data.surname ? data.data.surname : '',
                    gender: data.data.gender ? data.data.gender : 'male',
                    country: data.data.country ? data.data.country : '',
                    city: data.data.city ? data.data.city : '',
                    birthday: data.data.birthday ? data.data.birthday : 0,
                    telegram: data.data.telegram ? data.data.telegram : '',
                    phone: data.data.phone ? data.data.phone.slice(1) : '',
                    email: data.data.email ? data.data.email : ''
                }
                dispatch(setProfileAction(profile))
            }
        } catch(error) {
            if (error && error.response && error.response.status === 401) {
                localStorage.removeItem('token');
                window.location.replace(window.location.origin);
            }
        }
    }
}