import {types} from './types';

const initialState = {
    view: {}
}

export function cashbackReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_GET_CASHBACK:
            return { ...state, view: action.payload};
        default:
            return state
    }
}