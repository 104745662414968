import React from 'react';
import Usdt from '../../../../../../@casino/img/trc20.png';
import { useSelector } from 'react-redux';

const UstdERC20 = ({ setSelected, formik, currency }) => {
    const profile = useSelector(state => state.profile.view);

    return (
        <div
            onClick={() => {
                setSelected(true);
                formik.setFieldValue('cardType', 753);
                if (currency === 'RUB') {
                    formik.setFieldValue('min', profile?.balance < 100 ? 100 : profile?.balance);
                } else if (currency === 'UZS') {
                    formik.setFieldValue('min', profile?.balance < 15000 ? 15000 : profile?.balance);
                } else if (currency === 'KZT') {
                    formik.setFieldValue('min', profile?.balance < 600 ? 600 : profile?.balance);
                } else {
                    formik.setFieldValue('min', profile?.balance < 5 ? 5 : profile?.balance);
                }
            }}
            style={{ filter: formik.values.cardType === 753 ? 'invert(0%)' : 'invert(20%)', overflow: 'hidden', cursor: 'pointer', width: 100, height: 100, background: '#fff', borderRadius: 3, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
               <div style={{height: 80, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
               <img src={Usdt} style={{ height: 40 }} />
            </div>
            
            <div style={{ background: '#D2D8E3', width: 90, height: 1, marginBottom: 4 }}></div>
            <span style={{
                fontFamily: 'Montserrat, sans-serif',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '12px',
                lineHeight: '17px',
                color: '#8E9EC2',
                paddingBottom: 5
            }}>USDT(TRC20)</span>
        </div>
    )
}

export default UstdERC20;