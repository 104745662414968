export const types = {
    SET_BONUSES_LIST: '[PROFILE] SET BONUSES LIST',
    SET_HISTORY_LIST: '[PROFILE] SET HISTORY LIST',
    SET_TRANSACTION_LIST: '[PROFILE] SET TRANSACTION LIST',
    SET_PROFILE_VIEW: '[PROFILE] SET PROFILE VIEW',
    SET_GAMES_LIST: '[PROFILE] SET GAMES LAST LIST',
    SET_PROMOCODE: '[PROFILE] SET PROMOCODE',
    SET_REFERRALS_LIST: '[PROFILE] SET REFERRALS LIST',
    SET_BONUSES_VIEW: '[PROFILE] SET BONUSES VIEW',
    SET_LIST_VERIFICATION: '[PROFILE] SET VERIFICATION LIST'
}