import React from 'react';
import { ReactComponent as TimeIcon } from './time.svg';
import classes from './style.module.css';
import { translate } from '../../../../../dictionaries';

const Time = ({
    date_to,
    date_from,
    status
}) => {
    const now = new Date().getTime();

    const getDate = (val) => {
        const now = new Date().getTime();
        const diff = +val - now;

        let hours = (diff / 1000) / 60 / 60;
        let minutes = (diff - Math.floor(hours) * 1000 * 60 * 60) / 1000 / 60;
        let seconds = (diff - (Math.floor(hours) * 1000 * 60 * 60 + Math.floor(minutes) * 1000 * 60)) / 1000;
        const hours_formatter = Math.floor(hours) < 10 ? '0' + Math.floor(hours) : Math.floor(hours);
        const minutes_formatter = Math.floor(minutes) < 10 ? '0' + Math.floor(minutes) : Math.floor(minutes);
        const seconds_formatter = Math.round(seconds) < 10 ? '0' + Math.round(seconds) : Math.round(seconds);

        return (
            <span style={{
                color: '#FFFFFF',
                fontFamily: 'Montserrat, sans-serif',
                fontSize: 12,
                fontWeight: 400,
            }}>{hours_formatter}<span style={{ fontSize: 8 }}>ч</span> : {minutes_formatter}<span style={{ fontSize: 8 }}>м</span> : {seconds_formatter}<span style={{ fontSize: 8 }}>с</span></span>
        )
    }

    const [left, setLeft] = React.useState(1);

    let timer = null;

    React.useEffect(() => {
        timer = setInterval(() => {
            if (left < 10) {
                setLeft(left + 1);
            } else {
                setLeft(1)
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        }
    }, [left])

    return (
        <div className={classes.root}>
            <div>
                <TimeIcon />
            </div>
            <div>
                <div className={classes.wrapText}>
                    <span className={classes.text}>
                        {translate('common.498')}
                    </span>
                </div>
                <div className={classes.wrapText}>
                    {
                        !status && (
                            <span style={{
                                color: '#FFFFFF',
                                fontFamily: 'Montserrat, sans-serif',
                                fontSize: 12,
                                fontWeight: 400,
                            }}>00<span style={{ fontSize: 8 }}>ч</span> : 00<span style={{ fontSize: 8 }}>м</span> : 00<span style={{ fontSize: 8 }}>с</span></span>
                        )
                    }
                    {
                        Boolean(+date_to > now && now > +date_from) && getDate(date_to)
                    }
                    {
                        Boolean(now < +date_from) && getDate(date_from)
                    }
                </div>
            </div>
        </div>
    )
}

export default Time;