import React from 'react';
import { translate } from '../../dictionaries';

const configNagiation = [
    {
        label: 'main',
        path: '/',
        exact: true,
        component: React.lazy(() => import('./home/page'))
    },
    {
        label: 'tournament',
        path: '/tournament',
        exact: true,
        component: React.lazy(() => import('./tournament/page/list'))
    },
    // {
    //     label: 'lotteries',
    //     path: '/bet',
    //     exact: true,
    //     component: React.lazy(() => import('./bet'))
    // },
    {
        label: 'bonuses',
        path: '/bonuses',
        exact: true,
        component: React.lazy(() => import('./bonuses/page/list'))
    },
    {
        label: 'cashback',
        path: '/bonuses/cashback',
        exact: true,
        component: React.lazy(() => import('../cashback'))
    }
]

export {
    configNagiation
}