import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useRouter} from '../../../../../../@casino/hook/useRouter';
import {getLabel, getBackground} from './helper'
const useStyles = makeStyles(() => ({
    root: {
        cursor: 'pointer', 
        width: 400, 
        height: 125, 
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center',
        transition: 'left .5s ease'
    },
    labelJackpot: {
        fontFamily: 'Montserrat, sans-serif',
        transition: 'color .2s ease', 
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        padding: '5px 10px',
        background: 'rgba(0, 0, 0, 0.3)',
        borderRadius: 20,
    },
    amountJackpot: {
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '45px',
        lineHeight: '55px',
        color: '#fff',
    }
}))
const Jackpot = ({jackpot}) => {
    const classes = useStyles();
    const router = useRouter();
    const [left, setLeft] = React.useState(1);

    let timer = null;
        
    React.useEffect(() => {
        timer = setInterval(() => {
            if (left < jackpot.length && left <= 3) {
                setLeft(left + 1);
            } else {
                setLeft(1)
            }
        }, 3000);

    return () => {
        clearInterval(timer);
    }
    }, [left, jackpot.length])
  
    return (
        <div style={{display: 'flex', width: 400, overflow: 'hidden', position: 'relative', height: 150}}>
            {
                jackpot.length >= 1 &&
                <div 
                    className={classes.root} 
                    style={{position: 'absolute', top: 0, left: left === 1 ? 0 : left * 400, background: `url(${getBackground(jackpot[0].jackpot)})`, backgroundSize: 'cover' }}
                    onClick={() => router.history.push('/jackpot')}
                >
                    <span
                        className={classes.labelJackpot}
                    >{getLabel(jackpot[0].jackpot)}
                    </span>
                    <span className={classes.amountJackpot}>
                        {new Intl.NumberFormat('ru-RU').format(jackpot[0].primary_amount.toFixed(2))}
                    </span>
                </div>
            }
            {
                jackpot.length >= 2 && 
                <div onClick={() => router.history.push('/jackpot')} className={classes.root} 
                    style={{position: 'absolute', top: 0, left: left === 2 ? 0 : left * 400, background: `url(${getBackground(jackpot[1].jackpot)})`, backgroundSize: 'cover' }}>
                    <span
                        className={classes.labelJackpot}
                    >{getLabel(jackpot[1].jackpot)}</span>
                    <span className={classes.amountJackpot}>
                        {new Intl.NumberFormat('ru-RU').format(jackpot[1].primary_amount.toFixed(2))}
                    </span>
                </div>
            }
            {
                jackpot && jackpot.length >= 3 &&
                <div onClick={() => router.history.push('/jackpot')} className={classes.root} 
                    style={{position: 'absolute', top: 0, left: left === 3 ? 0 : left * 400, background: `url(${getBackground(jackpot[2].jackpot)})`, backgroundSize: 'cover'}}>
                    <span
                        className={classes.labelJackpot}
                    >{getLabel(jackpot[2].jackpot)}</span>
                    <span className={classes.amountJackpot}>
                        {new Intl.NumberFormat('ru-RU').format(jackpot[2].primary_amount.toFixed(2) ? jackpot[2].primary_amount.toFixed(2) : 0)}
                    </span>
                </div>
            } 
            {
                jackpot.length >= 4 &&
                <div onClick={() => router.history.push('/jackpot')} className={classes.root} 
                    style={{position: 'absolute', top: 0, left: left === 4 ? 0 : left * 400, background: `url(${getBackground(jackpot[3].jackpot)})`, backgroundSize: 'cover' }}>
                    <span
                        className={classes.labelJackpot}
                    >{getLabel(jackpot[3].jackpot)}</span>
                    <span className={classes.amountJackpot}>
                        {new Intl.NumberFormat('ru-RU').format(jackpot[3].primary_amount.toFixed(2))}
                    </span>
                </div>
            } 
        </div> 
        
    )
}

export default Jackpot;