import React from 'react';
import { ReactComponent as GoldCup } from './gold-cup.svg';
import { ReactComponent as SilverCup } from './silver-cup.svg';
import { ReactComponent as BronzeCup } from './bronze-cup.svg';
import classes from './style.module.css';

const PlaceItem = ({
    item,
    index
}) => {
    const handleCup = () => {
        switch (index) {
            case 0: return GoldCup;
            case 1: return SilverCup;
            case 2: return BronzeCup
        }
    }

    const Cup = handleCup();

    return (
        <div className={classes.root} key={index}>
            <div className={classes.wrapCup}>
                <span className={classes.textCup}>{index + 1}</span>
                <Cup />
            </div>
            <div className={classes.wrapPrize}>
                <div className={classes.wrapSum}>
                    <span className={classes.textSum}>
                        {new Intl.NumberFormat('ru-RU').format(item)}
                    </span>
                </div>
                <span className={classes.currency}>₽</span>
            </div>
        </div>
    )
}

export default PlaceItem;