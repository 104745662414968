import React from 'react';
import {ReactComponent as Eye} from '../../../../../@casino/img/eye.svg';
import {ReactComponent as Exit} from '../../../../../@casino/img/exit.svg';
import {ReactComponent as SuccessVerification} from '../../../../../@casino/img/sucessVerification.svg';
import {ReactComponent as ErrorVerification} from '../../../../../@casino/img/errorVerification.svg';
import {ReactComponent as CoinProfile} from '../../../../../@casino/img/coinProfile.svg';
import {ReactComponent as WithdrawalProfile} from '../../../../../@casino/img/withdrawalProfile.svg';
import {ReactComponent as ReplenishmentProfile} from '../../../../../@casino/img/replenishmentProfile.svg';
import User from '../../../../../@casino/img/user.svg';
import { useSelector } from 'react-redux';
import { useRouter} from '../../../../../@casino/hook/useRouter';
import BackgroundProfile from '../../../../../@casino/img/backgroundProfile.svg';
import { translate } from '../../../../../dictionaries';

const ProfileBlock = () => {
    const profile = useSelector(state => state.profile.view);
    const router = useRouter();
    const getCurrency = (currency) => {
        switch (currency) {
            case 'RUB': return '₽';
            case 'USD': return '$';
            case 'EUR': return '€';
            default: return currency
        }
    }
    return (
        <div style={{
            background: `url(${BackgroundProfile})`,
            backgroundPositionX: -20,
            backgroundPositionY: -10,
            boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)',
            maxWidth: 231, 
            width: '100%',
            height: 560,
            position: 'relative',
            backgroundColor: '#00236E'
        }}>
            <Eye style={{position: 'absolute', top: 19, left: 19}}/>
            <Exit style={{position: 'absolute', top: 19, right: 14, cursor: 'pointer'}} onClick={() => {localStorage.removeItem('token'); window.location.replace(window.location.origin)}}/>
            <div style={{marginTop: 29}}>
                <div style={{ display: 'flex', justifyContent: 'center'}}>
                    <div style={{width: 120, position: 'relative',}}>
                        <img src={User} style={{width: 120, height: 120, borderRadius: '50%'}} alt=""/>
                        {
                            !!profile.verification_passport && !!profile.verification_selfie_passport &&
                            !!profile.verification_selfie_card && !!profile.verification_payments &&
                            <SuccessVerification style={{position: 'absolute', bottom: 0, right: 0}}/>
                        }
                        {
                            (!!!profile.verification_passport || !!!profile.verification_selfie_passport ||
                            !!!profile.verification_selfie_card || !!!profile.verification_payments) &&
                            <ErrorVerification style={{position: 'absolute', bottom: 0, right: 0}}/>
                        }
                        
                    </div>
                    
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', 
                    marginTop: 21
                }}>
                    <span style={{
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '14px',
                        lineHeight: '17px',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        color: '#D2D8E3',
                    }}>{profile.name} {profile.surname}</span>
                    <span style={{
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '16px',
                        lineHeight: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',
                        color: '#FFFFFF',
                    }}>{profile?.login === '79653339666' ? '79********6' : isNaN(profile.login) ? profile.login : '+' + profile.login.slice(0, 1) + ' ' + profile.login.slice(1, 4) + ' ' + profile.login.slice(4, 7) + ' ' + profile.login.slice(7, 9) + '-' + profile.login.slice(9)}</span>
                </div>
                <div style={{display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 26}}>
                    <div style={{display: 'flex', alignItems: 'center', width: 190, height: 60, background: '#001137', borderRadius: 3, marginBottom: 10}}>
                        <div style={{marginRight: 28, marginLeft: 20}}>
                            <CoinProfile style={{width: 29, height: 30}}/>
                        </div>
                        <div>
                            <span style={{
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '12px',
                                lineHeight: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#8E9EC2',
                                paddingBottom: 5
                            }}>{translate('navigation.bonuses')}</span>
                            <span style={{
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#FFFFFF',
                            }}>{new Intl.NumberFormat('ru-RU').format(profile.sum_bonuses)} {getCurrency(profile?.currency)}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', width: 190, height: 60, background: '#001137', borderRadius: 3, marginBottom: 10}}>
                        <div style={{marginRight: 28, marginLeft: 20}}>
                            <WithdrawalProfile style={{width: 29, height: 30}}/>
                        </div>
                        <div>
                            <span style={{
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '12px',
                                lineHeight: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#8E9EC2',
                                paddingBottom: 5
                            }}>{translate('navigation.withdrawals')}</span>
                            <span style={{
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#FFFFFF',
                            }}>{new Intl.NumberFormat('ru-RU').format(profile.sum_withdrawals)} {getCurrency(profile?.currency)}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', alignItems: 'center', width: 190, height: 60, background: '#001137', borderRadius: 3}}>
                        <div style={{marginRight: 28, marginLeft: 20}}>
                            <ReplenishmentProfile style={{width: 29, height: 30}}/>
                        </div>
                        <div>
                            <span style={{
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '12px',
                                lineHeight: '15px',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#8E9EC2',
                                paddingBottom: 5
                            }}
                              
                            >{translate('navigation.replenishment')}</span>
                            <span style={{
                                fontFamily: 'Montserrat, sans-serif',
                                fontStyle: 'normal',
                                fontWeight: 600,
                                fontSize: '16px',
                                lineHeight: '20px',
                                display: 'flex',
                                alignItems: 'center',
                                color: '#FFFFFF',
                            }}>{new Intl.NumberFormat('ru-RU').format(profile.sum_deposit)} {getCurrency(profile?.currency)}</span>
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', marginTop: 23}}>
                    <span style={{                    
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '12px',
                        lineHeight: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#D2D8E3',
                        paddingTop: 9
                    }}>{translate('common.balance')}</span> <span style={{
                        marginLeft: 18,
                        fontFamily: 'Montserrat',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '24px',
                        lineHeight: '29px',
                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'right',
                        color: '#FFFFFF',
                    }}>{new Intl.NumberFormat('ru-RU').format(Math.floor((profile.balance)*100)/100)} {getCurrency(profile?.currency)}</span>
                </div>
                <div style={{display: 'flex', columnGap: 5, justifyContent: 'center', marginTop: 16}}>
                    <span style={{
                        padding: '10px 15px', 
                        background: '#7B61FF',
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#FFFFFF',
                        borderRadius: '50px',
                        cursor: 'pointer'
                    }}
                     onClick={() => router.history.push('/profile/withdrawal')}
                    >{translate('btn.withdrawal')}</span>
                    <span style={{
                        padding: '10px 15px', 
                        background: '#FF005C',
                        fontFamily: 'Montserrat, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        fontSize: '12px',
                        lineHeight: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#FFFFFF',
                        borderRadius: '50px',
                        cursor: 'pointer'
                    }}
                    onClick={() => router.history.push('/profile/replenishment')}
                    >{translate('btn.replenishment')}</span>
                </div>
            </div>
        </div>
    )
}

export default ProfileBlock;