import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {useRouter} from '../../../../../@casino/hook/useRouter';
import BackgroundNavigation from '../../../../../@casino/img/backgroundNavigation.png';
import {translate} from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    item: {
        height: 20,
        fontFamily: 'Montserrat, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#FFFFFF',
        paddingLeft: 11,
    },
    p15: {
        padding: 15,
        cursor: 'pointer'
    }
}));

const Navigation = () => {
    const router = useRouter();
    const classes = useStyles();

    return (
        <div style={{
            background: `url(${BackgroundNavigation})`,
            backgroundPositionX: -20,
            backgroundPositionY: -10,
            boxShadow: '0px 10px 20px rgba(0, 11, 36, 0.5)',
            maxWidth: 231, width: '100%',
            height: 560,
            backgroundColor: '#00236E'
        }}>
            <nav style={{display: 'flex', flexDirection: 'column', paddingTop: 13}}>
                <div 
                    className={classes.p15} 
                    style={{ 
                        background: router.pathname === '/profile/account' && 'linear-gradient(270deg, rgba(68, 0, 155, 0.3) 0%, #7B61FF 100%), #0A206D',
                    }}
                    onClick={() => router.history.push('/profile/account')}
                >
                    <span  className={classes.item} style={{
                        borderLeft: router.pathname === '/profile/account' && '2px solid #FF005C',
                        color: router.pathname === '/profile/account' ? '#fff' : '#8E9EC2'
                    }}>
                        {translate('navigation.account')}
                    </span>
                </div>
                <div 
                    className={classes.p15} 
                    style={{ 
                        background: router.pathname === '/profile/bonuses' && 'linear-gradient(270deg, rgba(68, 0, 155, 0.3) 0%, #7B61FF 100%), #0A206D',
                    }}
                    onClick={() => router.history.push('/profile/bonuses')}
                >
                    <span 
                        className={classes.item} 
                        style={{
                            borderLeft: router.pathname === '/profile/bonuses' && '2px solid #FF005C',
                            color: router.pathname === '/profile/bonuses' ? '#fff' : '#8E9EC2'
                        }}
                    >
                        {translate('navigation.bonuses')}
                    </span>
                </div>
                {
                    window.localStorage.getItem('token') && 
                    <div 
                        className={classes.p15} 
                        style={{ 
                            background: router.pathname === '/profile/view' && 'linear-gradient(270deg, rgba(68, 0, 155, 0.3) 0%, #7B61FF 100%), #0A206D',
                        }}
                        onClick={() => router.history.push('/profile/view')}    
                    >
                        <span  
                            className={classes.item} 
                            style={{
                                borderLeft: router.pathname === '/profile/view' && '2px solid #FF005C',
                                color: router.pathname === '/profile/view' ? '#fff' : '#8E9EC2'
                            }}
                            
                        >
                            {translate('navigation.profile')}
                        </span>
                    </div>
                }
                <div 
                    className={classes.p15} 
                    style={{ 
                        background: router.pathname === '/profile/referral' && 'linear-gradient(270deg, rgba(68, 0, 155, 0.3) 0%, #7B61FF 100%), #0A206D',
                    }}
                    onClick={() => router.history.push('/profile/referral')}
                >
                    <span  className={classes.item} style={{
                        borderLeft: router.pathname === '/profile/referral' && '2px solid #FF005C',
                        color: router.pathname === '/profile/referral' ? '#fff' : '#8E9EC2'
                    }}> 
                        {translate('navigation.referral')} <br />
                        <span style={{paddingLeft: 13}}>{translate('navigation.program')}</span>
                    </span>
                </div>
                <div 
                    className={classes.p15}
                    style={{ 
                        background: router.pathname === '/profile/history' && 'linear-gradient(270deg, rgba(68, 0, 155, 0.3) 0%, #7B61FF 100%), #0A206D',
                    }}
                    onClick={() => router.history.push('/profile/history')}
                >
                    <span  className={classes.item} style={{
                        borderLeft: router.pathname === '/profile/history' && '2px solid #FF005C',
                        color: router.pathname === '/profile/history' ? '#fff' : '#8E9EC2'
                    }}>
                        {translate('navigation.history')}
                    </span>
                </div>
                <div 
                    className={classes.p15} 
                    style={{ 
                        background: router.pathname === '/profile/transaction' && 'linear-gradient(270deg, rgba(68, 0, 155, 0.3) 0%, #7B61FF 100%), #0A206D',
                    }}
                    onClick={() => router.history.push('/profile/transaction')}
                >
                    <span  className={classes.item} style={{
                        borderLeft: router.pathname === '/profile/transaction' && '2px solid #FF005C',
                        color: router.pathname === '/profile/transaction' ? '#fff' : '#8E9EC2'
                    }}>
                        {translate('navigation.transaction')}
                    </span>
                </div>
                {/* <div 
                    className={classes.p15} 
                    style={{ 
                        background: router.pathname === '/profile/verification' && 'linear-gradient(270deg, rgba(68, 0, 155, 0.3) 0%, #7B61FF 100%), #0A206D',
                    }}
                    onClick={() => router.history.push('/profile/verification')}
                >
                    <span  className={classes.item} style={{
                        borderLeft: router.pathname === '/profile/verification' && '2px solid #FF005C',
                        color: router.pathname === '/profile/verification' ? '#fff' : '#8E9EC2'
                    }}>
                        {translate('navigation.verification')}
                    </span>
                </div> */}
            </nav>
        </div>
    )
}

export default Navigation;