import React from 'react';
import {useStyles} from './helper';
import clsx from 'clsx';

const TextField = ({name, value, title, onChange, placeholder}) => {
    const classes = useStyles();

    return (
        <label htmlFor={name} className={classes.labelField}>
            <span 
                className={clsx(classes.helper, classes.mb10)} 
            >{title}</span>
            <input 
                type="text" 
                name={name}
                id={name}
                placeholder={placeholder}
                value={value}
                onChange={(v) => onChange(v)}
                className={name === 'promocode' ? classes.fieldPromocode : classes.field}
            />
        </label>
    )
}

export default TextField;