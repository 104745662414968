import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { translate } from '../../../../../dictionaries';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%', maxWidth: 1140, margin: '0 auto', color: '#fff'
    },
    paragraf: {
        color: 'rgba(255,255,255,0.8)!important',
        fontFamily: 'Montserrat, sans-serif'
    },
    title: {
        display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', fontSize: 24, color: 'gold',
        textAlign: 'center'
    },
    text: {
        color: 'rgba(255,255,255,0.8)',
        fontFamily: 'Montserrat, sans-serif'
    }
}))

const RulesRU = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <h2 className={classes.title}>{translate('common.164')}</h2>
            <p className={classes.text}>
                {translate('common.165')}
            </p>

            <h2 className={classes.title}>{translate('common.166')}</h2>
            <p className={classes.text}>
                {translate('common.167')}
            </p>

            <h2 className={classes.title}>{translate('common.168')}</h2>
            <p className={classes.text}>
                {translate('common.169')}
                <br /><br />
                {translate('common.170')}
            </p>

            <h2 className={classes.title}>{translate('common.171')}</h2>
            <p className={classes.text}>
                {translate('common.172')}
                <br /><br />
                {translate('common.173')}
                <br /><br />
                {translate('common.174')}
                <br /><br />
                {translate('common.175')}
            </p>

            <h2 className={classes.title}>{translate('common.176')}</h2>
            <p className={classes.text}>
                {translate('common.177')}
                <br /><br />
                {translate('common.178')}
                <br /><br />
                {translate('common.179')}
                <br /><br />
                {translate('common.180')}
                <br /><br />
                {translate('common.181')}
                <br /><br />
                {translate('common.182')}
                <br /><br />
                {translate('common.183')}
                <br /><br />
                {translate('common.184')}
                <br /><br />
            </p>

            <h2 className={classes.title}>{translate('common.185')}</h2>
            <p className={classes.text}>
                {translate('common.186')}
                <br /><br />
                {translate('common.187')}
                <br /><br />
                {translate('common.188')}
                <br /><br />
                {translate('common.189')}
                <br /><br />
                {translate('common.190')}
                <br /><br />
                {translate('common.191')}
                <br /><br />
                {translate('common.192')}
                <br /><br />
                {translate('common.193')}
                <br /><br />
                {translate('common.194')}
                <br /><br />
                {translate('common.195')}
                <br /><br />
                {translate('common.196')}
                <br /><br />
                {translate('common.197')}
                <br /><br />
                {translate('common.198')}
                <br /><br />
            </p>
            <h2 className={classes.title}>{translate('common.199')}</h2>
            <p className={classes.text}>
                {translate('common.200')}
                <br /><br />
                {translate('common.201')}
                <br /><br />
                {translate('common.202')}
            </p>

            <h2 className={classes.title}>{translate('common.203')}</h2>
            <p className={classes.text}>
                {translate('common.204')}
                <br /><br />
                {translate('common.205')}
                <br /><br />
                {translate('common.206')}
                <br /><br />
                {translate('common.207')}
                <br /><br />
                {translate('common.208')}
                <br /><br />
                {translate('common.209')}
                <br /><br />
                {translate('common.210')}
                <br /><br />
                {translate('common.211')}
                <br /><br />
                {translate('common.212')}
                <br /><br />
                {translate('common.213')}
                <br /><br />
                {translate('common.214')}
                <br /><br />
                {translate('common.215')}
                <br /><br />
                {translate('common.216')}
                <br /><br />
                {translate('common.217')}
                <br /><br />
                {translate('common.218')}
                <br /><br />
                {translate('common.219')}
                <br /><br />
                {translate("common.374")}
                <br/><br/>
                {translate('common.220')}
                <br /><br />
                {translate('common.221')}
                <br /><br />
                {translate('common.222')}
                <br /><br />
                {translate('common.223')}
                <br /><br />
                {translate('common.224')}
                <br /><br />
                {translate('common.225')}
                <br /><br />
                {translate('common.225')}
                <br /><br />
                {translate('common.226')}
                <br /><br />
                {translate('common.227')}
                <br /><br />
                {translate('common.228')}
                <br /><br />
                {translate('common.229')}
                <br /><br />
                {translate('common.230')}
                <br /><br />
                {translate('common.231')}
                <br /><br />
                {translate('common.232')}
                <br /><br />
                {translate('common.233')}
                <br/><br/>
                {translate('common.234')}
                <br /><br />
                {translate('common.235')}
                <br /><br />
                {translate('common.236')}
            </p>
            <h2 className={classes.title}>{translate('common.237')}</h2>
            <p className={classes.text}>
                {translate('common.238')}
                <br /><br />
                {translate('common.239')}
                <br /><br />
                {translate('common.240')}
                <br /><br />
                {translate('common.241')}
                <br /><br />
                {translate('common.242')}
            </p>

            <h2 className={classes.title}>{translate('common.243')}</h2>
            <p className={classes.text}>
                {translate('common.244')}
                <br /><br />
                {translate('common.245')}
                <br /><br />
                {translate('common.246')}
                <br /><br />
                {translate('common.247')}
                <br /><br />
                {translate('common.248')}
                <br /><br />
                {translate('common.249')}
            </p>
            <p className={classes.text}>
                {translate('common.250')}
                <br /><br />
                {translate('common.251')}
                <br /><br />
                {translate('common.252')}
            </p>
            <h2 className={classes.title}>{translate('common.253')}</h2>
            <p className={classes.text}>
                {translate('common.254')}
                <br /><br />
                {translate('common.255')}
                <br /><br />
                {translate('common.256')}
                <br /><br />
                {translate('common.257')}
                <br /><br />
                {translate('common.258')}
                <br /><br />
                {translate('common.259')}
                <br /><br />
                {translate('common.260')}
                <br /><br />
                {translate('common.261')}
            </p>
            <h2 className={classes.title}>{translate('common.262')}</h2>
            <p className={classes.text}>
                {translate('common.263')}
                <br /><br />
                {translate('common.264')}
                <br /><br />
                {translate('common.265')}
                <br /><br />
                {translate('common.266')}
                <br /><br />
                {translate('common.267')}
                <br /><br />
                {translate('common.268')}
                <br /><br />
                {translate('common.269')}
                <br /><br />
                {translate('common.270')}
                <br /><br />
                {translate('common.271')}
                <br /><br />
                {translate('common.272')}
                <br /><br />
                {translate('common.273')}
                <br /><br />
                {translate('common.274')}
                <br /><br />
                {translate('common.275')}
                <br /><br />
                {translate('common.276')}
                <br /><br />
                {translate('common.277')}
            </p>
            <h2 className={classes.title}>{translate('common.278')}</h2>
            <p className={classes.text}>{translate('common.279')}</p>
            <h2 className={classes.title}>{translate('common.280')}</h2>
            <p className={classes.text}>{translate('common.281')}</p>
            <h2 className={classes.title}>{translate('common.282')}</h2>
            <p className={classes.text}>
                {translate('common.283')}
                <br /><br />
                {translate('common.284')}
                <br /><br />
                {translate('common.285')}
                <br /><br />
                {translate('common.286')}
            </p>
            <h2 className={classes.title}>{translate('common.287')}</h2>
            <p className={classes.text}>
                {translate('common.288')}
                <br /><br />
                {translate('common.289')}
                <br /><br />
                {translate('common.290')}
                <br /><br />
                {translate('common.291')}
                <br /><br />
                {translate('common.292')}
                <br /><br />
                {translate('common.293')}
                <br /><br />
                {translate('common.294')}
                <br /><br />
                {translate('common.295')}
                <br /><br />
                {translate('common.296')}
                <br /><br />
                {translate('common.297')}
                <br /><br />
                {translate('common.298')}
            </p>
            <h2 className={classes.title}>{translate('common.299')}</h2>
            <p className={classes.text}>
                {translate('common.300')}
                <br /><br />
                {translate('common.301')}
                <br /><br />
                {translate('common.302')}
                <br /><br />
                {translate('common.303')}
            </p>
            <h2 className={classes.title}>{translate('common.304')}</h2>
            <p className={classes.text}>
                {translate('common.305')}
                <br /><br />
                {translate('common.306')}
                <br /><br />
                {translate('common.307')}
                <br /><br />
                {translate('common.308')}
            </p>
            <h2 className={classes.title}>{translate('common.309')}</h2>
            <p className={classes.text}>
                {translate('common.310')}
                <br /><br />
                {translate('common.311')}
                <br /><br />
                {translate('common.312')}
            </p>
            <h2 className={classes.title}>{translate('common.313')}</h2>
            <p className={classes.text}>{translate('common.314')}</p>
            <h2 className={classes.title}>{translate('common.315')}</h2>
            <p className={classes.text}>
                {translate('common.316')}
                <br /><br />
                {translate('common.317')}
            </p>
            <h2 className={classes.title}>{translate('common.318')}</h2>
            <p className={classes.text}>
                {translate('common.319')}
                <br /><br />
                {translate('common.320')}
            </p>
            <h2 className={classes.title}>{translate('common.321')}</h2>
            <p className={classes.text}>{translate('common.322')}</p>
            <h2 className={classes.title}>{translate('common.323')}</h2>
            <p className={classes.text}>{translate('common.324')}</p>
            <h2 className={classes.title}>{translate('common.325')}</h2>
            <p className={classes.text}>
                {translate('common.326')}
                <br /><br />
                {translate('common.327')}
                <br /><br />
                {translate('common.328')}
            </p>
            <h2 className={classes.title}>{translate('common.329')}</h2>
            <p className={classes.text}>
                {translate('common.330')}
                <br /><br />
                {translate('common.331')}
                <br /><br />
                {translate('common.332')}
                <br /><br />
                {translate('common.333')}
                <br /><br />
                {translate('common.334')}
            </p>
            <p style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', color: 'gold'
            }}><b>{translate('common.335')}</b></p>
            <p className={classes.text}>{translate('common.336')}</p>
            <ul className={classes.text}>
                <li>{translate('common.337')}</li>
                <li>{translate('common.338')}</li>
                <li>{translate('common.339')}</li>
            </ul>
            <b style={{
                display: 'flex', alignItems: 'center', justifyContent: 'center', fontFamily: 'Montserrat, sans-serif', color: 'gold'
            }}>{translate('common.340')}</b>
            <p className={classes.text}>{translate('common.341')}</p>
            <ul className={classes.text}>
                <li><a href="http://www.cyberpatrol.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(255,255,255, 0.8' }}>http://www.cyberpatrol.com/</a></li>
                <li><a href="http://www.gamblock.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(255,255,255, 0.8' }}>http://www.gamblock.com/</a></li>
                <li><a href="http://www.solidoak.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(255,255,255, 0.8' }}>http://www.solidoak.com/</a></li>
                <li><a href="http://www.netnanny.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'rgba(255,255,255, 0.8' }}>http://www.netnanny.com/</a></li>
            </ul>
        </div>
    )
}

export default RulesRU;