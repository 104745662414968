import React from 'react';
import Bg from './bg-card.png';
import { useRouter } from '../../../../@casino/hook/useRouter';
import { useSelector } from 'react-redux';
import { translate } from '../../../../dictionaries';
const BonusCard = ({
    title,
    description,
    min_dep,
    max_bonus,
    IconOne,
    IconTwo
}) => {
    const router = useRouter();
    const profile = useSelector((state) => state.profile.view);
   
    return (
        <div style={{
            width: 567,
            height: 315,
            background: `url(${Bg})`,
            position: 'relative'
        }}>
            <div style={{ textAlign: 'center', paddingTop: 52, paddingLeft: 5, display: 'flex', flexDirection: 'column', rowGap: 11 }}>
                <h3 style={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 28,
                    fontWeight: 600,
                    margin: 0,
                    padding: 0
                }}>{title}</h3>
                <span style={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 16,
                    fontWeight: 400,
                }}>{description}</span>
            </div>
            <div style={{display: 'flex', justifyContent: 'center', marginTop: 40, marginLeft: 5}}>
                <div 
                    onClick={() => router.history.push(profile?.id ? '/profile/replenishment' : '/auth')}
                    style={{cursor: 'pointer', background: '#FF005C', borderRadius: 50, minWidth: 172, minHeight: 43, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <span style={{
                        color: '#FFF',
                        fontFamily: 'Montserrat, sans-serif',
                        fontSize: 16,
                        fontWeight: 600,
                    }}>{translate('common.435')}</span>
                </div>
            </div>
            <div style={{
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                rowGap: 10,
                marginTop: 33
            }}>
                <span style={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 20,
                    fontWeight: 400,
                }}>{min_dep}</span>
                <span style={{
                    color: '#FFF',
                    textAlign: 'center',
                    fontFamily: 'Montserrat, sans-serif',
                    fontSize: 20,
                    fontWeight: 400,
                    paddingLeft: 5
                }}>{max_bonus}</span>
            </div>
            <IconOne style={{position: 'absolute', top: 112, left: 62}}/>
            <IconTwo style={{position: 'absolute', bottom: 68, right: 58}}/>
        </div>
    )
}

export { BonusCard }