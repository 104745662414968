import {types} from './types';

const initialState = {
    bonuses: {
        list: [],
        page: 1
    },
    history: {
        list: [],
        page: 1,
    },
    transaction: {
        list: [],
        page: 1,
    },
    profile: {
        username: '',
        name: '',
        surname: '',
        birthday: 0,
        gender: 'female',
        country: '',
        city: '',
        email: '',
        phone: '',
        telegram: ''
    },
    games_list: [],
    promocode: {
        label: '',
        disabled: false
    },
    referrals_list: {
        list: [],
        page: 1
    },
    amount: 0,
    verification: [],
}

export function profileReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_BONUSES_LIST:
            return { ...state, bonuses: action.payload};
        case types.SET_HISTORY_LIST:
            return { ...state, history: action.payload};
        case types.SET_TRANSACTION_LIST: 
            return { ...state, transaction: action.payload};
        case types.SET_PROFILE_VIEW: 
            return { ...state, profile: action.payload};
        case types.SET_GAMES_LIST: 
            return { ...state, games_list: action.payload};
        case types.SET_PROMOCODE:
            return { ...state, promocode: action.payload};
        case types.SET_REFERRALS_LIST:
            return { ...state, referrals_list: action.payload};
        case types.SET_BONUSES_VIEW:
            return { ...state, amount: action.payload};
        case types.SET_LIST_VERIFICATION:
            return { ...state, verification: action.payload};
        default:
            return state
    }
}