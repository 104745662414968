import React from 'react';
import { BonusDepositDesktop } from './desktop';
import { BonusDepositMobile } from './mobile';

const BonusDeposit = () => {
    return (
        <div>
            {
                !(/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && (
                    <BonusDepositDesktop />
                )
            }
             {
                (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) && (
                    <BonusDepositMobile />
                )
            }

        </div>
    )
}

export { BonusDeposit }