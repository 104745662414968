import {types} from './types';

const initialState = {
    profile: null,
}

export function authorizationReducer(state = initialState, action) {
    switch (action.type) {
        case types.SET_PROFILE:
            return { ...state, profile: action.payload};
        default:
            return state
    }
}